import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Grid,
  Card,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
  CardHeader,
  TextField,
  Box
} from '@material-ui/core';
import { GetPodNames, LabelAdd } from '../Services/LableServices';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';


const LabelAddPage = () => {
  const [podData, setPodData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const getGameData = async () => {
    const result = await GetPodNames();
    setGameData(result?.data.data);
    console.log(result?.data.data)
  };
  const getPodData = async () => {
    const result = await GetPodNames();
    setPodData(result?.data.data);
    console.log(result?.data.data)
  };
  // console.log(state)
  const LabelSchema = Yup.object().shape({
    chapterNameLabel: Yup.string().required('Chapter Name is required').strict().trim("Trailing white spaces not allowed")
      .min(3).max(60).matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/, 'Please enter valid  Chapter name'),
    enterTextCodeLabel: Yup.string().required('Text code label is required').strict().trim("Trailing white spaces not allowed")
      .min(3).max(60).matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/, 'Please enter valid  text code label'),
    penaltiesLabel: Yup.string().required('Penalty label is required').strict().trim("Trailing white spaces not allowed")
      .min(3).max(60).matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/, 'Please enter valid  penalty label'),
    hintsLabel: Yup.string().required('Hints label is required').strict().trim("Trailing white spaces not allowed")
      .min(3).max(60).matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/, 'Please enter valid  hints label'),
    gameId: Yup.string().required('Please select a game'),
    podId: Yup.string().required('Please select a pod'),

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      chapterNameLabel: "",
      enterTextCodeLabel: "",
      penaltiesLabel: "",
      hintsLabel: "",
      gameId: "",
      podId: ""
    },

    validationSchema: LabelSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      try {
        const respData = await LabelAdd(values);

        if (respData.status === 200) {
          // navigate('/admin/gamelist');
          navigate('/admin/label/list');
          toast.success(respData?.data.message);
        }

      } catch (error) {
        console.log(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;
  useEffect(() => {
    getGameData();
    getPodData();
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Add Label" />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Chapter Label*</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Chapter Label"
                    variant="outlined"
                    name="chapterNameLabel"
                    value={values.chapterNameLabel}
                    error={Boolean(touched.chapterNameLabel && errors.chapterNameLabel)}
                    helperText={touched.chapterNameLabel && errors.chapterNameLabel}
                    {...getFieldProps('chapterNameLabel')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Text Code Label*</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Text Code Label"
                    variant="outlined"
                    name="enterTextCodeLabel"
                    value={values.enterTextCodeLabel}
                    error={Boolean(touched.enterTextCodeLabel && errors.enterTextCodeLabel)}
                    helperText={touched.enterTextCodeLabel && errors.enterTextCodeLabel}
                    {...getFieldProps('enterTextCodeLabel')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Penalties Label*</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Penalties Label"
                    variant="outlined"
                    name="penaltiesLabel"
                    value={values.penaltiesLabel}
                    error={Boolean(touched.penaltiesLabel && errors.penaltiesLabel)}
                    helperText={touched.penaltiesLabel && errors.penaltiesLabel}
                    {...getFieldProps('penaltiesLabel')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Hints Label*</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Hints Label"
                    variant="outlined"
                    name="hintsLabel"
                    value={values.hintsLabel}
                    error={Boolean(touched.hintsLabel && errors.hintsLabel)}
                    helperText={touched.hintsLabel && errors.hintsLabel}
                    {...getFieldProps('hintsLabel')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Pod*</InputLabel>
                  <FormControl fullWidth error={Boolean(touched.podId && errors.podId)}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant='outlined'
                      displayEmpty
                      label="Select Pod"
                      value={values.podId}
                      {...getFieldProps('podId')}
                      style={{ color: values.podId === "" ? 'gray' : 'black' }}
                    >
                      {podData.length ?
                        <MenuItem disabled value="">Select Pod</MenuItem>
                        : null
                      }
                      {podData.map((data, idx) => (
                        <MenuItem key={idx} value={data.value}>{data.label}</MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{touched.podId && errors.podId}</FormHelperText>

                  </FormControl>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputLabel>Game*</InputLabel>
                  <FormControl fullWidth error={Boolean(touched.gameId && errors.gameId)}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant='outlined'
                      displayEmpty
                      label="Select Game"
                      value={values.gameId}
                      {...getFieldProps('gameId')}
                      style={{ color: values.gameId === "" ? 'gray' : 'black' }}
                    >
                      {gameData.length ?
                        <MenuItem disabled value="">Select Game</MenuItem>
                        : null
                      }
                      {gameData.map((data, idx) => (
                        <MenuItem key={idx} value={data.value}>{data.label}</MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{touched.gameId && errors.gameId}</FormHelperText>

                  </FormControl>
                </Grid>
              </Grid>
              <Box display='flex' justifyContent="space-between" mt={3}>
                <Button type="submit">SAVE</Button>
              </Box>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </>
  );
};

export default LabelAddPage;
