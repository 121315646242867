import React from "react";
import AdminLayout from "../layouts/AdminLayout";
import { useRoutes, Navigate } from "react-router-dom";

import Welcome from "../Screens/User/Welcome";
import Instruction from "../Screens/User/Instruction";
import UserGame from "../Screens/User/UserGame/Game";
import LeaderBoard from "../Screens/User/UserGame/LeaderBoard";

import Login from "../Screens/auth/SignIn";
import ResetPassword from "../Screens/auth/ResetPassword";
import ForgetPassword from "../Screens/auth/ForgetPassword/ForgetPassword";

import NotFoundPage from "../Screens/User/404";
import GameFinishedPage from "../Screens/User/UserGame/GameFinished";
import GameFinishedStatus from "../Screens/User/UserGame/GameFinishedStatus";
import ShortUrl from "../Screens/User/UserGame/ShortUrl";
import AllTeams from "../Screens/User/UserGame/AllTeams";
import LeaderBoardShortUrl from "../Screens/User/UserGame/LeaderBoardShortUrl";

export default function LoginRoutes() {
  return useRoutes([
    {
      path: "auth",
      children: [
        { path: "login", element: <Login /> },
        { path: "changepassword/:token", element: <ResetPassword /> },
        { path: "forgotpassword", element: <ForgetPassword /> },
        { path: "", element: <Navigate to="/auth/login" replace /> },
        { path: "*", element: <Navigate to="/auth/login" replace /> },
      ],
    },
    {
      path: "user",
      children: [
        { path: ":token/:gameId/:podId", element: <Welcome /> },
        { path: ":token/:gameId/:podId/instruction", element: <Instruction /> },
        { path: ":token/:gameId/:podId/game", element: <UserGame /> },
        {
          path: ":token/:gameId/:podId/status",
          element: <GameFinishedStatus />,
        },
        { path: ":token/:gameId/:podId/leaderboard", element: <LeaderBoard /> },
        { path: ":id/leaderboard", element: <LeaderBoardShortUrl /> },
        { path: ":id", element: <ShortUrl /> },
        { path: "all-teams/:podId", element: <AllTeams /> },
      ],
    },
    { path: "404", element: <NotFoundPage /> },
    { path: "*", element: <Navigate to="/auth/login" replace /> },
  ]);
}
