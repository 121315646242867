import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { useNavigate } from 'react-router-dom';
import { Col, Row, Modal, Card, Button } from 'react-bootstrap';
import { Grid, TextField, Box, CardHeader, CardContent, InputLabel } from '@mui/material';
import { gameAdd } from '../Services/GameServices';
import AddChapter from '../ChapterManagement/AddChapter';
import { toast } from 'react-toastify';

const Game = () => {
  const navigate = useNavigate();
  const [gameId, setgameId] = useState('');
  const [name, setName] = useState('');

  const [gameError, setGameError] = useState(false);
  const [show, setShow] = useState(false);
  const [showChapter, setShowChapter] = useState(false);
  //for closing the popup
  const handleClose = () => {
    setShow(false);
    navigate('/admin/gamelist');
  };

  const handleChapterClose = () => {
    setShowChapter(false);
    navigate('/admin/gamelist');
  };

  //when go click on back button back to game list
  const handleGameList = () => {
    navigate('/admin/gamelist');
  };

  //calling an add game api and add chapter popup
  const handleGame = async () => {
    setGameError(true);
    try {
      let game = name.trim();
      if (name.length) {
        const gameData = await gameAdd({ name: game });
        setShow(true);

        setgameId(gameData?.data.data._id);
      }
    } catch (err) {
      toast.error(err?.response.data.message);
    }
  };

  //show chapter popup
  const handleChapter = () => setShowChapter(true);


  const UploadGameSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').strict().trim("Name should not have trailing white spaces")
      .min(2).max(55),
    internalTitle: Yup.string().required('Internal Title is required').strict().trim("Internal Title should not have trailing white spaces"),
    // minutes: Yup.number().required("Minutes is required").strict().moreThan(0, "Please enter valid minutes"),
    // minutes: Yup.string().required("Minutes is required").min(1).max(500).matches(/[0-9]/, "Please enter valid minutes"),
    // seconds: Yup.number().required("Seconds is required").min(0).max(59),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      internalTitle: "",
      
      // seconds:""
    },

    validationSchema: UploadGameSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        console.log(values)
        let sendData = {
          name: values.name,
          internalTitle: values.internalTitle,          
        }
        const gameData = await gameAdd(sendData);

        if (gameData.status === 200) {
          // setShow(true);
          setgameId(gameData?.data.data._id);
          toast.success(gameData?.data.message);
          navigate('/admin/gamelist');
        }

      } catch (error) {
        console.log(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleTimeDuration = (e) => {
    let v = e.target.value;
    let n = e.target.name;


    if (Number(v) === NaN || Number(v) === null || Number(v) === undefined) {
      setFieldValue(e.target.name, 0)
    } else if (Number(v) < 0) {
      setFieldValue(e.target.name, 0)
    } else {
      setFieldValue(e.target.name, parseInt(e.target.value))
    }
  }

  return (
    <>
      <Card>
        <Card.Header title="Add Game">
          <div className="row">
            <div className="col-sm-9">
              <Card.Title as="h5">Add Game</Card.Title>
            </div>
          </div>
        </Card.Header>
        <div style={{ padding: '20px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel>Name*</InputLabel>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    placeholder='Enter Name'
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps('name')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Internal Title*</InputLabel>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    placeholder='Enter Internal Title'
                    value={values.internalTitle}
                    error={Boolean(touched.internalTitle && errors.internalTitle)}
                    helperText={touched.internalTitle && errors.internalTitle}
                    {...getFieldProps('internalTitle')} />
                </Grid>
                {/* <Grid item xs={12} md={6}>        
                    <InputLabel>Seconds*</InputLabel>    
                    <TextField 
                      fullWidth
                      required
                      variant="standard" 
                      placeholder='Enter Seconds'
                      value={values.seconds}
                      name="seconds"
                      type="number"
                      error={Boolean(touched.seconds && errors.seconds)}
                      helperText={touched.seconds && errors.seconds}
                      onChange={(e)=>{handleTimeDuration(e)}} />            
                </Grid>                                                                                                                       */}
              </Grid>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                <Button type="reset" onClick={() => { navigate("/admin/gamelist") }} className="btn btn-outline-secondary mr-3">
                  BACK
                </Button>
                <Button type="submit" className="btn btn-primary">
                  SAVE
                </Button>
              </Box>
            </Form>
          </FormikProvider>
        </div>
      </Card>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Body>Do you want to add chapter?</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline-secondary" onClick={handleClose}>
            NO
          </Button>
          <Button className="btn btn-primary" onClick={handleChapter}>
            YES
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={showChapter} onHide={handleChapterClose} backdrop="static">
        <Modal.Body>
          <h4>Add Chapter</h4>
          <AddChapter gameId={gameId} setShowChapter={setShowChapter} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Game;
