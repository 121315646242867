import React, { useState, useRef, useEffect } from 'react';
import { getChapterAll, reorderChapter } from '../Services/ChapterServices';
import DeleteChapterConfirm from './DeleteChapterConfirm';

import EditPuzzle from './EditChapter/EditPuzzle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';

import Tooltip from '@mui/material/Tooltip';
import AddChapter from './AddChapter';
import { Card, Button, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TrashChapter from './TrashChapter';


const ChapterList = () => {
  const [deleteId, setDeleteId] = useState('');
  const [editId, setEditId] = useState('');
  const [show, setShow] = useState(false);
  const [showChapter, setShowChapter] = useState(false);
  const [showChapterTrash, setShowChapterTrash] = useState(false);
  const [editChapterShow, setEditChapterShow] = useState(false);
  const { gameId } = useParams();
  const navigate = useNavigate();
  const tableRef = useRef();
  const [listData, setListData] = useState({
    data: [],
    count: 0
  });
  const [refresh, setRefresh] = useState(false);

  // close the chapter modal
  const handleChapterClose = () => setShowChapter(false);
  const handleTrash = () => {
    setShowChapterTrash(true)
  };
  const handleTrashClose = () => setShowChapterTrash(false);

  //get the data to display in list
  const getData = async (query) => {
    let body;
    // const { page, orderBy, orderDirection  } = query;

    // body = { 
    //   search: query?.search, 
    //   pageNumber: query?.page + 1, 
    //   pageSize: query?.pageSize, 
    //   gameId: gameId,
    //   sortBy: orderBy?.field,
    //   sortOrder: orderDirection==='asc' ? 1 : -1,
    // };
    body = {
      search: '',
      pageNumber: 1,
      pageSize: 100,
      gameId: gameId,
      sortBy: '',
      sortOrder: '',
    };

    // if (query.filters.length) {
    //   query.filters.map((data) => {
    //     if (data.value.length === 1) {
    //       body[data.column.field] = data.value[0];
    //     }
    //   });
    // }
    const response = await getChapterAll(body);
    const data = response?.data?.data?.result;
    const totalCount = response?.data?.data?.totalChapters;
    console.table(data)
    setListData({
      data: data,
      count: totalCount
    });
    // return {
    //   data,
    //   page,
    //   totalCount
    // };
  };


  useEffect(() => {
    getData()
  }, [refresh])


  //open the modal to Add the chapter
  const handleAddChapter = () => {
    setShowChapter(true);
  };
  //open the modal to edit the chapter
  const handleChapterEdit = (chapter) => {
    setEditId(chapter._id);
    setEditChapterShow(true);

  };
  // show the modal when click on delete
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  //close the chapter modal
  const handleEditChapterClose = () => setEditChapterShow(false);

  const handleViewChapter = (data) => {
    navigate(`/admin/chapter/${gameId}/${data._id}`)
  }



  const onDragEnd = async (result) => {
    console.log(result);
    const { draggableId, destination, source } = result;
    if (!destination) return;
    if (source.index !== destination.index) {
      console.log(listData)
      console.log("source", listData.data[source.index]);
      console.log("destination", listData.data[destination.index]);
      const sendData = {
        newOrder: destination.index,
        oldOrder: source.index,
        // newChapterId :listData.data[source.index]._id,
        // oldChapterId:listData.data[destination.index]._id,
        gameId: gameId
      }
      let resp = await reorderChapter(sendData);

      if (resp.status === 200) {
        if (true) {
          setRefresh(!refresh)
        }
      }
      return;
    }
  };

  return (
    <>
      <Card style={{ padding: '0px', paddingBottom: '50px' }}>
        <Card.Header>
          <Card.Title as="h5">Chapter List </Card.Title>
        </Card.Header>
        <span style={{ margin: "20px" }}>
          <Button className="btn btn-primary" onClick={() => handleAddChapter()}>
            ADD
          </Button>
          {/* <Button className="btn btn-primary" onClick={() => handleTrash()}>
            TRASH{' '}
          </Button> */}

        </span>
        <div style={{ background: "#FFF", height: "auto", padding: "5px 20px", color: '#000' }}>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={"singleColumnDroppableAreaThusStaticInput"}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div className='chapter-table-header'>
                    <div style={{ minWidth: '20%' }}>
                      <h6><b>Reorder</b></h6>
                    </div>
                    <div style={{ minWidth: '55%', textAlign: 'left' }}>
                      <h6><b>Chapter Name</b></h6>
                    </div>
                    <div style={{ minWidth: '25%' }}>
                      <h6><b>Action</b></h6>
                    </div>
                  </div>
                  {
                    listData.data.length ? listData.data.map((element, idx) => (
                      <Draggable
                        key={idx}
                        draggableId={element?._id.toString()}
                        index={idx}
                        style={{ background: "#000" }}
                      >
                        {(provided) => {
                          // console.log(props.data);
                          // let data = props.data;
                          return (
                            <div
                              key={element._id}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {element.deleteStatus != true ?
                                <div style={{ height: "auto", cursor: 'default', display: 'flex', justifyContent: 'space-between', padding: '5px 20px', border: "1px solid #000" }}>
                                  <div style={{ minWidth: '20%' }}>
                                    <OpenWithIcon style={{ cursor: 'grab' }} />
                                  </div>
                                  <div style={{ minWidth: '55%', cursor: 'pointer', textAlign: 'left' }} onClick={() => { handleViewChapter(element) }} >
                                    <p>{element.name}</p>
                                  </div>
                                  <div style={{ minWidth: '25%' }}>
                                    <>
                                      <Tooltip title="Edit Chapter">
                                        <EditIcon onClick={() => handleChapterEdit(element)} style={{ marginRight: '15px', cursor: 'pointer' }} />
                                      </Tooltip>
                                      <Tooltip title="Delete Chapter">
                                        <DeleteIcon
                                          className='dlt-icon'
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            handleShow(element._id);
                                          }}
                                        />
                                      </Tooltip>
                                    </>
                                  </div>
                                </div> : ""}
                            </div>)
                        }}
                      </Draggable>

                    ))
                      : <div style={{ height: "50px", cursor: 'default', display: 'flex', justifyContent: 'center', padding: '5px 20px', border: "1px solid #000" }}>
                        <p>No Chapter available</p>
                      </div>}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Card>
      <DeleteChapterConfirm refreshList={() => { getData(); }} deleteId={deleteId} show={show} setShow={setShow} />
      {/* <Modal show={showChapter} onHide={handleChapterClose}>
             <Modal.Body>Do you want to edit chapter?</Modal.Body>
             <Modal.Footer>
               <Button variant="secondary" onClick={handleChapterClose}>
                 No
               </Button>
               <Button variant="secondary" onClick={handleEditChapter}>
                 Yes
               </Button>
             </Modal.Footer>
           </Modal> */}
      <Modal dialogClassName="modal-90w" centered size="xl" show={editChapterShow} onHide={handleEditChapterClose} backdrop="static">
        <Modal.Body style={{ padding: '20px' }}>
          <h4 style={{ marginBottom: '20px' }}>Edit Chapter </h4>
          <EditPuzzle refreshList={() => { getData(); }} gameId={gameId} editId={editId} onClose={handleEditChapterClose} />
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showChapter} centered onHide={handleChapterClose} backdrop="static">
        <Modal.Body style={{ padding: '20px' }}>
          <h4 style={{ marginBottom: '20px' }}>Add Chapter</h4>
          <AddChapter refreshList={() => { getData(); }} gameId={gameId} setShowChapter={setShowChapter} />
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showChapterTrash} centered onHide={handleTrashClose} backdrop="static">
        <Modal.Body style={{ padding: '20px' }}>
          <h4 style={{ marginBottom: '20px' }}>Trash Chapter</h4>
          <TrashChapter refreshList={() => { getData(); }} setShowChapterTrash={setShowChapterTrash} />
          {/* <AddChapter refreshList={() => { getData(); }} gameId={gameId} setShowChapterTrash={setShowChapterTrash} /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChapterList;
