import React from 'react';
import { gameDelete } from '../Services/GameServices';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DeleteConfirm = ({ show, deleteId, setShow, tableRef }) => {
  const handleClose = () => setShow(false);
  //delete the game
  const handleGameRemove = async () => {
    setShow(false);

    try {
      const res = await gameDelete({ _id: deleteId });
      toast.success(res?.data.message);
      tableRef.current.onQueryChange();
    } catch (err) {}
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline-secondary btn btn-primary" onClick={handleClose}>
            NO
          </Button>
          <Button className="btn btn-primary" onClick={handleGameRemove}>
            YES
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteConfirm;
