import axios from 'axios';
import { contentUrl } from '../Config/config';
import Interceptor from '../../../config/Interceptor';

export const getContent = () => {
    const token = localStorage.getItem('token');
    return Interceptor().post(`${contentUrl}/getOneContentDetails`,{}, {
      headers: {
        Authorization: ` Bearer ${token}`
      }
    });
  };
  

export const updateContent = (data) => {
    const token = localStorage.getItem('token');
    return Interceptor().post(`${contentUrl}/updateContent`,data, {
      headers: {
        Authorization: ` Bearer ${token}`
      }
    });
  };
  