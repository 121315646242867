import React from 'react';
import Upload from './AddChapter';

const Chapter = () => {
  return (
    <>
      <Upload />
    </>
  );
};

export default Chapter;
