import React from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap';
import {Box } from '@material-ui/core';

const ImageModal = ({show, data, onClose}) => {

    return (
        <>
            <Modal size="auto" centered show={show} backdrop="static">
            <ModalBody>
                <Box
                    component="img"
                    alt="file preview"
                    src={data}
                    sx={{
                      objectFit: 'contain',
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                <Button type="submit" onClick={()=>{onClose()}} style={{margin: '1px', marginTop: '9px'}} className="btn btn-outline-secondary bt-sm float-right">
                    Back
                </Button>
            </ModalBody>
            </Modal>
        </>
    )
}

export default ImageModal;