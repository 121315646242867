import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TeamOneView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState(location?.state);
  const onListTeam = () => {
    navigate('/admin/teamlist');
  };
  return (
    <>
      <button className="btn btn-primary mt-2" onClick={(e) => onListTeam(e)}>
        Back
      </button>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Team Name</th>
            <th scope="col">Pod</th>

            <th scope="col">Game</th>
            <th scope="col">startTime</th>
            <th scope="col">endTime</th>
          </tr>
        </thead>

        <tbody>
          <td>{teamData?.teamName}</td>
          <td>{teamData?.podId.name}</td>
          <td>{teamData?.gameId.name}</td>
          <td>
            {' '}
            {teamData.startTime.hours}:{teamData.startTime.minutes}
          </td>
          <td>
            {' '}
            {teamData.endTime.hours}:{teamData.endTime.minutes}
          </td>
        </tbody>
      </table>
    </>
  );
};

export default TeamOneView;
