import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';
import './assets/css/style.css'
import './assets/css/animation.css'
// import './assets/css/owl.carousel.min.css'
import './assets/css/responsive.css'
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Provider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </LocalizationProvider>,
  document.getElementById('root')
);

reportWebVitals();
