import React, { useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';
import clueIcon from "../../../assets/images/button-img-toggle.png";
// Material ui
import {
    Card,
    Dialog,
    Drawer,
    Typography,
    Grid,
    Box,
    DialogContent
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
// Icon
import AudioTrackIcon from '@mui/icons-material/Audiotrack';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import DescriptionIcon from '@mui/icons-material/Description';
import { Base_URL } from '../Config/Config';
import { Modal, ModalBody } from 'react-bootstrap';
import DataViewer from './DataViewer';
import { SocketContext } from '../../../contexts/socketConnection';
import { useDispatch, useSelector } from 'react-redux';
import SolutionView from './SolutionView';
import closeIcon from "../../../assets/images/close-icon.png";
import imageBlack from "../../../assets/images/imageblack.png";
import audioBlack from "../../../assets/images/audioblack.png";
import vedioBlack from "../../../assets/images/vedioblack.png";
import discributionFile from "../../../assets/images/file.svg"
import {
    chapterDataSuccess,
    chapterFun,
    gameDataSuccess,
    gameFinished,
    hintFun,
    penaltyFun,
    solutionFun,
    teamFun,
} from "../../../Actions/ActionCreators";

const ContentStyle = styled('div')(() => ({
    backgroundColor: "#1D1C1C",
    color: "#FFF",
    border: "1px solid #FFF",
    padding: "10px",
    fontWeight: 800,
    textAlign: "center",
    alignItems: 'center',
    width: '100%',
    marginTop: "25px",
    minHeight: "100%",
    cursor: 'pointer',
}));

const TakenStyle = styled('div')(() => ({
    display: 'flex',
    backgroundColor: "#02DC81",
    border: "1px solid #FFF",
    padding: "10px",
    textAlign: "left",
    gap: '10px',
    width: "100%",
    marginTop: "25px",
    minHeight: "100%",
    cursor: 'pointer',
    textTransform: 'uppercase'
}));

const TakenSolutionStyle = styled('div')(() => ({
    backgroundColor: "#02DC81",
    border: "1px solid #FFF",
    padding: "15px 5px 5px",
    textAlign: "center",
    fontWeight: 800,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: "25px",
    minHeight: "100%",
    cursor: 'pointer',
}));

const IconStyle = styled('div')(() => ({
    backgroundColor: "#1D1C1C",
    color: "#FFF",
    borderRadius: "45px",
    padding: "10px 5px",
    border: "1px #FFF solid",
    marginRight: "15px"

}))

const RootStyle = styled('div')(() => ({
    fontFamily: 'PFDinMonoRegular',
    justifyContent: 'center',
    alignItems: 'center'
}))


export default function Hints({ data, solutionTaken, setSolutionTaken, penalty, hintTaken, show, handleClose, hintLabel, labels, gameId, podId, setPenalty }) {

    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const teamDetail = useSelector((state) => state.team.data);

    const getAnswerData = (data) => ({ id: data?._id, solution: data?.solution, solutionPoints: data?.solutionPoints })


    const answerData = useSelector(state => state.chapter.data && Object.keys(state.chapter.data).length ? getAnswerData(state.chapter.data) : {})
    const chapterIdd = useSelector(state => state.chapter.data && state.chapter.data._id)

    const chapterName = useSelector(state => state.chapter.data && state.chapter.data.name)


    const isRevealed = (data) => {

        if (solutionTaken.filter(d => d.chapterId == data.id).length) {
            return true;
        } else {
            return false;
        }
    }

    const [view, setView] = useState({
        show: false,
        data: null
    });

    const [solution, setSolution] = useState({
        show: false,
        data: null
    });



    const handleOpenView = (data) => {
        socket.emit('hintsTaken', {
            teamId: teamDetail.teamDetails._id,
            roomName: teamDetail.teamDetails.teamName,
            points: data.points,
            hintId: data._id,
            gameId: gameId,
            podId: podId,
            chapterId: chapterIdd,
            hintName: data.title,
            chapterName: chapterName
        })

        // setPenalty(prev => {
        //     if (hintTaken?.includes(data._id)) return prev
        //     return prev + data.points
        // })
    }



    const handleCloseView = () => {
        setView({
            ...view,
            show: false,
            data: null
        })
        // handleClose()
    }
    const handleShowSolution = (data) => {
        const chapterId = data.id;

        socket.emit('revealAnswerUsed', {
            teamId: teamDetail.teamDetails._id,
            roomName: teamDetail.teamDetails.teamName,
            points: data.solutionPoints,
            chapterId: chapterId,
        });
        if (solutionTaken.some((el) => el.chapterId === chapterId)) {
            // Solution has already been taken, no need to proceed
            return;
        }

        const newSolutionTaken = [
            ...solutionTaken,
            {
                chapterId: chapterId,
                taken: true,
                _id: data.solutionPoints._id,
            },
        ];

        const newPenalty = penalty + data.solutionPoints;


        setSolutionTaken(newSolutionTaken);
        setPenalty(newPenalty);
    };


    const handleCloseSolution = () => {
        setSolution({
            show: false,
            data: null
        })
    }
    // useEffect(() => {
    //     window.location.reload()
    // })
    useEffect(() => {
        socket.on('hintsTaken', (data) => {
            setView({
                ...view,
                show: true,
                data: { ...data.hintData }
            })
            handleClose()
        })

        socket.on('revealAnswerUsed', (data) => {
            if (data?.solutionTaken && data?.solutionTaken.taken) {
                setSolution({
                    show: true,
                    data: { ...answerData }
                })
                setPenalty(data?.penalty)
                handleClose()
                setSolutionTaken([data.solutionTaken])
            }
        })

        socket.on('codeSuccess', () => {
            handleCloseSolution();
            handleCloseView();
        })


    }, [])



    return (

        <RootStyle>
            <Modal maxwidth="495px" show={show} onHide={() => { handleClose() }} centered backdrop="static">
                <Box style={{ display: "flex", padding: "2%", backgroundColor: "#1D1C1C", color: "#FFFB00", justifyContent: "flex-end", cursor: 'pointer' }}>
                    <Typography style={{ color: "#FFFB00", gap: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => { handleClose() }} >
                        <img src={closeIcon} style={{ cursor: 'pointer', height: '20px' }} />
                        <span style={{ color: "#FFF", letterSpacing: '0.32px', fontSize: '14px' }}>{labels?.closeButton}</span>
                    </Typography>
                </Box>
                <ModalBody style={{ backgroundColor: "#1D1C1C", padding: "0 12% 10% 12%" }}>
                    {/* <h4 style={{color:"#FFF", textAlign:'center'}}>{_.toUpper(hintLabel)}</h4> */}
                    {(data && data.length === 0 && (answerData.solution && answerData.solution.length === 0)) ?
                        (<div className="no-clues">
                            <span className="text-center mt-3"> {labels.nohintlabel}</span>
                        </div>
                        )
                        : <></>}
                    {(data && data.length > 0) && data.map((element, idx) => {
                        return (
                            element.deleteStatus === false ?
                                (teamDetail.hintsTaken && teamDetail.hintsTaken.includes(element._id)) || (hintTaken.includes(element._id)) ?
                                    <TakenStyle key={idx} onClick={() => { handleOpenView(element) }}>
                                        {element.mediaType && element.mediaType.split('/')[0] === 'video' ?
                                            <img src={vedioBlack} />
                                            :
                                            element.mediaType && element.mediaType.split('/')[0] === 'audio' ?
                                                <img src={audioBlack} />
                                                :
                                                element.mediaType && element.mediaType.split('/')[0] === 'image' ?
                                                    <img src={imageBlack} />
                                                    :
                                                    <img src={discributionFile} />
                                        }
                                        <h4 style={{ fontSize: '20px', fontWeight: 700, paddingTop: "10px" }}>{element.title}</h4>
                                    </TakenStyle>
                                    :
                                    <ContentStyle key={idx} onClick={() => { handleOpenView(element) }}>
                                        <h4 style={{ color: "inherit" }}>{element.title}</h4>
                                        <Typography variant='body1' style={{ color: "#FFFB00" }}>{element.points} {(element.points <= 1 ? (labels?.point ? labels?.point : "POINT") : (labels?.points ? labels?.points : "POINTS"))}</Typography>
                                    </ContentStyle>
                                : null
                        )
                    })}
                    {(answerData && answerData.solution && answerData.solution.length > 0) ?
                        (isRevealed(answerData) ?
                            <TakenSolutionStyle onClick={() => { handleShowSolution(answerData) }}>
                                <h4>{labels?.revealanswer ? labels?.revealanswer : "REVEAL ANSWER"}</h4>
                            </TakenSolutionStyle>
                            :
                            <ContentStyle onClick={() => { handleShowSolution(answerData) }}>
                                <h4 style={{ color: "inherit" }}>{labels?.revealanswer ? labels?.revealanswer : "REVEAL ANSWER"}</h4>
                                <Typography variant='body1' style={{ color: "#FFFB00" }}>{answerData.solutionPoints} {(answerData.solutionPoints <= 1 ? (labels?.point ? labels?.point : "POINT") : (labels?.points ? labels?.points : "POINTS"))}</Typography>
                            </ContentStyle>
                        ) : <></>}
                </ModalBody>
            </Modal>
            <DataViewer
                labels={labels}
                show={view?.show}
                data={view?.data}
                onClose={() => { handleCloseView() }}
            />
            <SolutionView
                labels={labels}
                show={solution.show}
                data={solution.data}
                onClose={() => { handleCloseSolution() }}
            />
        </RootStyle>
    )
}
