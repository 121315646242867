import React, { useRef,useEffect,useState } from 'react'
import { Card, Button } from 'react-bootstrap';
import MaterialTable from 'material-table';
import Tooltip from '@mui/material/Tooltip';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {getChapterTrash,getChapterAll} from "../../Services/ChapterServices"
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {Box} from '@material-ui/core';
import OpenWithIcon from '@mui/icons-material/OpenWith';

const TrashChapter = ({setShowChapterTrash, refreshList}) => {
    const tableRef = useRef();
  const { gameId } = useParams();
  const navigate=useNavigate()
  const [listData, setListData] = useState({
    data: [],
    count: 0
  });
    
    const handleGameRestore = async (deleteId) => {
        console.log(deleteId)
        try {
            const res = await getChapterTrash({ _id: deleteId });
            setShowChapterTrash(false)
            refreshList();
            // toast.success(res?.data.message);
            // tableRef.current.onQueryChange();
        } catch (err) { }
    };
    const getData = async (query) => {
        let body;
        // const { page, orderBy, orderDirection  } = query;
    
        // body = { 
        //   search: query?.search, 
        //   pageNumber: query?.page + 1, 
        //   pageSize: query?.pageSize, 
        //   gameId: gameId,
        //   sortBy: orderBy?.field,
        //   sortOrder: orderDirection==='asc' ? 1 : -1,
        // };
        body = {
          search: '',
          pageNumber: 1,
          pageSize: 100,
          gameId: gameId,
          sortBy: '',
          sortOrder: '',
        };
    
        // if (query.filters.length) {
        //   query.filters.map((data) => {
        //     if (data.value.length === 1) {
        //       body[data.column.field] = data.value[0];
        //     }
        //   });
        // }
        const response = await getChapterAll(body);
        const data = response?.data?.data?.result;
    const totalCount = response?.data?.data?.totalChapters;
        
        console.table(data)
        setListData({
          data: data,
          count: totalCount
        });
        return {
          data
        };
      };
      useEffect(() => {
        getData()
      }, [])
    
  return (
    <>
     {/* <Card style={{ padding: '0px', paddingBottom: '50px' }}> */}
        <div style={{ background: "#FFF", padding: "5px 20px", color: '#000' }}>
                <div >
                  <div className='chapter-table-header'>
                    <div style={{ minWidth: '55%', textAlign: 'left' }}>
                      <h6><b>Chapter Name</b></h6>
                    </div>
                    <div style={{ minWidth: '25%' }}>
                      <h6><b>Action</b></h6>
                    </div>
                  </div>
                  {listData.data.length ? listData.data.map((element, idx) => (
                      <div>
                              {element.deleteStatus===true?
                              <div style={{ height: "auto", cursor: 'default', display: 'flex', justifyContent: 'space-between', padding: '5px 20px', border: "1px solid #000" }}>
                                
                                <div style={{ minWidth: '55%', textAlign: 'left' }}  >
                                  <p>{element.name}</p>
                                </div>
                                <div style={{ minWidth: '25%' }}>
                                  <>
                                      <RestoreFromTrashIcon
                                        className='dlt-icon'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleGameRestore(element._id);
                                        }}
                                      />
                                  </>
                                </div>
                              </div>:""}
                            </div>
                      

                    ))
                      : <div style={{ height: "50px", cursor: 'default', display: 'flex', justifyContent: 'center', padding: '5px 20px', border: "1px solid #000" }}>
                        <p>No Chapter available</p>
                      </div>}
                </div>
             
        </div>
      {/* </Card> */}
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
            <Button type="reset" onClick={() => { setShowChapterTrash(false) }} className="btn btn-outline-secondary mr-3">
              BACK
            </Button>
          </Box>

    </>
  )
}

export default TrashChapter