import axios from 'axios';
import { dashboardUrl } from '../Config/config';
import { getToken } from '../../../utils/token';
import Interceptor from '../../../config/Interceptor';

var body = {};

export const getDashboard = () => {
  return Interceptor().post(`${dashboardUrl}/AllStatistics`, body, {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  });
};
