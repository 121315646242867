import React, { useState, useRef } from 'react';
import { copyGame, gameAdd, gameList, gameTrash } from '../Services/GameServices';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteConfirm from './DeleteConfirm';
import Multiselect from 'multiselect-react-dropdown';
import { Card, Modal, Button } from 'react-bootstrap';
import AddChapter from '../ChapterManagement/AddChapter/index';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExtensionIcon from '@mui/icons-material/Extension';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import { pink, yellow } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { InputLabel, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const GameList = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState({
    show:false,
    data : null
  });
  const tableRef = useRef();
  const [filterData, setFilterData] = useState([]);
  const [showChapter, setShowChapter] = useState(false);
  const [gameId, setGameId] = useState('');
  const columns = [
    {
      title: 'Name', field: 'name', filtering: false, render: (rowData) => (
        <span style={{ cursor: "pointer" }} onClick={() => handleGameOne(rowData)}>{rowData.name}</span>
      )
    },
    { title: 'Internal Title', field: 'internalTitle', filtering: false },
    
    { title: 'Total Chapter', field: 'totalChapters', filtering: false },
    {
      title: 'Action',
      field: 'action',
      sorting: false,
      render: (rowData) => {
        return (
          <>
            {/* <Tooltip title="Add Chapter">
              <AddIcon sx={{ color: yellow[700] }} onClick={() => handleChapter(rowData)} style={{ marginRight: '10px', cursor:"pointer" }} />
            </Tooltip>
            <Tooltip title="View Chapter">
              <VisibilityIcon color="primary" onClick={() => handleChapterList(rowData)} style={{ marginRight: '10px', cursor:"pointer" }} />
            </Tooltip> */}
            {/* <Tooltip title="View Game">
              <ExtensionIcon onClick={() => handleGameOne(rowData)} style={{ marginRight: '20px' }} />
            </Tooltip> */}
            <Tooltip title="Edit">
              <EditIcon onClick={() => handleGameList(rowData)} style={{ marginRight: '10px', cursor: "pointer" }} />
            </Tooltip>
            {/* <Tooltip title="Leaderboard">
              <LeaderboardIcon onClick={() => handleLeaderboard(rowData)} style={{ marginRight: '20px' }}></LeaderboardIcon>
            </Tooltip> */}
            <Tooltip title="Delete">
              <DeleteIcon
                className='dlt-icon'
                onClick={() => {
                  handleShow(rowData._id);
                }}
                style={{ marginRight: '10px', cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip title="Duplicate">
              <FileCopyIcon                
                onClick={() => {
                  handleDuplicate(rowData);
                }}
                style={{ marginRight: '10px', cursor: "pointer" }}
              />
            </Tooltip>
          </>
        );
      }
    },
    // { title:'Labels' , field:'label', sorting:'false', render:(rowData)=>(
    //   <>
    //   {Object.keys(rowData.labelResult).length && rowData.labelResult._id ? 
    //   <Tooltip title="Update Labels">
    //     <EditIcon onClick={()=>{handleLabelEdit(rowData.labelResult)}}/>
    //   </Tooltip>
    //   :
    //   <Tooltip title="Add Labels">
    //     <AddCircleIcon onClick={()=>{handleAddLabel(rowData)}} />
    //   </Tooltip>

    //   }
    //   </>
    // )}
  ];
  const handleDuplicate = (data) =>{ 
    console.log(data)
    const sendData = {
      _id : data._id,
      name: data.name,
      internalTitle: data.internalTitle
    }
    
    setShowDuplicate({
      ...showDuplicate,
      show:true,
      data : {...sendData}
    })
  }
  const UploadGameSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').strict().trim("Name should not have trailing white spaces")
      .min(2).max(55),
    internalTitle: Yup.string().required('Internal Title is required').strict().trim("Internal Title should not have trailing white spaces"),
    // minutes: Yup.number().required("Minutes is required").strict().moreThan(0, "Please enter valid minutes"),
    // minutes: Yup.string().required("Minutes is required").min(1).max(500).matches(/[0-9]/, "Please enter valid minutes"),
    // seconds: Yup.number().required("Seconds is required").min(0).max(59),
  });
  const handleChangeName = (e) =>{
    let prevData = showDuplicate.data;
    prevData.name = e.target.value;

    setShowDuplicate({
      ...showDuplicate,
      data : {...prevData}
    })
  }
  const handleChangeTitle = (e) =>{
    let prevData = showDuplicate.data;
    prevData.internalTitle = e.target.value;
    setShowDuplicate({
      ...showDuplicate,
      data : {...prevData}
    })
  }
  const handleConfirmDuplicate = async () =>{
    if(showDuplicate.data.name.trim() && showDuplicate.data.name!==null && showDuplicate.data.name!==undefined&&showDuplicate.data.name!==""){
      if(showDuplicate.data.internalTitle.trim()&& showDuplicate.data.internalTitle!==null && showDuplicate.data.internalTitle!==undefined && showDuplicate.data.internalTitle!==""){
        try{
          const sendData = {
            _id : showDuplicate.data._id,
            newnameofgame : showDuplicate.data.name,
            newinternaltitle : showDuplicate.data.internalTitle          
          }
          const resp = await copyGame(sendData);
      
          if(resp.status===200){
            toast.success(resp?.data?.message);
            tableRef.current.onQueryChange();
          }
        }catch(err){
          console.log(err);
          toast.error(err?.response.data.message)
        }finally{
          setShowDuplicate({
            ...showDuplicate,
            show:false,
            data:null
          })
        }
      }else{
        toast.error("Internal title is required!!")
      }
    }else{
      toast.error("Please enter game name!!")
    }
  }

  const handleCloseDuplicate = () =>[
    setShowDuplicate({
      ...showDuplicate,
      show:false,
      data:null
    })
  ]

  const handleAddLabel = (data) => {
    navigate("/admin/label/add", { state: data })
  }


  const handleLabelEdit = (data) => {
    navigate('/admin/label/edit/' + data._id, { state: data })
  }

  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const handleChapterList = (data) => {

    navigate('/admin/chapter/list/' + data._id, { state: data._id });
  };
  const handleChapter = (data) => {
    setGameId(data._id);
    setShowChapter(true);
  };
  const handleAdd = () => {
    navigate('/admin/game');
  };

  const handleTrash = () => {
    navigate('/admin/trash');
  };

  const handleGameList = (data) => {
    navigate('/admin/game/edit/' + data._id, { state: data });
  };
  const handleGameOne = (data) => {
    navigate('/admin/onegameview', { state: data });
  };

  const getData = async (query) => {
    let body;
    const { page, orderBy, orderDirection } = query;
    // const params = {
    //   page:query?.page,
    //   pageCount:query?.pageSize,
    //   searchItem:query?.search,
    //   order:orderDirection,
    //   orderBy:
    // }
    if (filterData?.length) {
      body = {
        search: query?.search,
        pageNumber: query?.page + 1,
        pageSize: query?.pageSize,
        totalChapters: filterData,
        [orderBy?.field === "totalChapters" ? 'sortByChapters' : 'sortBy']: orderBy?.field,
        sortOrder: orderDirection === 'asc' ? 1 : -1,
      };
    } else {
      body = {
        search: query?.search,
        pageNumber: query?.page + 1,
        pageSize: query?.pageSize,
        [orderBy?.field === "totalChapters" ? 'sortByChapters' : 'sortBy']: orderBy?.field,
        sortOrder: orderDirection === 'asc' ? 1 : -1,
      };
    }

    if (query.filters.length) {
      query.filters.map((data) => {
        if (data.value.length === 1) {
          body[data.column.field] = data.value[0];
        }
      });
    }
    const response = await gameList(body);
    const data = response?.data?.data?.finalResult;
    const totalCount = response?.data?.data?.totalGames;
    return {
      data,
      page,
      totalCount
    };
  };
  const handleFilter = async (selectedList, selectedItem) => {
    setFilterData((prev) => [...prev, selectedItem.id]);
    tableRef.current.onQueryChange();
  };
  const filterRemove = (selectedList, removedItem) => {
    const index = filterData.indexOf(removedItem.id);
    filterData.splice(index, 1);
    tableRef.current.onQueryChange();
  };
  const handleChapterClose = () => {
    setShowChapter(false);
    navigate('/admin/gamelist');
  };
  return (
    <div>
      <Card style={{ padding: '0px' }}>
        <Card.Header>
          <div className="row">
            <div className="col-sm-9">
              <Card.Title as="h5">Game List</Card.Title>
            </div>
            <div className="col-sm-3  justify-content-end ">
              <Multiselect
                className='filter'
                name="gameId"
                displayValue="key"
                placeholder="Chapter Filter"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={(selectedList, removedItem) => filterRemove(selectedList, removedItem)}
                onSearch={function noRefCheck() { }}
                onSelect={(selectedList, selectedItem) => handleFilter(selectedList, selectedItem)}
                showCheckbox
                options={[
                  { id: '0', key: '0' },
                  { id: '1', key: '1' },
                  { id: '2', key: '2' },
                  { id: '3', key: '3' },
                  { id: '4', key: '4' },
                  { id: '5', key: '5' },
                  { id: '6', key: '6' },
                  { id: '7', key: '7' }
                ]}
                style={{
                  chips: {
                    minWidth: "25px"
                  },
                  searchBox: {
                    display: "flex",
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: "1px solid #1d1c1c",
                    flexDirection: "row",
                    minWidth: "150px",
                    flexWrap: "wrap",
                    justifyContent: "justify"
                  }
                }}
                customCloseIcon={<CloseIcon style={{ cursor: 'pointer' }} />}
              />
            </div>
          </div>
        </Card.Header>
        <div>
          <MaterialTable
            tableRef={tableRef}
            title={
              <>
                <Button className="btn btn-primary btn-sm" onClick={() => handleAdd()}>
                  ADD{' '}
                </Button>
                <Button className="btn btn-primary btn-sm" onClick={() => handleTrash()}>
                  Trash{' '}
                </Button>
              </>
            }
            columns={columns}
            data={getData}
            options={{
              search: true,
              emptyRowsWhenPaging: false,
              headerStyle: { fontWeight: 'bold' },
              draggable: false
            }}
          />

          <DeleteConfirm tableRef={tableRef} deleteId={deleteId} show={show} setShow={setShow} />
        </div>
      </Card>
      <Modal size="xl" show={showChapter} onHide={handleChapterClose} backdrop="static">
        <Modal.Body>
          <h4>Add Chapter</h4>
          <AddChapter refreshList={() => { tableRef.current.onQueryChange(); }} gameId={gameId} setShowChapter={setShowChapter} />
        </Modal.Body>
      </Modal>
      <Modal show={showDuplicate.show} onHide={handleCloseDuplicate} backdrop="static" centered>
        <Modal.Body>
          <p>Do really want to duplicate this game with this name?</p>
          <div className='row'>
            <div className="col-md-12">
              <InputLabel>Name*</InputLabel>
              <TextField
                fullWidth
                variant="standard"
                required
                placeholder='Enter Name'
                value={showDuplicate?.data?.name} 
                onChange={(e)=>{handleChangeName(e)}}           
                />
            </div>
            <div className="col-md-12 mt-2">
              <InputLabel>Internal Title*</InputLabel>
              <TextField
                fullWidth
                variant="standard"
                required
                placeholder='Enter Internal Title'
                value={showDuplicate?.data?.internalTitle} 
                onChange={(e)=>{handleChangeTitle(e)}}           
                />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline-secondary" onClick={handleCloseDuplicate}>
            NO
          </Button>
          <Button className="btn btn-primary" onClick={handleConfirmDuplicate}>
            YES
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GameList;
