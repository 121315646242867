import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/user/logo.png';

const NavLogo = () => {

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
