import axios from 'axios';
import { backendUrl } from '../Config/config';

const login = async (values) => {
  return axios.post(`${backendUrl}/adminLogin`, values);
};

const forgetPassword = async (email) => {
  console.log('email', email);
  return axios.post(`${backendUrl}/forgotPasswordAdmin`, email);
};

const resetPassword = async (resetPassword, token) => {
  return await axios.post(`${backendUrl}/resetPasswordAdmin`, resetPassword, { params: { token: token } });
};

export { login, forgetPassword, resetPassword };
