import { PLAY, CLEAN_ALL_DATA } from '../Actions/Types';





const play = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            played: false
        };
    }


    switch (action.type) {
        case PLAY:
            return {
                ...state,
                played: true
            }
        case CLEAN_ALL_DATA:
            return {
                ...state,
                played: false
            }

        default:
            return state;
    }
}



export default play;