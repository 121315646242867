import React, { useState, useRef } from 'react'
import { gameTrash, gameRestore } from '../Services/GameServices';
import Tooltip from '@mui/material/Tooltip';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Card, Modal, Button } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { Grid, TextField, Box, CardHeader, CardContent, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Trash = () => {
    const [filterData, setFilterData] = useState([]);
    const tableRef = useRef();
    const navigate = useNavigate()
    const columns = [
        {
            title: 'Name', field: 'name', filtering: false,

        },
        { title: 'Internal Title', field: 'internalTitle', filtering: false },
        // {
        //     title: 'Time Duration', field: 'minutes', sorting: false, render: (rowData) => (
        //         <span>
        //             {`${rowData.minutes < 10 ? '0' + rowData.minutes : rowData.minutes} min`}
        //         </span>
        //     )
        // },
        {
            title: 'Action',
            field: 'action',
            sorting: false,
            render: (rowData) => {
                return (
                    <>
                        <Tooltip title="Recover">
                            <RestoreFromTrashIcon
                                className='dlt-icon'
                                onClick={() => {
                                    handleGameRestore(rowData._id);
                                }}
                                style={{ marginRight: '10px', cursor: "pointer" }}
                            />
                        </Tooltip>
                    </>
                );
            }
        },
    ];

    const handleGameRestore = async (deleteId) => {
        console.log(deleteId)
        try {
            const res = await gameRestore({ _id: deleteId });
            toast.success(res?.data.message);
            tableRef.current.onQueryChange();
        } catch (err) { }
    };

    const getData = async (query) => {
        let body;
        const { page, orderBy, orderDirection } = query;
        if (filterData?.length) {
            body = {
                search: query?.search,
                pageNumber: query?.page + 1,
                pageSize: query?.pageSize,
                totalChapters: filterData,
                [orderBy?.field === "totalChapters" ? 'sortByChapters' : 'sortBy']: orderBy?.field,
                sortOrder: orderDirection === 'asc' ? 1 : -1,
            };
        } else {
            body = {
                search: query?.search,
                pageNumber: query?.page + 1,
                pageSize: query?.pageSize,
                [orderBy?.field === "totalChapters" ? 'sortByChapters' : 'sortBy']: orderBy?.field,
                sortOrder: orderDirection === 'asc' ? 1 : -1,
            };
        }
        if (query.filters.length) {
            query.filters.map((data) => {
                if (data.value.length === 1) {
                    body[data.column.field] = data.value[0];
                }
            });
        }
        const response = await gameTrash(body);
        const data = response?.data?.data?.finalResult;
        const totalCount = response?.data?.data?.totalGames;
        return {
            data,
            page,
            totalCount
        };
    };
    const handleFilter = async (selectedList, selectedItem) => {
        setFilterData((prev) => [...prev, selectedItem.id]);
        tableRef.current.onQueryChange();
    };

    const filterRemove = (selectedList, removedItem) => {
        const index = filterData.indexOf(removedItem.id);
        filterData.splice(index, 1);
        tableRef.current.onQueryChange();
    };
    return (
        <div>
            <Card style={{ padding: '0px' }}>
                <Card.Header>
                    <div className="row">
                        <div className="col-sm-9">
                            <Card.Title as="h5">Game Trash List</Card.Title>
                        </div>
                        <div className="col-sm-3  justify-content-end ">
                            <Multiselect
                                className='filter'
                                name="gameId"
                                displayValue="key"
                                placeholder="Chapter Filter"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(selectedList, removedItem) => filterRemove(selectedList, removedItem)}
                                onSearch={function noRefCheck() { }}
                                onSelect={(selectedList, selectedItem) => handleFilter(selectedList, selectedItem)}
                                showCheckbox
                                options={[
                                    { id: '0', key: '0' },
                                    { id: '1', key: '1' },
                                    { id: '2', key: '2' },
                                    { id: '3', key: '3' },
                                    { id: '4', key: '4' },
                                    { id: '5', key: '5' },
                                    { id: '6', key: '6' },
                                    { id: '7', key: '7' }
                                ]}
                                style={{
                                    chips: {
                                        minWidth: "25px"
                                    },
                                    searchBox: {
                                        display: "flex",
                                        borderRadius: 0,
                                        border: 'none',
                                        borderBottom: "1px solid #1d1c1c",
                                        flexDirection: "row",
                                        minWidth: "150px",
                                        flexWrap: "wrap",
                                        justifyContent: "justify"
                                    }
                                }}
                            // customCloseIcon={<CloseIcon style={{ cursor: 'pointer' }} />}
                            />
                        </div>
                    </div>
                </Card.Header>
                <div>
                    <MaterialTable
                        tableRef={tableRef}
                        title="Trash Management"
                        columns={columns}
                        data={getData}
                        options={{
                            search: true,
                            emptyRowsWhenPaging: false,
                            headerStyle: { fontWeight: 'bold' },
                            draggable: false
                        }}
                    />

                    {/* <DeleteConfirm tableRef={tableRef} deleteId={deleteId} show={show} setShow={setShow} /> */}
                </div>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                    <Button type="reset" onClick={() => { navigate("/admin/gamelist") }} className="btn btn-outline-secondary mr-3">
                        BACK
                    </Button>
                </Box>
            </Card>
            {/* <Modal size="xl" show={showChapter} onHide={handleChapterClose} backdrop="static">
                <Modal.Body>
                    <h4>Add Chapter</h4>
                    <AddChapter refreshList={() => { tableRef.current.onQueryChange(); }} gameId={gameId} setShowChapter={setShowChapter} />
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default Trash 