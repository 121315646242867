import React, { useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
const EditValidation = ({
  update,
  teamName,
  setTeamName,
  onGameSelect,
  options,
  onPodSelect,
  podoptions,
  podId,
  gameId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit,
  error
}) => {
  const navigate = useNavigate();
  const [t, SetT] = useState({
    start: `${update.startTime.hours}:${update.startTime.minutes}`,
    end: `${update.endTime.hours}:${update.endTime.minutes}`
  });
  const handleTeamList = () => {
    navigate('/admin/teamlist');
  };

  const handleStartTimer = (e) => {
    console.log('Eeeeeeeeeeeeeeeee', e.split(':'));
    if (startDate < endDate || startDate >= '00:00') {
      setStartDate(e);
      setEndDate(e);
    }
  };
  const handleEndTimer = (e) => {
    console.log('Eeeeeeeeeeeeeeeee', e.split(':'));
    if (e > startDate) {
      setEndDate(e);
    }
  };

  return (
    <>
      <form>
        <div className="form-group">
          <label>Enter the Team name*</label>
          <input type="text" name="teamname" className="form-control" value={teamName} onChange={(e) => setTeamName(e.target.value)} />
          {error && teamName.length === 0 && <small className="text-danger form-text">team name required</small>}
        </div>

        <div className="form-group">
          <label>Select Game*</label>
          <Multiselect
            className="form-control"
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={(e) => onGameSelect(e)}
            options={options}
            selectedValues={[{ key: update?.gameId.name, id: update?.gameId._id }]}
            showCheckbox
            singleSelect
          />
        </div>
        {error && gameId.length === 0 && <small className="text-danger form-text">game is required</small>}
        <br />
        <div className="form-group">
          <label>Select Pod*</label>
          <Multiselect
            className="form-control"
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={(e) => onPodSelect(e)}
            options={podoptions}
            selectedValues={[{ key: update?.podId.name, id: update?.podId._id }]}
            showCheckbox
            singleSelect
          />
          {error && podId.length == 0 && <small className="text-danger form-text">pod is required</small>}
        </div>
        <br />
        <label htmlFor="starttime">Start Time*</label>
        <input
          type="time"
          //   value={t.start}
          value={startDate}
          onChange={(e) => {
            handleStartTimer(e.target.value);
            SetT({ ...t, ['start']: e.target.value });
          }}
        />
        {error && startDate.length == 0 && <small className="text-danger form-text">start time required</small>}
        <br />
        <label htmlFor="endtime">End Time*</label>
        <input
          type="time"
          //   value={t.end}
          value={endDate}
          onChange={(e) => {
            handleEndTimer(e.target.value);
            SetT({ ...t, ['end']: e.target.value });
          }}
        />
        {error && endDate.length == 0 && <small className="text-danger form-text">end time required</small>}
      </form>
      <button className="btn btn-primary mt-2" onClick={() => handleTeamList()}>
        Back
      </button>
      <button className="btn btn-success mt-2" onClick={(e) => handleSubmit(e)}>
        Add
      </button>
    </>
  );
};

export default EditValidation;
