import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { Modal, ModalBody } from 'react-bootstrap';
import { Base_URL } from '../Config/Config';
import closeIcon from "../../../assets/images/close-icon.png"
import AudioPlayer from '../../../components/AudioPlayer'
export default function DataViewer({ show, data, onClose, labels }) {
    // console.log(data)
    return (
        data &&
        <Modal show={show} onHide={() => { onClose() }} centered backdrop="static">
            <Box style={{ display: "flex", paddingTop: "2%", paddingRight: "2%", backgroundColor: "#1D1C1C", color: "#FFFB00", justifyContent: "flex-end", cursor: 'pointer' }}>
                <Typography style={{ color: "#FFFB00", gap: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { onClose() }}>
                    <img src={closeIcon} style={{ cursor: 'pointer', height: '20px' }} />
                    <span style={{ color: "#FFF", letterSpacing: '0.32px', fontSize: '14px' }}>{labels?.closeButton}</span>
                </Typography>
            </Box>
            <ModalBody className={data.text ? 'need-hint-content text-center' : 'need-hint-content text-center p-0'}>
                <h3 className='text-white'>{labels?.hinttaken ? labels?.hinttaken : "HINT TAKEN"}</h3>
                <h4>{(labels?.penalty ? labels?.penalty : "PENALTY")} : {data.points} {labels.mins}</h4>
                {data.mediaType && (data.mediaType).split("/")[0] === "video" ?
                    <video width="100%" key={data._id} preload="auto" controlsList="nodownload" controls className="card-media">
                        <source src={`${Base_URL}/${data.url}`}></source>
                    </video>
                    : data?.mediaType && (data.mediaType).split("/")[0] === "image" ?
                        <Box
                            component="img"
                            alt="file preview"
                            src={`${Base_URL}/${data.url}`}
                            sx={{
                                objectFit: 'cover',
                                width: '100%',
                            }}
                        />
                        : data?.mediaType && (data.mediaType).split("/")[0] === "audio" ?
                            <div id="hint-need-audio">
                                <AudioPlayer src={`${Base_URL}/${data.url}`} />
                            </div>
                            :
                            <>
                                {data.text &&
                                    <p>{data.text}</p>
                                }
                            </>
                }
                <div className='hintFooter'>
                    <p className='mt-2 text-white text-justify'>{data.description}</p>
                </div>

            </ModalBody>
        </Modal>
    )
}
