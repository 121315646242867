import React from 'react';
import { url } from '../../Config/config';
import { Grid, Box } from '@material-ui/core';
import AudioPlayerAdmin from '../../../../components/AudioPlayerAdmin';

const condition = ['png', 'jpg', 'jpeg'];
const EditPuzzleDisplay = ({ displayPuzzle }) => {
  console.log(displayPuzzle)
  return (
    <>
      {displayPuzzle && Object.keys(displayPuzzle).length ?
        <Grid container spacing={3}>
          {displayPuzzle.mediaType && (displayPuzzle.mediaType).split("/")[0] === "video" ?
            <Grid item lg={displayPuzzle.fileType === 'uploaded' ? 12 : 12} md={displayPuzzle.fileType === 'uploaded' ? 12 : 12} xs={12}>
              <video
                preload="auto"
                controls
                controlsList="nodownload"
                style={{
                  top: 8,
                  borderRadius: 1,
                  // border:"2px solid #000",              
                  objectFit: 'contain',
                  // padding: '5px',
                  width: '100%',
                  height: '200px'
                }}
              >
                <source src={displayPuzzle.fileType === 'uploaded' ? displayPuzzle.url : `${url}${displayPuzzle.url}`}></source>
              </video>
            </Grid>
            : displayPuzzle.mediaType && (displayPuzzle.mediaType).split("/")[0] === "image" ?
              <Grid item lg={displayPuzzle.fileType === 'uploaded' ? 12 : 12} md={displayPuzzle.fileType === 'uploaded' ? 12 : 12} xs={12}>
                <Box
                  component="img"
                  alt="file preview"
                  src={displayPuzzle.fileType === 'uploaded' ? displayPuzzle.url : `${url}${displayPuzzle.url}`}
                  sx={{
                    top: 8,
                    borderRadius: 1,
                    // border:"2px solid #000",              
                    objectFit: 'contain',
                    paddingTop: '5px',
                    width: 'calc(100% - 16px)',
                    height: '320px'
                  }}
                />
              </Grid>
              : displayPuzzle.mediaType && (displayPuzzle.mediaType).split("/")[0] === "audio" ?
                <Grid item lg={12} md={12} xs={12}>
                  <div id="hint-need-audio">
                    <AudioPlayerAdmin src={displayPuzzle.fileType === 'uploaded' ? displayPuzzle.url : `${url}${displayPuzzle.url}`} />
                  </div>
                </Grid>
                : <></>}
        </Grid>
        : <></>}
    </>
  );
};

export default EditPuzzleDisplay;
