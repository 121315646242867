import {
   LOADING, LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE, CLEAN_ALL_DATA,
   CHAPTER_DATA_SUCCESS, GAME_DATA_SUCCESS, HINT_FUN, PENALTY_FUN, CHAPTER_FUN, CHAPTER_SOLUTION,
   TEAM_FUN, GAME_FINISH, SOLUTION_FUN
} from "./Types"
import { LOGOUT_LOADING, LOGOUT_SUCCESS, LOGOUT_FAILURE, CLEAN_LOGIN_DATA } from "./Types"
import { REFRESH_TOKEN_LOADING, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from './Types'
import { SET_USER, REMEMBER_USER } from './Types';
import { TEAM_DATA_SUCCESS } from './Types'
import { PLAY } from './Types';



export const loading = (data) => ({
   type: LOADING,
   data,
})


export const loginBegin = () => ({
   type: LOGIN_LOADING
})

export const loginSuccess = (logindata) => ({
   type: LOGIN_SUCCESS,
   logindata,
})

export const loginFailure = (loginerror) => ({
   type: LOGIN_FAILURE,
   loginerror

})

export const setUser = (data) => ({
   type: SET_USER,
   data,
})

export const rememberUser = (data) => ({
   type: REMEMBER_USER,
   data,
})


export const logoutBegin = () => ({
   type: LOGOUT_LOADING
})

export const logoutSuccess = (data) => ({
   type: LOGOUT_SUCCESS,
   data,
})

export const logoutFailure = (error) => ({
   type: LOGOUT_FAILURE,
   error
})


export const refreshTokenBegin = () => ({
   type: REFRESH_TOKEN_LOADING
})

export const refreshTokenSuccess = (data) => ({
   type: REFRESH_TOKEN_SUCCESS,
   data,
})

export const refreshTokenFailure = (error) => ({
   type: REFRESH_TOKEN_FAILURE,
   error

})


export const cleanLoginData = () => ({
   type: CLEAN_LOGIN_DATA,
})


export const cleanAllData = () => ({
   type: CLEAN_ALL_DATA,
})


// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++




export const played = () => ({
   type: PLAY,
})


export const teamDataSuccess = (data) => ({
   type: TEAM_DATA_SUCCESS,
   data,
})


export const penaltyFun = (data) => ({
   type: PENALTY_FUN,
   data,
})

export const chapterFun = (data) => ({
   type: CHAPTER_FUN,
   data,
})

export const chapterSolution = (data) => ({
   type: CHAPTER_SOLUTION,
   data,
})


export const gameDataSuccess = (data) => ({
   type: GAME_DATA_SUCCESS,
   data,
})

export const gameFinished = () => ({
   type: GAME_FINISH,
})


export const hintFun = (data) => ({
   type: HINT_FUN,
   data,
})


export const solutionFun = (data) => ({
   type: SOLUTION_FUN,
   data,
})


export const teamFun = (data) => ({
   type: TEAM_FUN,
   data,
})

export const chapterDataSuccess = (data) => ({
   type: CHAPTER_DATA_SUCCESS,
   data,
})