import React, { useEffect, useState } from 'react';
import {motion} from 'framer-motion'
import {Card, CardContent} from '@mui/material';
import { CardHeader } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { getPodName } from './Services/Service';

const NotFoundPage = () => {
  const {state} = useLocation();

  console.clear()
  console.log(state)

  const [labelData, setLabelData] = useState();

  const getPod = async () => {
    const res = await getPodName({ _id: state.pod_id, gameId: state.game_id });
    console.log("first", res?.data?.labelData);
    setLabelData(res?.data?.labelData);
  };

  useEffect(() => {
    getPod();
  }, []);
  return (
      <div style={{display:'flex', justifyContent:'center',alignItems:'center', minHeight:'100vh'}}>
        <Card style={{borderRadius:0, color:"#FFF", backgroundColor:"#1D1C1C", maxWidth:'25vw'}}>
          <CardContent style={{fontFamily: 'PFDinMonoMedium', textAlign:'center'}}>
            <h6 style={{color:"#FFFB00", fontFamily: 'PFDinMonoBold',fontSize:"24px", fontWeight:'normal', marginBottom:"20px"}}>{labelData?.miscellaneousheader ? labelData.miscellaneousheader : "SOMETHING ISN’T RIGHT"}</h6>
            {labelData?.miscellaneoustext ? labelData?.miscellaneoustext : "Please verify you are attempting to access this link at the correct date and time. Contact your event organizer for help!"}
          </CardContent>
        </Card>
      </div>
  );
};

export default NotFoundPage;
