import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { gameEdit, getOneGame } from '../Services/GameServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card } from 'react-bootstrap';
import { Grid, TextField, Box, CardHeader, CardContent, InputLabel } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';
import ChapterList from '../ChapterManagement/ChapterList';
import LabelEditPage from '../LabelManagement/Edit';
const EditGame = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { gameId } = useParams();
  console.log(gameId)



  const UploadGameSchema = Yup.object().shape({
    name: Yup.string().required('name is required').strict().trim("Name should not have trailing white spaces")
      .min(2).max(55),
    internalTitle: Yup.string().required('Internal Title is required').strict().trim("Internal Title should not have trailing white spaces"),
    // seconds: Yup.number().required("Seconds is required").min(0).max(59),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: gameId,
      name: "",
      internalTitle: "",

      // seconds:state.timeDuration.seconds
    },

    validationSchema: UploadGameSchema,
    onSubmit: async (values) => {
      try {
        console.log(values)
        let sendData = {
          _id: values.id,
          name: values.name.charAt(0).toUpperCase() + values.name.slice(1),
          internalTitle: values.internalTitle.charAt(0).toUpperCase() + values.internalTitle.slice(1),
        }
        const result = await gameEdit(sendData);
        if (result.status === 200) {
          toast.success(result.data.message);
          // navigate('/admin/gamelist');
        }

      } catch (error) {
        // isSubmitting(false);
        console.log(error)
        toast.error(error?.response.data.message);

      }
    }
  });


  const { errors, values, touched, setValues, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleTimeDuration = (e) => {
    let v = e.target.value;
    let n = e.target.name;


    if (Number(v) === NaN || Number(v) === null || Number(v) === undefined) {
      setFieldValue(e.target.name, 0)
    } else if (Number(v) < 0) {
      setFieldValue(e.target.name, 0)
    } else {
      setFieldValue(e.target.name, parseInt(e.target.value))
    }
  }

  const fetchGameDetail = async () => {
    try {
      const resp = await getOneGame({ _id: gameId })
      let data = resp.data.data.result;
      setValues({
        ...values,
        name: data.name,

        internalTitle: data.internalTitle,
      })

    } catch (error) {
      console.log(error)
      toast.error(error?.response.data.message);

    }
  }



  useEffect(() => {
    fetchGameDetail()
  }, [])

  return (
    <div>
      <Card mb={3}>
        <Card.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Card.Title as="h5">Edit Game</Card.Title>
            <Button type="submit" className="btn btn-primary" onClick={() => { navigate(`/admin/label/edit/${gameId}`) }}>
              EDIT LABEL
            </Button>
          </div>
        </Card.Header>
        <div className="p-4">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel>Name*</InputLabel>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    placeholder='Enter Name'
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps('name')} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <InputLabel>Minutes*</InputLabel>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    placeholder='Enter Minutes'
                    value={values.minutes}
                    name="minutes"
                    type="number"
                    error={Boolean(touched.minutes && errors.minutes)}
                    helperText={touched.minutes && errors.minutes}
                    onChange={(e) => { handleTimeDuration(e) }} />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <InputLabel>Internal Title*</InputLabel>
                  <TextField
                    fullWidth
                    variant="standard"
                    required
                    placeholder='Enter Internal Title'
                    value={values.internalTitle}
                    error={Boolean(touched.internalTitle && errors.internalTitle)}
                    helperText={touched.internalTitle && errors.internalTitle}
                    {...getFieldProps('internalTitle')} />
                </Grid>
                {/* <Grid item xs={12} md={6}>        
                    <InputLabel>Seconds*</InputLabel>    
                    <TextField 
                      fullWidth
                      required
                      variant="standard" 
                      placeholder='Enter Seconds'
                      value={values.seconds}
                      name="seconds"
                      type="number"
                      error={Boolean(touched.seconds && errors.seconds)}
                      helperText={touched.seconds && errors.seconds}
                      onChange={(e)=>{handleTimeDuration(e)}} />            
                </Grid>                                                                                                                       */}
              </Grid>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                <Button type="reset" onClick={() => { navigate("/admin/gamelist") }} className="btn btn-outline-secondary mr-3">
                  BACK
                </Button>
                <Button type="submit" className="btn btn-primary">
                  UPDATE
                </Button>
              </Box>
            </Form>
          </FormikProvider>
        </div>

      </Card>
      <br />
      {/* <LabelEditPage gameId={gameId} /> */}
      <br />
      <ChapterList gameId={gameId} />

    </div>
  );
};

export default EditGame;
