import React, { useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import './AddValidation.css';
const AddValidation = ({
  teamName,
  setTeamName,
  onGameSelect,
  options,
  onPodSelect,
  podoptions,
  podId,
  gameId,

  startDate,
  setStartDate,
  endDate,
  setEndDate,

  handleSubmit,
  error
}) => {
  const navigate = useNavigate();

  const handleTeamList = () => {
    navigate('/admin/teamlist');
  };

  const handleStartTimer = (event) => {
    const e = event.target.value;
    if (startDate >= '00:00' || startDate < endDate) {
      setStartDate(e);
      setEndDate(e);
    }

    console.log('Eeeeeeeeeeeeeeeee', e.split(':'));
  };
  const handleEndTimer = (event) => {
    const e = event.target.value;
    console.log(e, 'eeeee');
    console.log('Eeeeeeeeeeeeeeeee', e.split(':'));

    if (e > startDate) {
      setEndDate(e);
    }
  };
  return (
    <>
      <form>
        <div className="form-group">
          <label>Enter the Team name*</label>
          <input type="text" name="teamname" className="form-control" value={teamName} onChange={(e) => setTeamName(e.target.value)} />
          {error && teamName.length === 0 && <small className="text-danger form-text">team name required</small>}
        </div>

        <div className="form-group">
          <label>Select Game*</label>
          <Multiselect
            className="form-control"
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={(e) => onGameSelect(e)}
            options={options}
            showCheckbox
            singleSelect
          />
        </div>
        {error && gameId.length === 0 && <small className="text-danger form-text">game is required</small>}
        <br />
        <div className="form-group">
          <label>Select Pod</label>
          <Multiselect
            className="form-control"
            displayValue="key"
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={(e) => onPodSelect(e)}
            options={podoptions}
            showCheckbox
            singleSelect
          />
          {error && podId.length == 0 && <small className="text-danger form-text">pod is required</small>}
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="starttime">Start Time*</label>
          <input type="time" value={startDate} className="startTimer" onChange={(e) => handleStartTimer(e)} />
          {error && startDate.length == 0 && <small className="text-danger form-text">start time required</small>}
          <br />
          <label htmlFor="endtime">End Time*</label>
          <input type="time" value={endDate} className="endTimer" onChange={(e) => handleEndTimer(e)} />
          {error && endDate.length == 0 && <small className="text-danger form-text">end time required</small>}
        </div>
      </form>
      <button className="btn btn-primary mt-2" onClick={() => handleTeamList()}>
        Back
      </button>
      <button className="btn btn-success mt-2" onClick={(e) => handleSubmit(e)}>
        Add
      </button>
    </>
  );
};

export default AddValidation;
