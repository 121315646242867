import axios from 'axios';
import { baseUrl, podUrl } from '../Config/config';
import Interceptor from '../../../config/Interceptor';

export const podAdd = (name) => {
  return Interceptor().post(`${podUrl}/addPod`, name);
};
export const podAll = (pod) => {
  return Interceptor().post(`${podUrl}/getAllPods`, pod);
};
export const podAllName = (pod) => {
  return Interceptor().post(`${podUrl}/getAllpodNames`, pod);
};
export const editPod = (pod) => {
  return Interceptor().post(`${podUrl}/updatePod`, pod);
};



export const podDelete = (podId) => {
  return Interceptor().post(`${podUrl}/deletePod`, podId);
};

export const getOnePod = (podId) => {
  return Interceptor().post(`${podUrl}/getOnePodDetails`, podId);
};

export const getPodLeaderBoard = (data) => {
  return Interceptor().post(`${podUrl}/getadminLeaderBoard`, data);
};

export const getAdminPodReports = (data) => {
  return Interceptor().post(`${baseUrl}/reports/getadminpodreports`, data);
};

export const getPodReportExport = (data) => {
  return Interceptor().post(`${baseUrl}/reports/getPodReportExport`, data);
};