import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './ForgotPassword.css';
import validator from 'validator';
import Breadcrumb from '../../../layouts/AdminLayout/Breadcrumb';
import { forgetPassword } from '../../Admin/Services/Service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';
// import Stack from '@mui/material/Stack';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { TextField } from '@material-ui/core';
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (email.length != 0) {
        const result = await forgetPassword({ email });
        // window.reload();
        navigate('/auth/login');
        toast.success(result.data.message);
        setLoader(false);
        errorDisplay(false);
      } else {
        setError(true);
        setLoader(false);
      }
    } catch (err) {
      setError(true);
      setEmail('');
      setLoader(false);

      console.log('dataeerr', err.response);
      toast.error(err.response.data.message);
    }
  };
  const handleChange = (e) => {
    console.log('eee', e.target.value);
    let em = e.target.value;
    em = em.replace(' ', '');
    // em = email.trim();
    // console.log(em);
    setEmail(em);
    if (!validator.isEmail(em)) {
      setErrorDisplay(true);
      setEmailError('Enter valid email');
      console.log('failure');
    } else {
      console.log('success');
      // setEmailError('');
      setErrorDisplay(false);
    }
  };
  // console.log('cjeckemal', email);
  // const handleSpace = (e) => {
  //   console.log('trimp', e.target.value);
  //   let email = e.target.value;
  //   setEmail(email.trim().split(/ +/).join(' '));
  // };

  const handleLogin = () => {
    navigate('/auth/login');
  };
  return (
    <React.Fragment>
      {!loader ? (
        <>
          {' '}
          <Breadcrumb />
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <Card className="borderless">
                <Card.Body className="text-center">
                  <div className="mb-4">
                    <i className="feather icon-user-plus auth-icon" />
                  </div>
                  <h3 className="mb-4">Forgot Password </h3>

                  <div className="form-group mb-3">
                    <TextField
                      variant='outlined'
                      type="email"
                      fullWidth
                      placeholder="Email address"
                      name="email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                      onKeyUp={(e) => {
                        e.target.value.trim();
                      }}
                    // pattern="[^\s]+"
                    />
                  </div>

                  {!errorDisplay ? (
                    <> {error && email.length === 0 && <small className="text-danger form-text">Email is required</small>} </>
                  ) : (
                    <> {emailError && <small className="text-danger form-text">{emailError}</small>}</>
                  )}
                  <br />
                  <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>
                    Submit
                  </button>
                  <button className="btn btn-secondary" onClick={(e) => handleLogin(e)}>
                    Back to login
                  </button>
                </Card.Body>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <Box sx={{ display: 'flex' }}>
            <ReactLoading />
          </Box>
        </Backdrop>
      )}
    </React.Fragment>
  );
};

export default ForgetPassword;
