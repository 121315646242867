import React, { useState } from 'react';
import MaterialTable from 'material-table';

import { LeaderboardData } from './Database';
import { Card, Button, Modal } from 'react-bootstrap';
const Leaderboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <MaterialTable
            title="Leaderboard"
            columns={[
              { title: 'Team Name', field: 'teamName' },
              {
                title: 'Team Member',
                field: 'teamMember',
                render: (rowData) => {
                  return (
                    <>
                      <Button className="btn btn-outline-secondary btn-sm" onClick={() => setShow(true)}>
                        Team Members
                      </Button>
                    </>
                  );
                }
              },
              { title: 'Total Time', field: 'totalTime' },
              {
                title: 'Penalties',
                field: 'penalties'
              },
              {
                title: 'Overall Score',
                field: 'score'
              }
            ]}
            data={[
              { teamName: 'Developer', teamMember: 'Yash', totalTime: '56:32', penalties: 63, score: 90 },
              {
                teamName: 'Designer',
                teamMember: 'Yash',
                totalTime: '52:32',
                penalties: 23,
                score: 67
              }
            ]}
            options={{
              search: true
            }}
          />
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Player's List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>PLayer 1</p>
          <p>Player 2</p>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Leaderboard;
