import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { url } from '../Config/config';

import { Box, CardContent, CardHeader, Grid, InputLabel, TextField } from '@material-ui/core'
import { Form, FormikProvider, useFormik } from 'formik';
import { Card, Button } from 'react-bootstrap';

import { getContent, updateContent } from '../Services/ContentServices';
import { useNavigate } from 'react-router-dom';

export default function ContentManagement() {
    const navigate = useNavigate();
    const allowed_ext = ['mp4', 'wav', 'm4a', 'mpeg'];

    const ContentSchema = Yup.object().shape({
        description: Yup.string().required("Description is required").max(500, "Description maximum length reached(500 characters)"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: "",
            video: null,
            preview: "",
            url: null
        },

        validationSchema: ContentSchema,
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                console.log(values)

                const formData = new FormData();
                formData.append('_id', values.id);
                formData.append('description', values.description.trim());
                if (values.video !== null) {
                    formData.append('contentVideo', values.video);
                }

                const contentData = await updateContent(formData);

                if (contentData.status === 200) {
                    toast.success(contentData?.data.message);
                }

            } catch (error) {
                console.log(error)
                toast.error(error?.response.data.message);

            }
        }
    });


    const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;

    const handleDropFile = (e) => {
        let f = e.target.files[0];
        if (allowed_ext.includes(f.type.split("/")[1])) {
            setFieldValue('video', f);
            // setFieldValue('preview',f.name);
            setFieldValue('url', { preview: URL.createObjectURL(f) });
        } else {
            toast.error(`Only ${allowed_ext} are allowed`)
        }

    };

    const GetData = async () => {
        let resp = await getContent();
        const data = resp.data.data;
        setValues({
            ...values,
            id: data._id,
            description: data.description,
            url: url + data.video
        })
    }
    useEffect(() => {
        GetData()
    }, [])

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Content Management</Card.Title>
                </Card.Header>
                <div className="p-4">
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <InputLabel>Description*</InputLabel>
                                    <TextField
                                        fullWidth
                                        placeholder='Enter Description'
                                        variant="outlined"
                                        value={values.description}
                                        required
                                        multiline
                                        rows={3}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        {...getFieldProps('description')} />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InputLabel>Video*</InputLabel>
                                    <input type="file" name="video" value={values.preview} className="form-control" onChange={handleDropFile} />
                                    {Boolean(touched.video && errors.video) && <small className="text-danger form-text">{touched.video && errors.video}</small>}
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    {(values.url !== null) && typeof (values.url) === 'string' ?
                                        <Grid item lg={6} md={12} xs={12}>

                                            <video
                                                preload="auto"
                                                controls
                                                controlsList="nodownload"
                                                style={{
                                                    top: 8,
                                                    borderRadius: 1,
                                                    border: "2px solid #000",
                                                    objectFit: 'fit',
                                                    padding: '5px',
                                                    width: 'calc(100% - 16px)',
                                                    height: 'calc(100% - 16px)'
                                                }}
                                            >
                                                <source src={`${values.url}`}></source>
                                            </video>
                                        </Grid> : <></>}
                                    {(values.url !== null) && values.url.preview ?
                                        <Grid item lg={6} md={12} xs={12}>

                                            <video
                                                preload="auto"
                                                controls
                                                controlsList="nodownload"
                                                style={{
                                                    top: 8,
                                                    borderRadius: 1,
                                                    border: "2px solid #000",
                                                    objectFit: 'fit',
                                                    padding: '5px',
                                                    width: 'calc(100% - 16px)',
                                                    height: 'calc(100% - 16px)'
                                                }}
                                            >
                                                <source src={`${values.url.preview}`}></source>
                                            </video>
                                        </Grid> : <></>}
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                                <Button type="reset" onClick={() => { navigate("/admin/pod") }} className="btn btn-outline-secondary mr-3">
                                    BACK
                                </Button>
                                <Button type="submit" className="btn btn-primary">
                                    UPDATE
                                </Button>
                            </Box>
                        </Form>
                    </FormikProvider>
                </div>
            </Card>
        </>
    );
};

