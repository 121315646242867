import React from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { Box } from '@material-ui/core';

const VedioModel = ({ show, data, onClose }) => {

    return (
        <>
            <Modal size="lg" centered show={show} backdrop="static">
                <ModalBody>
                    <video
                        preload="auto"
                        controls
                        controlsList="nodownload"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: 'auto'
                        }}
                    >
                        <source src={data}></source>
                    </video>
                    <Button type="submit" onClick={() => { onClose() }} style={{ margin: '1px', marginTop: '9px' }} className="btn btn-outline-secondary bt-sm float-right">
                        Back
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default VedioModel;