import { useRef, useEffect, useState } from 'react';

function useAnimatedState(initialState) {
    const frame = useRef();
    const [state, setState] = useState(initialState);

    const setAnimatedState = newState => {
        cancelAnimationFrame(frame.current);

        frame.current = requestAnimationFrame(() => {
            setState(newState);
        });
    };

    useEffect(() => {
        return () => {
            cancelAnimationFrame(frame.current);
        };
    }, []);

    return [state, setAnimatedState];
}

export default useAnimatedState;
