import React from 'react'
import { styled } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import lockIcon from '../../../assets/images/lockImg.svg';
import { useSelector } from 'react-redux';

const RootStyle = styled('div')(() => ({
    backgroundColor: "#1D1C1C",
    color: "#FFF",
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'PFDinMonoMedium'
}))


export default function Result({ data, onContinue, correctSolution }) {
    const labelData = useSelector(state => state.game.data && state.game.data.labelData &&
        Object.keys(state.game.data.labelData).length ? state.game.data.labelData : {});
    let sol = correctSolution[0];
    return (
        <RootStyle>
            <div className="congratulation  common-modal">
                <div className="modal-body">
                    <div className="modal-body-head">
                        <div className="icon-shape pb-3">
                            <b ><span><img src={lockIcon} /> </span></b>
                        </div>
                        <h4 className="modal-title text-center">{labelData?.chapterCompleteLabel}</h4>
                        <h5 className="sub-content text-center">{labelData?.chapterCompleteDesc}</h5>
                        <h5 className="yallow-content yallow-color year-text" style={{ textAlign: "center" }}>{data?.result === "final answer" ? sol : data?.result}</h5>
                    </div>
                    <div>
                        <button onClick={() => { onContinue() }} className="styledBtn px-btn">
                            <span>
                                {labelData?.continueButtonOnPopup}
                            </span>
                            {/* <span>
                                    <svg id="arrow.e0e8f1ef" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_96" data-name="Path 96" d="M0,0H24V24H0Z" fill="none"/>
                                        <path id="Path_97" data-name="Path 97" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
                                    </svg>
                                </span> */}
                        </button>
                    </div>
                </div>
            </div>

        </RootStyle>
    )
}
