import React, { useState, useRef } from 'react'

// function RenderTime({ time }) {
//     let minutes = parseInt(time / 60)
//     let seconds = parseInt(time % 60)

//     if (minutes < 10) {
//         minutes = '0' + minutes
//     }
//     if (seconds < 10) {
//         seconds = '0' + seconds
//     }

//     return (`${minutes}:${seconds}`)
// }


function RenderTime({ time = 0 }) {
    const minutes = Math.floor(time / 60).toFixed(0).padStart(2, '0');
    const seconds = Math.floor(time % 60).toFixed(0).padStart(2, '0');
    return `${minutes}:${seconds}`;
}




export default function AudioPlayer({ src, setDisable }) {
    const player = useRef();
    const timeline = useRef();
    const playButton = useRef();
    const [play, setPlay] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [seek, setSeek] = useState(0);

    function toggleAudio() {
        if (play) {
            player
                .current
                .pause();
        } else {
            player
                .current
                .play();
        }
        setPlay(!play);

        player.current.onloadedmetadata = () => {
            setDuration(player.current.duration);
        }
        setDuration(player.current.duration);
    }

    function changeTimelinePosition() {
        const percentagePosition = (100 * player.current.currentTime) / player.current.duration;
        timeline.current.style.backgroundSize = `${percentagePosition}% 100%`;
        setSeek(percentagePosition);
        // console.log(percentagePosition)
        timeline.current.value = percentagePosition;
        setCurrentTime(player.current.currentTime)
    }

    function audioEnded() {
        setPlay(false);
    }

    function changeSeek(e) {
        console.log(e.target.value)
        const time = ((e.target.value) * player.current.duration) / 100;
        player.current.currentTime = time;
        setSeek(e.target.value);
        setDuration(player.current.duration);
    }

    function loadMetaData(event) {
        setDuration(event.target.duration)
        // toggleAudio()
    }
    const isTouchDevice = () => {
        return (
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        );
    };

    return (
        <div className="audio-player mb-4" onClick={(e) => e.stopPropagation()}>
            <audio
                ref={player}
                onTimeUpdate={changeTimelinePosition}
                onEnded={audioEnded}
                onLoadedMetadata={loadMetaData}
                src={src}></audio>
            <div className="controls"

            >
                <button ref={playButton} className="player-button" onClick={(e) => {
                    // stop click event
                    e.stopPropagation();
                    e.preventDefault();
                    toggleAudio()
                }} onTouchEnd={(e) => {
                    // stop touch event
                    e.stopPropagation();
                    e.preventDefault();
                    toggleAudio()
                }}>
                    {play
                        ? <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 50 50">
                            <g id="Group_12" data-name="Group 12" transform="translate(-932 -474)">
                                <g
                                    id="Ellipse_1"
                                    data-name="Ellipse 1"
                                    transform="translate(932 474)"
                                    stroke="#fffb00"
                                    stroke-width="2">
                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                    <circle cx="25" cy="25" r="24" fill="none" />
                                </g>
                                <g id="Group_176" data-name="Group 176" transform="translate(945 487)">
                                    <path id="Path_222" data-name="Path 222" d="M0,0H24V24H0Z" fill="none" />
                                    <path
                                        id="Path_223"
                                        data-name="Path 223"
                                        d="M6,5H8V19H6ZM16,5h2V19H16Z"
                                        fill="#fffb00" />
                                </g>
                            </g>
                        </svg>
                        : <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 50 50">
                            <g id="play" transform="translate(-932 -474)">
                                <g
                                    id="Ellipse_1"
                                    data-name="Ellipse 1"
                                    transform="translate(932 474)"
                                    stroke="#fffb00"
                                    stroke-width="2">
                                    <circle cx="25" cy="25" r="25" stroke="none" />
                                    <circle cx="25" cy="25" r="24" fill="none" />
                                </g>
                                <g id="Group_11" data-name="Group 11" transform="translate(945 487)">
                                    <path id="Path_98" data-name="Path 98" d="M0,0H24V24H0Z" fill="none" />
                                    <path
                                        id="Path_99"
                                        data-name="Path 99"
                                        d="M19.376,12.416l-10.6,7.066A.5.5,0,0,1,8,19.066V4.934a.5.5,0,0,1,.777-.416l10.6,7.066a.5.5,0,0,1,0,.832Z"
                                        fill="#fffb00" />
                                </g>
                            </g>
                        </svg>}
                </button>
                <input
                    type="range"
                    ref={timeline}
                    className="timeline"
                    max="100"
                    value={seek}
                    onChange={(e) => {
                        changeSeek(e)
                    }}
                    title="Music"
                />
                <span>
                    {/* {currentTime ? <RenderTime time={currentTime} /> : "00:00"} / {duration ? <RenderTime time={duration} /> : "00:00"} */}
                    <RenderTime time={currentTime} />/{duration ? <RenderTime time={duration} /> : "00:00"}
                </span>

            </div>
        </div>
    )
}
