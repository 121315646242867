import React from 'react';
import { chapterDelete } from '../Services/ChapterServices';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DeleteChapterConfirm = ({ show, deleteId, setShow, refreshList }) => {
  const handleClose = () => setShow(false);
  //delete the chapter
  const handleChapterDelete = async () => {
    setShow(false);
    try {
      const deleteData = await chapterDelete({ _id: deleteId });
      console.log(deleteData.data.message)
      // tableRef.current.onQueryChange();
      refreshList();
      toast.success(deleteData.data.message);
    } catch (err) {
      toast.error(err.response.message);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline-secondary btn-sm btn btn-primary" onClick={handleClose}>
            No
          </Button>
          <Button className="btn btn-primary btn-sm" onClick={handleChapterDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteChapterConfirm;
