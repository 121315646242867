
import { baseUrl } from '../Config/config';
import Interceptor from '../../../config/Interceptor';

export const LabelAdd = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/addLabel`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const LabelGetList = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/getAllLabel`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


export const LabelGetDetail = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/getOneLabelDetails`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


export const LabelEdit = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/updateLabel`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};



export const GetPodNames = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/getPodNames`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const GetLabelDelete = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${baseUrl}/labels/deleteLabel`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};