import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LabelGetDetail } from '../Services/LableServices';


const LabelViewPage = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const { id } = useParams();
  const getData = async () => {
    const resp = await LabelGetDetail({ _id: id });
    setDetails({ ...resp.data.data })

  };


  const handleBack = () => {
    navigate('/admin/label/list');
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <div>
      <Card>
        <Card.Header>
          <Row>
            <Col md={11}>
              {' '}
              <Card.Title as="h5">Label Detail</Card.Title>{' '}
            </Col>
            <Col md={1}>
              <Button className="btn btn-outline-dark btn-sm mt-2" onClick={() => handleBack()}>
                BACK
              </Button>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {/* <Row>
            <Col>
              <h5>Pod Name</h5>
            </Col>
            <Col>
              <h6 className="text-muted ">{details?.podId?.name}</h6>
            </Col>
          </Row> */}
          <Row className="mt-3">
            <Col>
              <h5>Chapter Label</h5>
            </Col>
            <Col>
              <h6 className="text-muted ">{details?.chapterNameLabel}</h6>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <h5> Text Code Label</h5>
            </Col>
            <Col>
              {' '}
              <h6 className="text-muted ">{details?.enterTextCodeLabel}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              {' '}
              <h5>Hints Label</h5>
            </Col>
            <Col>
              {' '}
              <h6 className="text-muted ">{details?.hintsLabel}</h6>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              {' '}
              <h5>Penalties Label</h5>
            </Col>
            <Col>
              {' '}
              <h6 className="text-muted ">{details?.penaltiesLabel}</h6>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    </div>
  );
};

export default LabelViewPage;