import React, { useContext, useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Base_URL } from "../Config/Config";
import { styled, makeStyles } from "@material-ui/styles";
import { Button, FormControl, FormHelperText } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import Countdown from "react-countdown";
import Clues from "./Clues";
import Hints from "./Hints";
import Result from "./chapterResult";
import { SocketContext } from "../../../contexts/socketConnection";
import { useDispatch, useSelector } from "react-redux";
import {
  chapterDataSuccess,
  chapterFun,
  gameDataSuccess,
  gameFinished,
  hintFun,
  penaltyFun,
  solutionFun,
  teamFun,
} from "../../../Actions/ActionCreators";
import { Modal } from "react-bootstrap";
import KonvaDrawing from "./Konva";
import GameFinishedPage from "./GameFinished";
import lockIcon from "../../../assets/images/chapterLock.svg";
import arrowIcon from "../../../assets/images/arrow.svg";
import AudioPlayer from "../../../components/AudioPlayer";
import logo from "../../../assets/images/user/logo.png";
import gameOverImg from "../../../assets/images/game-over-img.png";
import { Dialog, Typography } from "@mui/material";
import useDebounce from "../../../hooks/useDebounce";

const InputStyle = styled("input")(() => ({
  width: "100%",
  backgroundColor: "inherit",
  margin: "0 3% 0 0",
  color: "#FFF",
  border: "none",
  borderBottom: "1px #FFF solid",
  letterSpacing: "0.26px",
  fontFamily: "PFDinMonoRegular",
}));

const useStyles = makeStyles({
  input: {
    color: "white",
  },
});

const HintButton = styled(Button)(() => ({
  border: "1px solid #FFF",
  color: "#FFF",
  padding: "4px 15px",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "uppercase",
  fontFamily: "PFDinMonoMedium",
}));


// const scrollTop = () => {
//   window.scrollTo(0, document.body.scrollHeight);
// }



const renderer = ({ hours, minutes, seconds, completed }) => {

  if (completed) {
    return (
      <b
        style={{ color: `${hours === 0 && minutes < 10 ? "red" : "white"}` }}
      >
        {hours === 0 ? "" : `${hours}:`}
        {minutes < 10 ? "0" + minutes : minutes}:
        {seconds < 10 ? "0" + seconds : seconds}
      </b>
    );
  } else {
    return (
      <b
        style={{ color: `${hours === 0 && minutes < 10 ? "red" : "white"}` }}
      >
        {hours === 0 ? "" : `${hours}:`}
        {minutes < 10 ? "0" + minutes : minutes}:
        {seconds < 10 ? "0" + seconds : seconds}
      </b>
    );
  }
};



const UserGame = () => {
  const clockRef = useRef()
  const [key, setKey] = useState(false);

  const navigate = useNavigate();
  const classes = useStyles();

  // Times
  let current_time = Date.now();
  let [date, setDate] = useState()
  const [now, setNow] = useState(0);
  const [contData, setContData] = useState()
  const [totalPodTime, setTotalPodTime] = useState()
  const [endTime, setEndTime] = useState();


  const [shrink, setShrink] = useState(false); //for layout
  const [playing, setPlaying] = useState(false);

  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  let { token, gameId, podId } = useParams();
  let chapter_count = 0;



  const [tempTime, setTempTime] = useState(1000);
  const [solution, setSolution] = useState("");
  const [solErr, setSolErr] = useState({
    error: false,
    text: "",
  });
  const [success, setSuccess] = useState({
    show: false,
    result: "",
    nextChapter: "",
  });
  const [leaderStatus, setLeaderStatus] = useState()
  const [solved, setSolved] = useState([]);
  const [solutionTaken, setSolutionTaken] = useState([]);
  const [hintTaken, setHintTaken] = useState([]);
  const [penalty, setPenalty] = useState(0);
  const [mount, setMount] = useState(true);
  const [videoEnd, setVideoEnd] = useState(false)


  /**
   * !REDUX DATA 
  */
  // const gameFinishedStatus = useSelector((state) => state.game.finished);
  // const teamDetail = useSelector((state) => state.team.data);
  // const game = useSelector((state) =>
  //   state.game.data && Object.keys(state.game.data) ? state.game.data : {}
  // );
  // const labelData = useSelector((state) =>
  //   state.game.data &&
  //     state.game.data.labelData &&
  //     Object.keys(state.game.data.labelData).length
  //     ? state.game.data.labelData
  //     : {}
  // );
  // const chapter = useSelector((state) =>
  //   state.game && state.game.data && state.game.data.chapterData
  //     ? state.game.data.chapterData
  //     : []
  // );
  // const chapterData = useSelector((state) =>
  //   state.chapter.data && Object.keys(state.chapter.data).length
  //     ? state.chapter.data
  //     : {}
  // );
  // const chapterSol = useSelector((state) =>
  //   state.chapter.data && Object.keys(state.chapter.data).length
  //     ? state.chapter.data.solution
  //     : {}
  // );


  const gameFinishedStatus = useSelector((state) => state.game.finished);

  const teamDetail = useSelector((state) => state.team.data);

  const game = useSelector((state) => state.game.data ?? {});

  const labelData = useSelector((state) => state.game.data?.labelData ?? {});

  const chapter = useSelector((state) => state.game.data?.chapterData ?? []);

  const chapterData = useSelector((state) => state.chapter.data ?? {});

  const chapterSol = useSelector((state) => state.chapter.data?.solution ?? {});



  const [show, setShow] = useState(false);
  const [chaptersData, setChaptersData] = useState({ chapterData: [] })

  const [isLastChapter, setIsLastChapter] = useState(false)
  const [lastChapter, setLastChapter] = useState(false);
  const chaptersDataRef = useRef();

  useEffect(() => {
    chaptersDataRef.current = chaptersData;
  }, [chaptersData]);

  const isLastChapterVideoFxn = (id, mediaType,) => {
    console.log("Running... 3", chapter, chaptersData.chapterData, chaptersDataRef.current)
    if (chaptersDataRef.current) {
      let isLastIndex = chaptersDataRef.current.chapterData.findIndex(chapter => {
        console.log(chapter._id, id)
        return chapter._id === id
      })
      console.log("Main Work ====>🤨🤨🤨", mediaType, "isLastChapter data isLastIndex=>", isLastIndex, "id =>", id, "chapter", chapter, chaptersData.chapterData)

      if (chaptersDataRef.current.chapterData.length && isLastIndex === chaptersDataRef.current.chapterData.length - 1 && mediaType == "video/mp4") {
        setIsLastChapter(true)
        console.log("Hurrrrraaaah! et isLastChapter", isLastChapter)
        if (clockRef.current) clockRef.current.stop();
      }
    }
  }






  useEffect(() => {
    // window.scrollTo(0, 0);
    /* Connect to socket and join the room */
    socket.on("connect", () => {
      //!-----------------------[EXTRA CHECK IF URL !EQUAL TO LOCALSTORAGE] -------------------//
      if (!teamDetail.teamDetails || teamDetail.teamDetails.token !== token) {
        navigate(`/user/${token}/${gameId}/${podId}`, {
          replace: true,
        });
        console.log("🥲 THIS HAPPEND BECAUSE THE LINK IS NOT SAME AS THE LOCALSTORAGE");
        window.location.reload()
      } else {
        socket.emit("joinRoom", {
          teamMemberName: teamDetail.currentUser,
          roomName: teamDetail.teamDetails.teamName,
          teamId: teamDetail.teamDetails._id,
          podId: podId
        });
      }
    }, [socket]);



    /* Get Timer Data */
    socket.on("getTimer", (data) => {
      updateGameRunningState(data)
    });


    socket.emit("getGameDetails", {
      gameId: gameId,
      roomName: teamDetail.teamDetails?.teamName,
      teamId: teamDetail.teamDetails._id,
      podId: podId,
    });



    const updateGameRunningState = (data) => {
      setPenalty(data.totalPenalties)
      setLeaderStatus(data.leaderboardstatus)

      console.log("🥵 precious data", data);
      if (endTime !== null && endTime !== undefined) {
        if (data.timeLeft == 0) {
          setShow(true);
        }
      }
      if (data && Object.keys(data).length) {
        setTempTime(data.timeup);
        console.log("setDate", data.timeLeft, 275)
        // setDate(data.timeLeft)
        setSolved(data.chapterSolved ? data.chapterSolved : []);
        setSolutionTaken(data.solutionTaken ? data.solutionTaken : []);
        setHintTaken(data.hintsTaken ? data.hintsTaken : []);
        dispatch(penaltyFun(data.totalPenalties));
      }
      if (data?.currentChapterId) {
        dispatch(chapterFun({ currentChapter: data.currentChapterId }));
      }
    }



    if (mount) {
      // document.body.classList.add("loading-indicator");
      socket.emit("getDrawing", {
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id
      })
      setMount(false);
    }



    socket.on("getCurrentTime", (data) => {
      console.log("server time", data)
      if (data.timeLeft <= 0) {
        dispatch(gameFinished());
      }
      console.log("setDate", Number(data.endTime) - data?.currentTime, data.endTime, 305)
      // setDate(Number(data.endTime) - data?.currentTime)
    })

    socket.on("getGameDetails", (data) => {
      console.log("Running... 1", data.chapterData.length, data.chapterData)
      //!-------------------------[ ALL GAME INFO in this dispatch ]-------------------------//
      console.log("DATA SET OF getGameDetails PROPERLY", {
        ...data.gameData,
        chapterData: data.chapterData,
        labelData: data.labelData,
      })
      setChaptersData({
        ...data.gameData,
        chapterData: data.chapterData,
        labelData: data.labelData,
      })

      dispatch(
        gameDataSuccess({
          ...data.gameData,
          chapterData: data.chapterData,
          labelData: data.labelData,
        })
      );

      if (data.timeLeft == 0) {
        setShow(true)
      }

      if (Array.isArray(data.chapterData) && data.chapterData.length) {
        let param = { _id: data.chapterData[0]._id };
        if (
          teamDetail.chapterDetail &&
          teamDetail.chapterDetail.currentChapter
        ) {
          param = {
            _id: teamDetail.chapterDetail.currentChapter,
            roomName: teamDetail.teamDetails.teamName,
            teamId: teamDetail.teamDetails._id,
          };
        }

        // console.log("getGameDetails info", teamDetail)
        // console.log("getGameDetails info", teamDetail)

        // if (mount) {

        setTempTime(data.timeLeft);
        // setDate(data.timeLeft)
        setEndTime(Number(data.endTime))
        setTotalPodTime(data.time)
        if (data?.teamData && data?.chapterData) {
          let solvedChapters = data?.teamData?.chapterSolved
          let lastChapterIndex = data.chapterData.findIndex(chapter => chapter._id === solvedChapters[solvedChapters.length - 1])

          if (lastChapterIndex === data.chapterData.length - 1) {
            // setLastChapter(true)
            //!=====================IF LAST CHAPTER IS VIDEO CHECK=================================!//
            setIsLastChapter(true)
          }

          if (lastChapterIndex !== data.chapterData.length - 1) {
            param = { _id: data.chapterData[lastChapterIndex + 1]._id };

            setPenalty(data.teamData.totalPenalties)
            setSolutionTaken(data.teamData.solutionTaken ? data.teamData.solutionTaken : []);
            setHintTaken(data.teamData.hintsTaken ? data.teamData.hintsTaken : []);
            dispatch(penaltyFun(data.teamData.totalPenalties));
            setSolved(data.teamData.chapterSolved ? data.teamData.chapterSolved : []);
            // setDate(Number(data.endTime) - data?.currentTime)
            console.log("setDate", data.timeLeft, 376)
            setDate(data?.timeLeft)
          }
        }

        socket.emit("getOneChapterDetails", {
          ...param,
          gameId: gameId,
          roomName: teamDetail.teamDetails.teamName,
          teamId: teamDetail.teamDetails._id,
          podId: podId,
        });
      }
      // }
      document.body.classList.remove("loading-indicator");
    });


    socket.on("getOneChapterDetails", (data) => {
      console.log("Running... 2", data?.result?._id, data.result.puzzle.mediaType)
      //!ALL DATA OF CHAPTER OF CURRENT DISPATCH 
      isLastChapterVideoFxn(data?.result?._id, data.result.puzzle.mediaType)
      dispatch(chapterDataSuccess(data.result));
      document.body.classList.remove("loading-indicator");
    });

    socket.on("hintsTaken", (data) => {
      let takenHints = teamDetail.hintsTaken ? teamDetail.hintsTaken : [];
      dispatch(penaltyFun(data.totalPenalties));
      setPenalty(data.totalPenalties ? data.totalPenalties : 0);
      if (data?.teamData) {
        dispatch(hintFun(data.teamData?.hintsTaken));
      } else {
        dispatch(hintFun([...takenHints, data.hintId]));
      }
      console.log(data, teamDetail.hintsTaken, takenHints, "hintsTaken");
    });

    socket.on("codeError", (data) => {
      console.log(data)
      setSolErr({
        error: true,
        text: data.message,
      });
    });

    socket.on("codeSuccess", (data) => {
      // setContData(data.nextChapterId)
      socket.emit("cleanDrawAll", {
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
      });
      // console.clear();
      let sol = data.correctcode.solution;
      setSuccess({
        show: true,
        result: sol[0],
      });
      setSolErr({
        ...solErr,
        error: false,
        text: "",
      });

      socket.emit("getOneChapterDetails", {
        _id: data.nextChapterId,
        gameId: gameId,
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        podId: podId,
      })
      console.log("------------ NEXT CHAPTERID ON codeSuccess", { chapterId: data.nextChapterId })
      dispatch(chapterFun({ currentChapter: data.nextChapterId }));
      socket.emit("getGameDetails", {
        gameId: gameId,
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        podId: podId,
      });

      //!IMPORTANT
      setVideoEnd(false)
      handleCloseHints()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

    socket.on("continueSuccess", (data) => {
      socket.emit("getOneChapterDetails", {
        _id: data.nextChapterId, gameId: gameId,
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        podId: podId,
      })
      //!IMPORTANT
      setVideoEnd(false)
      setShrink(false)
      handleCloseHints()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

    socket.on("gameFinished", (data) => {
      setSuccess({
        show: true,
        result: "final answer", //data?.correctcode
      });
      // setDate(0)
      setLeaderStatus(data.leaderboardstatus)

      if (data.message) {
        setLastChapter(true);
        dispatch(gameFinished());
      }
    });

    socket.on("gameFinishedContinue", (data) => {
      dispatch(gameFinished());
    });
  }, [socket]);



  //! This is for change time on focus no need to change
  useEffect(() => {
    const handleFocus = function () {
      // const currTime = Date.now();
      // const newDate = endTime <= currTime || endTime <= 999 ? 0 : date;

      // if (newDate !== date) {
      //   setDate(newDate);
      //   setKey(k => !k);
      // }
      console.log("This is trigger handle focus")

      socket.emit("getCurrentTime", {
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        gameId: gameId,
        podId: podId
      })
    };

    window.addEventListener("focus", handleFocus);

    // Cleanup: Remove the event listener on component unmount
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [endTime, date]);  // Added `endTime` and `date` as dependencies



  let [read, setRead] = useState(true)

  const handlerTimer = (timeLeft) => {
    socket.emit("getTimer", {
      timeLeft: timeLeft,
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
      gameId: gameId,
      podId: podId
    });

    // socket.emit("getDrawing", {
    //   roomName:teamDetail.teamDetails.teamName, 
    //   teamId:teamDetail.teamDetails._id 
    // })



    if (teamDetail.chapterDetail && teamDetail.chapterDetail.currentChapter) {
      // console.log("66666666666666666666666666666666666666");

      // socket.emit("getOneChapterDetails", {
      //   _id: teamDetail.chapterDetail.currentChapter,
      //   roomName: teamDetail.teamDetails.teamName,
      //   teamId: teamDetail.teamDetails._id,
      // });
    }
  }

  /**
   * -------------------------------------------------------------
   * ! THIS FUNCTION RUN ON LOOP 
   * -------------------------------------------------------------
   */
  const handleTicking = (time) => {
    if (date > now) {
      setDate((date - 1000));
    }
    const timeLeft = date;

    if (timeLeft < 5000 || read) {
      setRead(false)
      // handlerTimer(timeLeft)
    }
  };


  /*================================= Hints =====================================*/
  const [showHint, setShowHint] = useState(false);
  const handleCloseHints = () => {
    setShowHint(false);
  };

  const handleOpenHints = () => {
    setShowHint(true);
  };

  /*================================= Hints =====================================*/








  /*================================= Solution =====================================*/

  const handleChangeSolution = (e) => {
    setSolution(e.target.value);
    setSolErr({
      ...solErr,
      error: false,
      text: "",
    });
  };



  const handleSubmitSolution = () => {
    if (solution !== "" && solution !== null && solution !== undefined) {
      const timeLeft = date;

      socket.emit("getTimer", {
        timeLeft: timeLeft,
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        gameId: gameId,
        podId: podId
      });

      socket.emit("codeEntered", {
        roomName: teamDetail.teamDetails.teamName,
        teamId: teamDetail.teamDetails._id,
        chapterId: chapterData._id,
        code: solution.trim(),
        podId: podId
      });
      // setSolution("");

    } else {
      setSolErr({
        ...solErr,
        error: true,
        text: "Please Enter Code",
      });
    }
  };

  let debounceClick = useDebounce(handleSubmitSolution, 500)

  const handleHeavyClick = (e) => {
    e.preventDefault();
    console.log("handle handle")
    debounceClick()
  }

  const handleContinueVideo = () => {
    setVideoEnd(false)
    socket.emit("continue", {
      roomName: teamDetail.teamDetails.teamName,
      teamId: teamDetail.teamDetails._id,
      podId: podId,
      chapterId: chapterData._id,
    });
  };

  /*================================= Solution =====================================*/

  const handleContinueChapter = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    }, 200)
    setSuccess({
      ...success,
      show: false,
    });
    // dispatch(chapterFun({ currentChapter: contData }));
    setSolution("");


    if (lastChapter === true) {
      dispatch(gameFinished());
    }
  };

  const handleQuit = () => {
    dispatch(gameFinished());
  }





  return (
    <>
      {gameFinishedStatus === true ? (
        <GameFinishedPage endTime={endTime} handleTicking={handleTicking} show={show} totalPodTime={totalPodTime} leaderStatus={leaderStatus} />
      ) : (show === true) ? (
        <GameFinishedPage timeOver="time's up" leaderStatus={leaderStatus} />
      ) : (
        <>
          <section className="game-dash bg-black">
            <div className="side-bar-L">
              <div className="sidebar-head">
                {(date !== null && date !== undefined) && (endTime !== null && endTime !== undefined) && (
                  <>
                    <Countdown
                      ref={clockRef}
                      key={key}
                      date={date}
                      intervalDelay={1000}
                      now={() => 0}
                      autostart
                      precision={3}
                      renderer={renderer}
                      onTick={() => {
                        handleTicking(endTime);
                      }}
                      onComplete={() => { setShow(true) }}
                    />
                  </>
                )}
                <small>{labelData?.timeRemainig}</small>
                <span>
                  {(labelData && labelData.penaltiesLabel ? _.toUpper(labelData.penaltiesLabel) + ":" : "PENALTIES:")}{" "}
                  {penalty ? penalty : teamDetail.totalPenalty}{" "}
                  {labelData.mins}
                </span>
              </div>
              <div className="sidebar-body">
                <div className="need-hint-btn-bg">
                  <button
                    className="styledBtn hint-btn"
                    onClick={() => {
                      handleOpenHints();
                    }}
                  >
                    {labelData.hintsLabel}
                  </button>
                </div>
                <div className="sidebar-link-list-main">
                  <ul className="sidebar-link-list">
                    {chapter.map((data, idx) => {
                      if (data?.puzzle && data?.puzzle?.mediaType && data?.puzzle?.mediaType.split("/")[0] === "video") {
                        return null
                      } else {
                        chapter_count += 1;
                        return <li
                          key={data._id}
                          className={data._id === chapterData._id ? "active" : ""}
                        >
                          <a style={{ justifyContent: "space-between" }}>
                            {`${labelData && labelData.chapterNameLabel
                              ? labelData.chapterNameLabel.length < 8
                                ? labelData.chapterNameLabel
                                : `${labelData.chapterNameLabel}`
                              : "Chapter"
                              } ${chapter_count}`}
                            {!solved.includes(data._id) &&
                              data._id !== chapterData._id ? (
                              <span>
                                <img src={lockIcon} />
                              </span>
                            ) : (
                              <></>
                            )}
                          </a>
                        </li>
                        // return null
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className={shrink ? "game-side-R shrink-body" : "game-side-R"}>
              <div id="scrollView">
                <a href="#scrollView"></a>
              </div>
              {/* <div className={"game-side-R"}> */}
              <div className="game-side-body">
                {/* <!--first-chapter-screen--> */}
                <div className="game-side-chepter-1">
                  <div className="game-side-head text-center">
                    <div className="width-fix">
                      <h3 style={{ fontSize: "32px" }}>{game.name}</h3>
                      {/* <p style={{fontSize:"200px" }}></p> */}
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>
                  </div>
                  <div className="Chapter-similer-head text-left">
                    <p className="title">{labelData && chapterData.name}</p>
                    <p>{chapterData.description}</p>
                  </div>

                  {/* <div className="col-md-4 text-center" style={{left: "75%"}}>
              <button className="styledBtn mb-4" onClick={handleQuit}>
                QUIT
              </button>
            </div> */}
                  <div className="scroll-canvas">
                    <div className="width-fix-body canvas-body">

                      {/* <!--Game-screen-4--> */}
                      {/* <Board puzzleData={{...chapterData.puzzle}}/> */}
                      {/* {<Typography>{ chapterData.puzzle && chapterData.puzzle.text}</Typography>} */}
                      {(chapterData.puzzle &&
                        chapterData.puzzle.mediaType &&
                        chapterData.puzzle.mediaType.split("/")[0] === "image") ||
                        (chapterData.puzzle && chapterData.puzzle.text) ? (
                        <KonvaDrawing
                          puzzleData={
                            chapterData.puzzle && chapterData.puzzle.url
                              ? chapterData.puzzle.url
                              : ""
                          }
                          puzzleText={
                            chapterData.puzzle && chapterData.puzzle.text
                              ? chapterData.puzzle.text
                              : ""
                          }
                        />
                      ) : chapterData.puzzle &&
                        chapterData.puzzle.mediaType &&
                        chapterData.puzzle.mediaType.split("/")[0] === "video" ? (
                        <div className="video-box-screen-1">
                          {/* <video style={{width:'100%',maxHeight:'100%'}} width="1065" height="484" autoPlay muted loop controls preload="auto">
                      <source src={`${Base_URL}/${chapterData.puzzle.url}`} type="video/mp4" />
                    </video> */}
                          {playing === false && (
                            <ReactPlayer
                              height="auto"
                              width={
                                window.innerWidth - window.innerWidth * 0.3752
                              }                           // config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                              onContextMenu={e => e.preventDefault()}
                              config={{ file: { attributes: { controlsList: 'noplaybackrate nodownload', disablePictureInPicture: 1 } } }}
                              controls
                              playing={true}
                              url={`${Base_URL}/${chapterData.puzzle.url}`}
                              onEnded={() => setVideoEnd(true)}
                            />
                          )}
                        </div>
                      ) : chapterData.puzzle &&
                        chapterData.puzzle.mediaType &&
                        chapterData.puzzle.mediaType.split("/")[0] === "audio" ? (
                        <div className="audio-box-screen-1">
                          <div id="hint-need-audio">
                            <AudioPlayer
                              src={`${Base_URL}/${chapterData.puzzle.url}`}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="justify-content-center mt-5">
                        {chapterData.puzzle &&
                          chapterData.puzzle.mediaType &&
                          chapterData.puzzle.mediaType.split("/")[0] === "video" ? (
                          <div className="game-form col-md-4 m-auto text-center ">
                            {videoEnd == true ? <button
                              className="styledBtn px-btn"
                              onClick={() => {
                                handleContinueVideo();
                              }}
                            >
                              <span>
                                {labelData?.continueButtonOnVideo}
                              </span>
                            </button> : ""}

                          </div>
                        ) : (
                          <div className="game-form col-md-4 m-auto text-center ">
                            <h2 className="similer-title">
                              {labelData && labelData.enterTextCodeLabel
                                ? labelData.enterTextCodeLabel
                                : "what is the code?"}
                            </h2>
                            <form>
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <FormControl
                                  fullWidth
                                  error={solErr.error && solErr.text}
                                >
                                  <InputStyle
                                    placeholder={labelData?.typeGamePlaceholderText}
                                    InputProps={{ className: classes.input }}
                                    name="solution"
                                    autoComplete="off"
                                    value={solution}
                                    onChange={(e) => {
                                      handleChangeSolution(e);
                                    }}
                                  />
                                  <FormHelperText>
                                    {solErr.error && solErr.text ? labelData?.wrongCode : solErr.error && solErr.text}
                                  </FormHelperText>
                                </FormControl>
                                <button
                                  onClick={handleHeavyClick}
                                  className="code-submit-btn"
                                >
                                  <span>
                                    {labelData?.enterButton}
                                  </span>
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Clues
            clueData={chapterData.clues ? chapterData.clues : []}
            labels={labelData}
            setShrink={setShrink}
            handleOpenHints={() => { handleOpenHints() }}
            hintLable={labelData.hintsLabel}
          />
          <Modal maxwidth="md" show={success.show} centered backdrop="static">
            <Result
              correctSolution={chapterSol}
              data={success}
              onContinue={() => {
                handleContinueChapter();
              }}
            />
          </Modal>
          {/* <Modal show={show} backdrop="static" closeButton>
            <GameFinishedPage />
          </Modal> */}
          <Hints
            penalty={penalty}
            setPenalty={setPenalty}
            hintLabel={
              labelData && labelData.hintsLabel
                ? labelData.hintsLabel
                : "Need a Hint"
            }
            data={chapterData?.hints}
            answerData={{
              id: chapterData?._id,
              solution: chapterData?.solution,
              solutionPoints: chapterData?.solutionPoints,
            }}
            labels={labelData}
            show={showHint}
            solutionTaken={solutionTaken}
            setSolutionTaken={setSolutionTaken}
            hintTaken={hintTaken}
            handleClose={() => {
              handleCloseHints();
            }}
            gameId={gameId}
            podId={podId}

          />
        </>
      )}
    </>
  );
};

export default UserGame;
