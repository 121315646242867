import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../assets/images/logo 1.png";
import { GetPodDetails } from "../Services/Service";
// import logo from '../../../assets/images/logo.svg'

const AllTeams = () => {
  const [podDetails, setPodDetails] = useState();
  const { podId } = useParams();
  const navigate = useNavigate();
  document.body.classList.add("loading-indicator");

  const handleClickOnTeamBtn = (e, link) => {
    e.preventDefault();
    window.location.href = link;
  };

  useEffect(() => {
    console.log(podId);
    GetPodDetails(podId)
      .then((res) => {
        console.log(res);
        setPodDetails(res.data.data);
        document.body.classList.remove("loading-indicator");
      })
      .catch((err) => {
        navigate("/404");
        document.body.classList.remove("loading-indicator");
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-black all-teams-main-container">
      <div className="text-center">
        <h1 className="">{podDetails?.podName}</h1>
        <p className="tex-center">
          CLICK YOUR BREAKOUT ROOM NUMBER TO JOIN THE GAME
        </p>
      </div>
      <div className="overflow-hidden mt-5">
        <div className="row">
          <div className="col-6">
            {podDetails?.teamLinks?.map((team, index) => {
              return (
                <button
                  className="styledBtn px-btn"
                  onClick={(e) => {
                    handleClickOnTeamBtn(e, team.link);
                  }}
                >
                  <span>TEAM {index + 1}</span>
                </button>
              );
            })}
          </div>
          <div className="col-6 d-flex justify-content-center">
            <img src={logo} className="logo-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTeams;
