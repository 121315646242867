import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { login } from '../Admin/Services/Service';
import { Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../layouts/AdminLayout/Breadcrumb';
import { InputLabel, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { loginSuccess } from '../../Actions/ActionCreators'
import { useDispatch } from 'react-redux';

const SignIn = () => {
  const dispatch = useDispatch()
  const [pass, setPass] = useState(false);

  const handleClickShowPassword = () => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values, { resetForm }) => {

    try {
      const loginData = await login(values);
      if (loginData.status === 200) {
        let temp = {
          user: loginData.data.data,
          token: loginData?.data?.token
        }
        localStorage.setItem('x-auth', loginData?.data?.token);
        localStorage.setItem('token', loginData?.data?.token);
        dispatch(loginSuccess(temp))
        toast.success(loginData.data.message);

      }

    } catch (err) {
      console.log('gdgdg', err.response);
      resetForm();
      toast.error(err.response.data.message);
    }
  };
  return (
    <React.Fragment>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless">
            <Card.Body>
              <div className=" text-center mb-4">
                <i className="feather icon-unlock auth-icon" />
                <h3 className="mb-4">Login </h3>
              </div>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(100).required('Email is required').trim(),
                  password: Yup.string().max(255).required('Password is required').trim()
                })}
                onSubmit={handleSubmit}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <InputLabel>Email Address / Username</InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={touched.email && errors.email}
                        name="email"
                        placeholder="Enter email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email.replace(' ', '')}
                      />
                      {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
                    </div>
                    <div className="form-group mb-4">
                      <InputLabel>Password</InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type={pass ? 'text' : 'password'}
                        error={touched.password && errors.password}
                        name="password"
                        onBlur={handleBlur}
                        placeholder="Enter password"
                        onChange={handleChange}
                        value={values.password.replace(' ', '')}

                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {pass ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
                    </div>

                    {errors.submit && (
                      <Col sm={12}>
                        <Alert variant="danger">{errors.submit}</Alert>
                      </Col>
                    )}

                    <Row>
                      <Col mt={2}>
                        <Button className="btn-block" color="primary" disabled={isSubmitting} size="large" type="submit" variant="primary">
                          Sign In
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
              <p className="mb-2 text-muted">
                Forgot password?{' '}
                <NavLink to="/auth/forgotpassword" className="f-w-400 text-warning">
                  Reset
                </NavLink>
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
