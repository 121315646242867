export const LOADING = 'LOADING';

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_USER = 'SET_USER';
export const REMEMBER_USER = 'REMEMBER_USER';

export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CLEAN_LOGIN_DATA = 'CLEAN_LOGIN_DATA';



export const PLAY = 'PLAY';

export const TEAM_DATA_SUCCESS = 'TEAM_DATA_SUCCESS';
export const PENALTY_FUN = 'PENALTY_FUN';
export const CHAPTER_FUN = 'CHAPTER_FUN';
export const CHAPTER_SOLUTION = 'CHAPTER_SOLUTION';
export const TEAM_FUN = 'TEAM_FUN';
export const SOLUTION_FUN = 'SOLUTION_FUN';

export const GAME_DATA_SUCCESS = 'GAME_DATA_SUCCESS';
export const GAME_FINISH = 'GAME_FINISH';

export const CHAPTER_DATA_SUCCESS = 'CHAPTER_DATA_SUCCESS';
export const HINT_FUN = 'HINT_FUN';



export const CLEAN_ALL_DATA = 'CLEAN_ALL_DATA';