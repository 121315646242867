import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gameList } from '../Services/GameServices';
import { podAll } from '../Services/PodServices';
import { editTeam } from '../Services/TeamServices';
import EditValidation from './Validation/EditValidation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditTeam = () => {
  const location = useLocation();
  const [update, setUpdate] = useState(location?.state);

  const [gameData, setGameData] = useState([]);
  const [podData, setPodData] = useState([]);

  const [startDate, setStartDate] = useState(`${update.startTime.hours}:${update.startTime.minutes}`);
  const [endDate, setEndDate] = useState(`${update.endTime.hours}:${update.endTime.minutes}`);
  const [gameId, setGameId] = useState(update?.gameId._id);
  const [podId, setPodId] = useState(update?.podId._id);
  const [teamName, setTeamName] = useState(update?.teamName);
  const [error, setError] = useState(false);
  console.log('update', update);

  const options = gameData.map((game) => ({
    key: game.name,
    id: game._id
  }));
  const podoptions = podData.map((pod) => ({
    key: pod.name,
    id: pod._id
  }));

  const getGameData = async () => {
    const result = await gameList();
    console.log(result, 'hhfewreww');
    setGameData(result?.data.data);
  };
  useEffect(() => {
    getGameData();
    getPodData();
  }, []);

  const getPodData = async () => {
    const result = await podAll();
    console.log(result, 'hhfewreww');
    setPodData(result?.data.data);
  };

  const onGameSelect = (e) => {
    console.log(e);
    setGameId(e[0].id);
  };
  const onPodSelect = (e) => {
    setPodId(e[0].id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (teamName.length != 0 && gameId.length != 0 && podId.length != 0) {
        const teamResult = await editTeam({
          _id: update?._id,
          teamName,
          gameId,
          podId,
          endTime: { hours: endDate.split(':')[0], minutes: endDate.split(':')[1] },
          startTime: { hours: startDate.split(':')[0], minutes: startDate.split(':')[1] }
        });
        toast.success(teamResult.data.message);
      } else {
        setError(true);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <>
      <EditValidation
        teamName={teamName}
        setTeamName={setTeamName}
        onGameSelect={onGameSelect}
        options={options}
        onPodSelect={onPodSelect}
        podoptions={podoptions}
        podId={podId}
        gameId={gameId}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        error={error}
        handleSubmit={handleSubmit}
        update={update}
      />
    </>
  );
};

export default EditTeam;
