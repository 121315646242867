import React, { useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { Grid, Tooltip } from '@mui/material';
import MaterialTable from 'material-table';
import { LabelGetList, GetLabelDelete } from '../Services/LableServices';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const LabelList = () => {
  const TableRef = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [deletePod, setDeletePod] = useState('');

  const getData = async (query) => {
    const { page, pageSize, search, orderBy, orderDirection } = query;
    const body = {
      search: search,
      pageNumber: page + 1,
      pageSize: pageSize,
      'sortBy': orderBy?.field,
      sortOrder: orderDirection === 'asc' ? 1 : -1,
    };

    let resp = await LabelGetList(body);
    const data = resp?.data?.data?.result;
    const totalCount = resp?.data?.data?.totalLabels;
    return {
      data,
      page,
      totalCount
    };
  }

  const handleLabelView = (data) => {
    navigate('/admin/label/view/' + data._id, { state: data })

  }
  const handleLabelEdit = (data) => {
    navigate('/admin/label/edit/' + data._id, { state: data })
  }
  const handleLabelAdd = () => {
    navigate("/admin/label/add")
  }
  const handleDelete = (deleteId) => {
    setShow(true);
    setDeletePod(deleteId);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handlePodDelete = async () => {
    setShow(false);
    try {
      const podDeleteData = await GetLabelDelete({ _id: deletePod });
      TableRef.current.onQueryChange();
    } catch (err) { }
  };

  return (
    <>
      {/* <Row>
        <Col sm={12}> */}
      <div>
        <Card style={{ padding: '0px' }}>
          <Card.Header>
            <div className="row  ">
              <div className="col-sm-9">
                <Card.Title as="h5">Label Management</Card.Title>
              </div>
            </div>
          </Card.Header>
          <div>

            <MaterialTable
              tableRef={TableRef}
              title={
                <Button className="btn btn-primary btn-sm " onClick={() => handleLabelAdd()} style={{ cursor: 'pointer' }}>
                  ADD
                </Button>
              }
              columns={[
                // {
                //   title: 'Pod Name', field: 'hintsLabel', render: (rowData) => (
                //     rowData?.podId?.name
                //   ), sorting: false
                // },
                { title: 'Chapter Name Label', field: 'chapterNameLabel' },
                { title: 'Text Code Label', field: 'enterTextCodeLabel' },
                { title: 'Penalty Label', field: 'penaltiesLabel' },
                { title: 'Hint Label', field: 'hintsLabel' },
                {
                  title: 'Actions', render: (rowData) => {
                    return (
                      <>

                        {/* <Grid container spacing={2}> */}
                        {/* <Grid item lg={3} xs={12}> */}
                        <Tooltip title="View">
                          <VisibilityIcon className='view-icon' onClick={() => handleLabelView(rowData)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                        </Tooltip>
                        {/* </Grid> */}
                        {/* <Grid item lg={3} xs={12}> */}
                        <Tooltip title="Edit">
                          <EditIcon onClick={() => handleLabelEdit(rowData)} style={{ marginRight: '10px', cursor: 'pointer' }} />
                        </Tooltip>
                        {/* </Grid> */}
                        {/* <Grid item lg={3} xs={12}> */}
                        <Tooltip title="Delete">
                          <DeleteIcon
                            className='dlt-icon'
                            onClick={() => { handleDelete(rowData._id); }}
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                          />
                        </Tooltip>
                        {/* </Grid> */}

                        {/* </Grid> */}
                      </>
                    )
                  }, sorting: false
                },

              ]}
              data={getData}
              options={{
                draggable: false,
              }}
            />
          </div>
        </Card>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-outline-secondary" onClick={handleClose}>
              NO
            </Button>
            <Button className="btn btn-primary" onClick={handlePodDelete}>
              YES
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* </Col>
      </Row> */}
    </>
  );
};

export default LabelList;
