import axios from 'axios';
import { teamUrl } from '../Config/config';
import Interceptor from '../../../config/Interceptor';
var data = {};

export const teamGet = () => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${teamUrl}/geAllTeam`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const teamAdd = (team) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${teamUrl}/addTeam`, team, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const getOneTeam = (id) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${teamUrl}/getOneTeamDetails`, id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const editTeam = (team) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${teamUrl}/updateTeam`, team, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
