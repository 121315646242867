import { CHAPTER_DATA_SUCCESS, CHAPTER_SOLUTION, CLEAN_ALL_DATA, CHAPTER_FUN } from '../Actions/Types';





const chapter = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            data: {},
            error: {},
        }
    }


    switch (action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: {},
                error: {},
            }

        case CHAPTER_DATA_SUCCESS:
            return {
                ...state,
                data: action.data
            }

        case CHAPTER_SOLUTION:
            return {
                ...state,
                data: action.data
            }


        // case CHAPTER_FUN:
        //     return {
        //         ...state,
        //         data: action.data
        //     }




        default:
            return state






    }
}



export default chapter;