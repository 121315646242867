import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetUrlDetailsFromShortUrl } from "../Services/Service";

const ShortUrl = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  document.body.classList.add('loading-indicator')
  useEffect(() => {
    console.log(id);
    GetUrlDetailsFromShortUrl(id)
      .then((res) => {
        console.log(res);
        navigate(
          `/user/${res.data.tokenId}/${res.data.gameId}/${res.data.podId}`
        );
      })
      .catch((err) => {
        document.body.classList.remove('loading-indicator')
        navigate("/404");
        console.log(err);
      });
  }, []);

  return <div></div>;
};

export default ShortUrl;
