import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      e.stopPropagation()
      callback();
    }
  };
  const handleDrag = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      e.stopPropagation()
      callback();
    }
  };


  const handleTouch = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      e.stopPropagation()
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // document.addEventListener('drag', handleDrag);
    // document.addEventListener('mousedown', handleDrag);
    document.addEventListener('touchstart', handleTouch)
    // document.addEventListener('touchmove', handleTouch)
    return () => {
      document.removeEventListener('click', handleClick);
      // document.removeEventListener('drag', handleDrag);
      // document.removeEventListener('mousedown', handleDrag);
      document.removeEventListener('touchstart', handleTouch)
      // document.removeEventListener('touchmove', handleTouch)
    };
  });
};

export default useOutsideClick;
