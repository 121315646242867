import React, { useState, useEffect } from 'react';
import { Grid,Stack, Typography } from '@mui/material';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOneGame } from '../Services/GameServices';
const OneGameView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [game, setGame] = useState();
  useEffect(() => {
    getGame();
  }, []);
  const getGame = async () => {
    const game = await getOneGame({ _id: location?.state._id });
    setGame(game?.data.data);
  };
  const handleList = () => {
    navigate('/admin/gamelist');
  };
  return (
    <>
      <div>
        <Card>
          <Card.Header>
            <Row>
              <Col md={11}>
                {' '}
                <Card.Title as="h5">Game Detail</Card.Title>{' '}
              </Col>
              <Col md={1}>
                <Button className="btn btn-primary btn-sm" onClick={() => handleList()}>
                  BACK
                </Button>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Game Name</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.result?.name}</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Total Chapter</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.totalChapters}</h6>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Chapters</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.chapterResult.map((data,idx)=>(idx===game?.chapterResult.length-1 ? `${data.name}` : `${data.name}, `))}</h6>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Chapter Label</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.labelResult?.chapterNameLabel}</h6>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Text Code Label</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.labelResult?.enterTextCodeLabel}</h6>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Hint Label</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.labelResult?.hintsLabel}</h6>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={3} md={3} xs={12}>
                <h6 className = "fw-bold">Penalty Label</h6>
              </Col>
              <Col lg={9} md={9} xs={12}>
                <h6 className="">{game?.labelResult?.penaltiesLabel}</h6>
              </Col>
            </Row>  
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default OneGameView;
