import axios from 'axios';
import { chapterUrl } from '../Config/config';
import Interceptor from '../../../config/Interceptor';

export const ChapterAdd = (formData) => {
  const token = localStorage.getItem('token');
  console.log(formData, 'fff');
  return Interceptor().post(`${chapterUrl}/addChapter`, formData, {
    headers: {
      Authorization: ` Bearer ${token}`
    }
  });
};

export const getChapterOne = (_id) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${chapterUrl}/getOneChapterDetails`, _id, {
    headers: {
      Authorization: ` Bearer ${token}`
    }
  });
};

export const viewAllChapters = (data) => {
  const token = localStorage.getItem('token');
  console.log(token);
  return Interceptor().post(`${chapterUrl}/viewAllChapters`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const getChapterAll = (data) => {
  const token = localStorage.getItem('token');
  console.log(token);
  return Interceptor().post(`${chapterUrl}/getAllChapter`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
//trash chapter
export const getChapterTrash = (id) => {
  const token = localStorage.getItem('token');
  console.log(token);
  return Interceptor().post(`${chapterUrl}/retrieveChapters`, id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const chapterDelete = (_id) => {
  console.log('idddd', _id);
  const token = localStorage.getItem('token');
  return Interceptor().post(`${chapterUrl}/deleteChapter`, _id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const editchapter = (formData) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${chapterUrl}/updateChapter`, formData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const reorderChapter = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${chapterUrl}/reorderChapters`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
