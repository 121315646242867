import { GAME_DATA_SUCCESS, CLEAN_ALL_DATA, GAME_FINISH, TIME_UP } from '../Actions/Types';





const game = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            data: {},
            error: {},
            finished: false
        }
    }


    switch (action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: {},
                error: {},
                finished: false
            }

        case GAME_DATA_SUCCESS:
            return {
                ...state,
                data: action.data
            }

        case GAME_FINISH:
            return {
                ...state,
                finished: true
            }


        default:
            return state






    }
}



export default game;