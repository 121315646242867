import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';

import store from './ReduxStore/Store';
import Routing from './Routes/index';


if (navigator.userAgent.includes('Edg/')) {
  console.log(navigator.userAgent)
  document.body.classList.add('edge-class')
}


const App = () => {
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
  })
  return (
    <Provider store={store}>
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover limit={3} />
      <Routing />

    </Provider>
  );
};

export default App;

