import React, { useState, useEffect } from 'react';

import { gameList } from '../Services/GameServices';
import { podAll } from '../Services/PodServices';
import { teamAdd } from '../Services/TeamServices';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddValidation from './Validation/AddValidation';
const TeamAdd = () => {
  const [gameData, setGameData] = useState([]);
  const [podData, setPodData] = useState([]);
  const [startDate, setStartDate] = useState('00:00');
  const [endDate, setEndDate] = useState('00:00');

  const [gameId, setGameId] = useState([]);
  const [podId, setPodId] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [error, setError] = useState(false);

  const options = gameData.map((game) => ({
    key: game.name,
    id: game._id
  }));
  const podoptions = podData.map((pod) => ({
    key: pod.name,
    id: pod._id
  }));

  const getGameData = async () => {
    const result = await gameList();
    console.log(result, 'hhfewreww');
    setGameData(result?.data.data);
  };
  useEffect(() => {
    getGameData();
    getPodData();
  }, []);

  const getPodData = async () => {
    const result = await podAll();
    console.log(result, 'hhfewreww');
    setPodData(result?.data.data);
  };

  const onGameSelect = (e) => {
    console.log(e);
    setGameId(e[0].id);
  };
  const onPodSelect = (e) => {
    setPodId(e[0].id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (teamName.length != 0 && gameId.length != 0 && podId.length != 0 && startDate.length != 0 && endDate != 0) {
        const teamResult = await teamAdd({
          teamName,
          gameId,
          podId,
          endTime: { hours: endDate.split(':')[0], minutes: endDate.split(':')[1] },
          startTime: { hours: startDate.split(':')[0], minutes: startDate.split(':')[1] }
        });
        toast.success(teamResult.data.message);
        setTeamName('');
      } else {
        setError(true);
      }
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.message);
    }
  };

  return (
    <div>
      <AddValidation
        teamName={teamName}
        setTeamName={setTeamName}
        onGameSelect={onGameSelect}
        options={options}
        onPodSelect={onPodSelect}
        podoptions={podoptions}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        podId={podId}
        gameId={gameId}
        error={error}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default TeamAdd;
