import { useCallback, useState } from "react"


function useDebounce(callback, delay) {
    const [timer, setTimer] = useState(null);

    return useCallback((...args) => {
        if (timer) {
            clearTimeout(timer);
        }

        setTimer(setTimeout(() => {
            callback(...args);
        }, delay));

    }, [callback, delay]);
}



export default useDebounce