import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { url } from '../../Config/config';
import { Grid, Box } from '@material-ui/core';
import ImageModal from './ImageModel';
import VedioModel from './VedioModel';
import AudioPlayerAdmin from '../../../../components/AudioPlayerAdmin';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const condition = ['png', 'jpg', 'jpeg'];

export default function HintCard({ hint }) {

  const [showImageModel, setshowImageModel] = useState({
    show: false,
    data: null
  });

  const [vedioModel, setVedioModel] = useState({
    show: false,
    data: null
  });


  return (
    <Grid container spacing={3}>
      {hint.map((data, idx) => (
        data.deleteStatus === false ?
          <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
            <Card style={{ boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px", minHeight: "100%" }}>
              <div style={{ margin: '10px 15px', display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                <h6><b>Title :</b>{`${data.title}`}</h6>
                <h6><b>Points :</b>{`${data.points}`}</h6>
              </div>

              {/* <h5 style={{margin: '15px', marginBottom: '0px'}}>{`Title : ${data.title}`} {`Points :${data.points}`} </h5> */}
              {data.hintsType === 'text' ?

                <>
                  <h6 style={{ margin: '5px 15px' }}><b>Hint Text :</b></h6>
                  <p style={{ textAlign: 'justify', padding: "0px 15px" }} >{data.text}</p>
                </>
                :
                <>
                  <h6 style={{ margin: '5px 15px' }}><b>Hint File :</b></h6>

                  {data.mediaType && (data.mediaType).split("/")[0] === "video" ?
                    <div className="admin-video-container">
                      <video
                        preload="auto"
                        controlsList="nodownload"
                        // controls
                        onClick={() => { setVedioModel({ ...vedioModel, show: true, data: `${url}${data.url}` }) }}
                        style={{
                          // top: 8,
                          // borderRadius: 1,
                          // border:"2px solid #000",              
                          objectFit: 'contain',
                          padding: '5px',
                          width: '100%',
                          minHeight: '180px',
                          maxHeight: '272px',
                          cursor: 'pointer'
                        }}
                      >
                        <source src={`${url}${data.url}`}></source>
                      </video>
                      <span className="play-icon-admin"
                        style={{ cursor: 'pointer' }} onClick={() => { setVedioModel({ ...vedioModel, show: true, data: `${url}${data.url}` }) }} >
                        <PlayArrowIcon fontSize='large' />
                      </span>
                    </div>
                    : data.mediaType && (data.mediaType).split("/")[0] === "image" ?
                      <Box
                        component="img"
                        alt="file preview"
                        src={`${url}${data.url}`}
                        onClick={() => { setshowImageModel({ ...showImageModel, show: true, data: `${url}${data.url}` }) }}
                        sx={{
                          // top: 8,
                          // borderRadius: 1,
                          // border:"2px solid #000",              
                          objectFit: 'cover',
                          padding: '15px 5px',
                          width: '100%',
                          minHeight: '180px',
                          maxHeight: '272px',
                          cursor: 'pointer'
                        }}
                      />
                      : data.mediaType && (data.mediaType).split("/")[0] === "audio" ?
                        <div id="hint-need-audio" style={{ padding: "0 10px" }}>
                          <AudioPlayerAdmin src={`${url}${data.url}`} />
                        </div>
                        :
                        <></>}
                  {(data.description !== "" && data.description !== null && data.description !== undefined) &&
                    <>
                      <h6 style={{ margin: '5px 15px' }}><b>Description :</b></h6>
                      <p style={{ textAlign: 'justify', padding: "0px 15px" }} >{data.description}</p>
                    </>}
                </>}

            </Card>
            <ImageModal show={showImageModel.show} data={showImageModel.data} onClose={() => setshowImageModel({
              ...showImageModel, show: false
            })}
            />

            <VedioModel show={vedioModel.show} data={vedioModel.data} onClose={() => setVedioModel({
              ...vedioModel, show: false
            })}
            />
          </Grid>
          : null
      ))}
    </Grid>


  );
}
