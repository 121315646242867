import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { url } from '../Config/config';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
  CardHeader,
  TextField,
  Box,
} from "@material-ui/core";
import {
  GetPodNames,
  LabelGetDetail,
  LabelEdit,
} from "../Services/LableServices";
import { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const LabelEditPage = () => {
  const [gameData, setGameData] = useState([]);

  const allowed_ext = ["mp4", "wav", "m4a", "mpeg", "quicktime", "mov"];
  const videoRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log("gameID", id);

  useEffect(() => {
    getGameData();
    // getPodData();
    getLabelDetail();
  }, []);

  const LabelSchema = Yup.object().shape({
    welcome: Yup.string()
      .required("Welcome label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    enterYourNamePlaceholderText: Yup.string()
      .required("Enter your name placeholder text label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    teammatesJoining: Yup.string()
      .required("Teammates joining label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    teammatesJoined: Yup.string()
      .required("Teammates joined label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    typeGamePlaceholderText: Yup.string()
      .required("Type here placeholder text label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    typeTeamPlaceholderText: Yup.string()
      .required("Type game placeholder text label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    saveButtonLabel: Yup.string()
      .required("Save button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    watchVideoButtonLabel: Yup.string()
      .required("Watch video button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    closeButtonOnVIdeo: Yup.string()
      .required("Close button on video label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    // videoItself: Yup.string()
    //   .required("Video label is required")
    //   .strict()
    //   .trim("Trailing white spaces not allowed"),
    textUnderVideo: Yup.string()
      .required("Text under video label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    startGameButton: Yup.string()
      .required("Start game button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    timeRemainig: Yup.string()
      .required("Time Remainig label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    enterButton: Yup.string()
      .required("Enter button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    yourTeamNamedNow: Yup.string()
      .required("Your team named now popup is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    yourTeamNamePopupContinueButton: Yup.string()
      .required("Your team name popup continue button is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    enterCodePlaceholder: Yup.string()
      .required("Code placeholder is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    wrongCode: Yup.string()
      .required("Wrong code label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    clueLabel: Yup.string()
      .required("Clue label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    closeButton: Yup.string()
      .required("Close button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    continueButtonOnVideo: Yup.string()
      .required("Continue button on video scenes label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    continueButtonOnPopup: Yup.string()
      .required("Continue button on popup label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    continueButtonOnWelcome: Yup.string()
      .required("Continue button label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    gameTimeoutHeader: Yup.string()
      .required("Game timeout header label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    gameTimeoutText: Yup.string()
      .required("Game timeout text label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    enterTextCodeLabel: Yup.string()
      .required("Text code label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    penaltiesLabel: Yup.string()
      .required("Penalty label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    mins: Yup.string()
      .required("Min is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    hintsLabel: Yup.string()
      .required("Hints label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    chapterCompleteLabel: Yup.string()
      .required("Chapter Complete label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    chapterCompleteDesc: Yup.string()
      .required("Chapter Complete description is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    teamNameLabel: Yup.string()
      .required("Team Name label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    teamNamePlaceholderText: Yup.string()
      .required("Team Name placeholder text is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    teamNameDesc: Yup.string()
      .required("Team Name description is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    playScreenLabel: Yup.string()
      .required("Play Screen label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    playScreenDesc: Yup.string()
      .required("Play  Screen description is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    gameCompleteLabel: Yup.string()
      .required("Game Complete lable is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    point: Yup.string()
      .required("Point is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    points: Yup.string()
      .required("Points is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    hinttaken: Yup.string()
      .required("Hint taken is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    penalty: Yup.string()
      .required("Penalty is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    revealanswer: Yup.string()
      .required("Reveal answer is required")
      .strict()
      .trim("Trailing white spaces not allowed"),



    ViewLeaderboardButton: Yup.string()
      .required("Leaderboard is required")
      .strict()
      .trim("Trailing white spaces not allowed"),

    RefreshButton: Yup.string()
      .required("Refresh button is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    LeaderboardTitle: Yup.string()
      .required("Leaderboard title is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    Totaltime: Yup.string()
      .required("Total time is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    RevealAnswers: Yup.string()
      .required("Reveal answers is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    Finished: Yup.string()
      .required("Finished is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    Notfinished: Yup.string()
      .required("Not Finished is required")
      .strict()
      .trim("Trailing white spaces not allowed"),





    miscellaneousheader: Yup.string()
      .required("Miscellaneous header is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    miscellaneoustext: Yup.string()
      .required("Miscellaneous text is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    nocluelabel: Yup.string()
      .required("No Clue Label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),
    nohintlabel: Yup.string()
      .required("No Hint Label is required")
      .strict()
      .trim("Trailing white spaces not allowed"),

    gameId: Yup.string().required("Please select a game"),
    // podId: Yup.string().required('Please select a pod'),
  });

  const getGameData = async () => {
    const result = await GetPodNames();
    setGameData(result?.data.data);
    console.log(result?.data.data);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: "",
      welcome: "",
      enterYourNamePlaceholderText: "",
      continueButtonOnWelcome: "",
      teammatesJoining: "",
      teammatesJoined: "",
      typeTeamPlaceholderText: "",
      typeGamePlaceholderText: "",
      saveButtonLabel: "",
      watchVideoButtonLabel: "",
      closeButtonOnVIdeo: "",
      contentVideo: "",
      url: "",
      textUnderVideo: "",
      startGameButton: "",
      timeRemainig: "",
      enterButton: "",
      wrongCode: "",
      enterCodePlaceholder: "",
      clueLabel: "",
      yourTeamNamedNow: "",
      yourTeamNamePopupContinueButton: "",
      closeButton: "",
      continueButtonOnPopup: "",
      continueButtonOnVideo: "",
      gameTimeoutHeader: "",
      gameTimeoutText: "",
      gameTimeoutImage: "",
      gameTimeoutUrl: "",
      gameCompletedImage: "",
      contentVideothumbnail: "",
      gameThumbnailUrl: "",
      gameCompletedUrl: "",
      chapterNameLabel: "",
      enterTextCodeLabel: "",
      penaltiesLabel: "",
      hintsLabel: "",
      chapterCompleteLabel: "",
      chapterCompleteDesc: "",
      teamNameLabel: "",
      teamNamePlaceholderText: "",
      teamNameDesc: "",
      playScreenLabel: "",
      playScreenDesc: "",
      gameCompleteLabel: "",
      gameCompleteDesc: "",
      gameId: "",
      point: "",
      points: "",
      mins: "",
      hinttaken: "",
      penalty: "",
      revealanswer: "",

      ViewLeaderboardButton: "",
      RefreshButton: "",
      LeaderboardTitle: "",
      Totaltime: "",
      RevealAnswers: "",
      Finished: "",
      Notfinished: "",


      miscellaneousheader: "",
      miscellaneoustext: "",

      nocluelabel: "",
      nohintlabel: ""
      // podId: ""
    },

    validationSchema: LabelSchema,
    onSubmit: async (values) => {
      try {
        console.log(values);
        let formData = new FormData();
        formData.append("_id", values._id)
        formData.append("welcome", values.welcome)
        formData.append("enterYourNamePlaceholderText", values.enterYourNamePlaceholderText)
        formData.append("continueButtonOnWelcome", values.continueButtonOnWelcome)
        formData.append("teammatesJoining", values.teammatesJoining)
        formData.append("teammatesJoined", values.teammatesJoined)
        formData.append("typeTeamPlaceholderText", values.typeTeamPlaceholderText)
        formData.append("typeGamePlaceholderText", values.typeGamePlaceholderText)
        formData.append("saveButtonLabel", values.saveButtonLabel)
        formData.append("watchVideoButtonLabel", values.watchVideoButtonLabel)
        formData.append("closeButtonOnVIdeo", values.closeButtonOnVIdeo)
        formData.append("textUnderVideo", values.textUnderVideo)
        formData.append("startGameButton", values.startGameButton)
        formData.append("timeRemainig", values.timeRemainig)
        formData.append("enterButton", values.enterButton)
        formData.append("wrongCode", values.wrongCode)
        formData.append("enterCodePlaceholder", values.enterCodePlaceholder)
        formData.append("clueLabel", values.clueLabel)
        formData.append("closeButton", values.closeButton)
        formData.append("yourTeamNamedNow", values.yourTeamNamedNow)
        formData.append("yourTeamNamePopupContinueButton", values.yourTeamNamePopupContinueButton)
        formData.append("continueButtonOnPopup", values.continueButtonOnPopup)
        formData.append("continueButtonOnVideo", values.continueButtonOnVideo)
        formData.append("gameTimeoutHeader", values.gameTimeoutHeader)
        formData.append("gameTimeoutText", values.gameTimeoutText)
        formData.append("chapterNameLabel", values.chapterNameLabel)
        formData.append("enterTextCodeLabel", values.enterTextCodeLabel)
        formData.append("penaltiesLabel", values.penaltiesLabel)
        formData.append("hintsLabel", values.hintsLabel)
        formData.append("chapterCompleteLabel", values.chapterCompleteLabel)
        formData.append("chapterCompleteDesc", values.chapterCompleteDesc)
        formData.append("teamNameLabel", values.teamNameLabel)
        formData.append("teamNamePlaceholderText", values.teamNamePlaceholderText)
        formData.append("teamNameDesc", values.teamNameDesc)
        formData.append("playScreenLabel", values.playScreenLabel)
        formData.append("playScreenDesc", values.playScreenDesc)
        formData.append("gameCompleteLabel", values.gameCompleteLabel)
        formData.append("gameCompleteDesc", values.gameCompleteDesc)
        formData.append("point", values.point)
        formData.append("points", values.points)
        formData.append("hinttaken", values.hinttaken)
        formData.append("mins", values.mins)
        formData.append("penalty", values.penalty)
        formData.append("revealanswer", values.revealanswer)

        formData.append("ViewLeaderboardButton", values.ViewLeaderboardButton)
        formData.append("RefreshButton", values.RefreshButton)
        formData.append("LeaderboardTitle", values.LeaderboardTitle)
        formData.append("Totaltime", values.Totaltime)
        formData.append("RevealAnswers", values.RevealAnswers)
        formData.append("Finished", values.Finished)
        formData.append("Notfinished", values.Notfinished)






        formData.append("miscellaneousheader", values.miscellaneousheader)
        formData.append("miscellaneoustext", values.miscellaneoustext)

        formData.append("nocluelabel", values.nocluelabel)
        formData.append("nohintlabel", values.nohintlabel)

        formData.append("gameId", values.gameId)

        if (values.contentVideo !== null && values.contentVideo !== undefined && values.contentVideo !== "") {
          formData.append("contentVideo", values.contentVideo)
        }
        if (values.gameCompletedImage !== null && values.gameCompletedImage !== undefined && values.gameCompletedImage !== "") {
          formData.append("gameCompletedImage", values.gameCompletedImage)
        }
        if (values.gameTimeoutImage !== null && values.gameTimeoutImage !== undefined && values.gameTimeoutImage !== "") {
          formData.append("gameTimeoutImage", values.gameTimeoutImage)
        }
        if (values.contentVideothumbnail !== null && values.contentVideothumbnail !== undefined && values.contentVideothumbnail !== "") {
          formData.append("contentVideothumbnail", values.contentVideothumbnail)
        }

        console.clear();
        console.log("formData", formData)

        const respData = await LabelEdit(formData);
        console.log("repData", respData)

        if (respData.status === 200) {
          navigate(`/admin/game/edit/${id}`);
          toast.success(respData?.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response.data.message);
      }
    },
  });

  const { errors, values, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps, setValues } = formik;

  const handleDropFile = (e) => {
    console.log(e)
    let f = e.target.files[0];
    if (allowed_ext.includes(f.type.split("/")[1])) {
      setValues({
        ...values,
        contentVideo: f,
        url: URL.createObjectURL(f)
      })
      videoRef.current.load();
      // setFieldValue("contentVideo", f);
      // // setFieldValue('preview',f.name);
      // setFieldValue("url", URL.createObjectURL(f));
      console.log("sdfsdfsdfsdfdf")
    }
    else {
      toast.error(`Only ${allowed_ext} are allowed`);
    }
  };
  const allowed_img = ['png', 'jpg', 'jpeg'];

  const handleGameCompleteFile = (e) => {
    let f = e.target.files[0];
    if (allowed_img.includes(f.type.split("/")[1])) {
      setFieldValue("gameCompletedImage", f);
      // setFieldValue('preview',f.name);
      setFieldValue("gameCompletedUrl", URL.createObjectURL(f));
    }
    else {
      toast.error(`Only ${allowed_img} are allowed`);
    }
  };

  const handleGameTimeoutFile = (e) => {
    let f = e.target.files[0];
    if (allowed_img.includes(f.type.split("/")[1])) {
      setFieldValue("gameTimeoutImage", f);
      // setFieldValue('preview',f.name);
      setFieldValue("gameTimeoutUrl", URL.createObjectURL(f));
    }
    else {
      toast.error(`Only ${allowed_img} are allowed`);
    }
  };
  const handleGameThumbnailFile = (e) => {
    let t = e.target.files[0];
    if (allowed_img.includes(t.type.split("/")[1])) {
      setFieldValue("contentVideothumbnail", t);
      // setFieldValue('preview',f.name);
      setFieldValue("gameThumbnailUrl", URL.createObjectURL(t));
    }
    else {
      toast.error(`Only ${allowed_img} are allowed`);
    }
  };

  const getLabelDetail = async () => {
    const resp = await LabelGetDetail({ gameId: id });
    const data = resp.data.data;
    console.log(data)
    console.clear();
    // console.log("id____",data._id);
    if (!data.contentVideo) {
      data.contentVideo = "public/admin/default/1658826330902-Need For Speed -[GMV].mp4"
    }
    if (!data.gameCompletedImage) {
      data.gameCompletedImage = "public/admin/default/gameCompleted.png"
    }
    if (!data.gameTimeoutImage) {
      data.gameTimeoutImage = "public/admin/default/timeup.jpg"
    }
    if (!data.contentVideothumbnail) {
      data.contentVideothumbnail = "public/admin/default/videoThumbnail.jpg"
    }
    setValues({
      chapterNameLabel: data.chapterNameLabel,
      enterTextCodeLabel: data.enterTextCodeLabel,
      hintsLabel: data.hintsLabel,
      penaltiesLabel: data.penaltiesLabel,
      gameId: data.gameId,
      chapterCompleteLabel: data.chapterCompleteLabel,
      chapterCompleteDesc: data.chapterCompleteDesc,
      teamNameLabel: data.teamNameLabel,
      teamNamePlaceholderText: data.teamNamePlaceholderText,
      teamNameDesc: data.teamNameDesc,
      playScreenLabel: data.playScreenLabel,
      playScreenDesc: data.playScreenDesc,
      gameCompleteLabel: data.gameCompleteLabel,
      gameCompleteDesc: data.gameCompleteDesc,
      // podId: data?.podId,
      _id: data._id,
      welcome: data.welcome,
      enterYourNamePlaceholderText: data.enterYourNamePlaceholderText,
      continueButtonOnWelcome: data.continueButtonOnWelcome,
      teammatesJoining: data.teammatesJoining,
      teammatesJoined: data.teammatesJoined,
      typeTeamPlaceholderText: data.typeTeamPlaceholderText,
      typeGamePlaceholderText: data.typeGamePlaceholderText,
      saveButtonLabel: data.saveButtonLabel,
      yourTeamNamedNow: data.yourTeamNamedNow,
      watchVideoButtonLabel: data.watchVideoButtonLabel,
      closeButtonOnVIdeo: data.closeButtonOnVIdeo,
      textUnderVideo: data.textUnderVideo,
      startGameButton: data.startGameButton,
      timeRemainig: data.timeRemainig,
      enterButton: data.enterButton,
      wrongCode: data.wrongCode,
      enterCodePlaceholder: data.enterCodePlaceholder,
      clueLabel: data.clueLabel,
      closeButton: data.closeButton,
      continueButtonOnVideo: data.continueButtonOnVideo,
      continueButtonOnPopup: data.continueButtonOnPopup,
      yourTeamNamePopupContinueButton: data.yourTeamNamePopupContinueButton,
      gameTimeoutHeader: data.gameTimeoutHeader,
      gameTimeoutText: data.gameTimeoutText,
      contentVideo: data.contentVideo,
      gameTimeoutImage: data.gameTimeoutImage,
      gameCompletedImage: data.gameCompletedImage,
      contentVideothumbnail: data.contentVideothumbnail,

      url: url + data.contentVideo,
      gameCompletedUrl: url + data.gameCompletedImage,
      gameTimeoutUrl: url + data.gameTimeoutImage,
      gameThumbnailUrl: url + data.contentVideothumbnail,
      mins: data.mins,
      point: data.point,
      points: data.points,
      hinttaken: data.hinttaken,
      penalty: data.penalty,
      revealanswer: data.revealanswer,

      ViewLeaderboardButton: data.ViewLeaderboardButton,
      RefreshButton: data.RefreshButton,
      LeaderboardTitle: data.LeaderboardTitle,
      Totaltime: data.Totaltime,
      RevealAnswers: data.RevealAnswers,
      Finished: data.Finished,
      Notfinished: data.Notfinished,


      miscellaneousheader: data.miscellaneousheader,
      miscellaneoustext: data.miscellaneoustext,

      nocluelabel: data.nocluelabel,
      nohintlabel: data.nohintlabel
    });
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Edit Label</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="p-4">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Welcome screen:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Welcome*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Welcome Label"
                      variant="outlined"
                      name="welcome"
                      value={values.welcome}
                      error={Boolean(touched.welcome && errors.welcome)}
                      helperText={touched.welcome && errors.welcome}
                      {...getFieldProps("welcome")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Enter Your Name Placeholder Text*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter your name placeholder text"
                      variant="outlined"
                      name="enterYourNamePlaceholderText"
                      value={values.enterYourNamePlaceholderText}
                      error={Boolean(
                        touched.enterYourNamePlaceholderText &&
                        errors.enterYourNamePlaceholderText
                      )}
                      helperText={
                        touched.enterYourNamePlaceholderText &&
                        errors.enterYourNamePlaceholderText
                      }
                      {...getFieldProps("enterYourNamePlaceholderText")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Continue Button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Continue button Label"
                      variant="outlined"
                      name="continueButtonOnWelcome"
                      value={values.continueButtonOnWelcome}
                      error={Boolean(
                        touched.continueButtonOnWelcome && errors.continueButtonOnWelcome
                      )}
                      helperText={
                        touched.continueButtonOnWelcome && errors.continueButtonOnWelcome
                      }
                      {...getFieldProps("continueButtonOnWelcome")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Teammates Joining*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Teammates joining Label"
                      variant="outlined"
                      name="teammatesJoining"
                      value={values.teammatesJoining}
                      error={Boolean(
                        touched.teammatesJoining && errors.teammatesJoining
                      )}
                      helperText={
                        touched.teammatesJoining && errors.teammatesJoining
                      }
                      {...getFieldProps("teammatesJoining")}
                    />
                  </Grid>
                </Grid>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Team name section:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Teammates Joined*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Teammates joined Label"
                      variant="outlined"
                      name="teammatesJoined"
                      value={values.teammatesJoined}
                      error={Boolean(
                        touched.teammatesJoined && errors.teammatesJoined
                      )}
                      helperText={
                        touched.teammatesJoined && errors.teammatesJoined
                      }
                      {...getFieldProps("teammatesJoined")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Team Name Placeholder Text*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Team Name Label"
                      variant="outlined"
                      name="teamNamePlaceholderText"
                      value={values.teamNamePlaceholderText}
                      error={Boolean(
                        touched.teamNamePlaceholderText && errors.teamNamePlaceholderText
                      )}
                      helperText={touched.teamNamePlaceholderText && errors.teamNamePlaceholderText}
                      {...getFieldProps("teamNamePlaceholderText")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Header*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Team Name Label"
                      variant="outlined"
                      name="teamNameLabel"
                      value={values.teamNameLabel}
                      error={Boolean(
                        touched.teamNameLabel && errors.teamNameLabel
                      )}
                      helperText={touched.teamNameLabel && errors.teamNameLabel}
                      {...getFieldProps("teamNameLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Team Name Description*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Team Description"
                      variant="outlined"
                      name="teamNameDesc"
                      value={values.teamNameDesc}
                      error={Boolean(
                        touched.teamNameDesc && errors.teamNameDesc
                      )}
                      helperText={touched.teamNameDesc && errors.teamNameDesc}
                      {...getFieldProps("teamNameDesc")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Team Name Popup Header*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Save Button Label"
                      variant="outlined"
                      name="yourTeamNamedNow"
                      value={values.yourTeamNamedNow}
                      error={Boolean(
                        touched.yourTeamNamedNow && errors.yourTeamNamedNow
                      )}
                      helperText={
                        touched.yourTeamNamedNow && errors.yourTeamNamedNow
                      }
                      {...getFieldProps("yourTeamNamedNow")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Team Name Popup Continue Button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Save Button Label"
                      variant="outlined"
                      name="yourTeamNamePopupContinueButton"
                      value={values.yourTeamNamePopupContinueButton}
                      error={Boolean(
                        touched.yourTeamNamePopupContinueButton && errors.yourTeamNamePopupContinueButton
                      )}
                      helperText={
                        touched.yourTeamNamePopupContinueButton && errors.yourTeamNamePopupContinueButton
                      }
                      {...getFieldProps("yourTeamNamePopupContinueButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Save Button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Save Button Label"
                      variant="outlined"
                      name="saveButtonLabel"
                      value={values.saveButtonLabel}
                      error={Boolean(
                        touched.saveButtonLabel && errors.saveButtonLabel
                      )}
                      helperText={
                        touched.saveButtonLabel && errors.saveButtonLabel
                      }
                      {...getFieldProps("saveButtonLabel")}
                    />
                  </Grid>
                </Grid>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  How to play:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Watch video button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Watch video button Label"
                      variant="outlined"
                      name="watchVideoButtonLabel"
                      value={values.watchVideoButtonLabel}
                      error={Boolean(
                        touched.watchVideoButtonLabel &&
                        errors.watchVideoButtonLabel
                      )}
                      helperText={
                        touched.watchVideoButtonLabel &&
                        errors.watchVideoButtonLabel
                      }
                      {...getFieldProps("watchVideoButtonLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Close button (on video)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Close Button on video Lable"
                      variant="outlined"
                      name="closeButtonOnVIdeo"
                      value={values.closeButtonOnVIdeo}
                      error={Boolean(
                        touched.closeButtonOnVIdeo && errors.closeButtonOnVIdeo
                      )}
                      helperText={
                        touched.closeButtonOnVIdeo && errors.closeButtonOnVIdeo
                      }
                      {...getFieldProps("closeButtonOnVIdeo")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Play Screen Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Play Screen Label"
                      variant="outlined"
                      name="playScreenLabel"
                      value={values.playScreenLabel}
                      error={Boolean(
                        touched.playScreenLabel && errors.playScreenLabel
                      )}
                      helperText={
                        touched.playScreenLabel && errors.playScreenLabel
                      }
                      {...getFieldProps("playScreenLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Play Screen Description*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Play Screen Label"
                      variant="outlined"
                      name="playScreenDesc"
                      value={values.playScreenDesc}
                      error={Boolean(
                        touched.playScreenDesc && errors.playScreenDesc
                      )}
                      helperText={
                        touched.playScreenDesc && errors.playScreenDesc
                      }
                      {...getFieldProps("playScreenDesc")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>
                      Text under the video after it plays*
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Text under the video after it plays Label"
                      variant="outlined"
                      name="textUnderVideo"
                      value={values.textUnderVideo}
                      error={Boolean(
                        touched.textUnderVideo && errors.textUnderVideo
                      )}
                      helperText={
                        touched.textUnderVideo && errors.textUnderVideo
                      }
                      {...getFieldProps("textUnderVideo")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Start game button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Start game button Label"
                      variant="outlined"
                      name="startGameButton"
                      value={values.startGameButton}
                      error={Boolean(
                        touched.startGameButton && errors.startGameButton
                      )}
                      helperText={
                        touched.startGameButton && errors.startGameButton
                      }
                      {...getFieldProps("startGameButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Video*</InputLabel>
                    <input
                      type="file"
                      accept="video/*"
                      name="videoItself"
                      className="form-control"
                      onChange={handleDropFile}
                    />
                    {Boolean(touched.videoItself && errors.videoItself) && (
                      <small className="text-danger form-text">
                        {touched.videoItself && errors.videoItself}
                      </small>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Content Video Thumbnail*</InputLabel>
                    <input
                      type="file"
                      name="contentVideothumbnail"
                      className="form-control"
                      onChange={handleGameThumbnailFile}
                    />
                    {Boolean(touched.contentVideothumbnail && errors.contentVideothumbnail) && (
                      <small className="text-danger form-text">
                        {touched.contentVideothumbnail && errors.contentVideothumbnail}
                      </small>
                    )}
                    {values.gameThumbnailUrl &&
                      typeof values.gameThumbnailUrl === "string" ? (
                      <Grid item lg={6} md={12} xs={12}>
                        <Box
                          component="img"
                          alt="file preview"
                          src={values.gameThumbnailUrl}
                          sx={{
                            top: 8,
                            borderRadius: 1,
                            objectFit: "cover",
                            padding: "5px",
                            width: 'calc(100% - 2px)',
                            height: '200px'
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    {values.url &&
                      typeof values.url === "string" ? (
                      <Grid item lg={6} md={12} xs={12}>
                        <video
                          ref={videoRef}
                          controls
                          controlsList="nodownload"
                          style={{
                            top: 8,
                            borderRadius: 1,
                            border: "2px solid #000",
                            objectFit: "fit",
                            padding: "5px",
                            width: "calc(100% - 280px)",
                            height: "calc(100% - 280px)",
                          }}
                        >
                          <source src={values?.url}></source>
                        </video>
                      </Grid>
                    ) : (
                      <></>
                    )}

                  </Grid>
                </Grid>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Game section:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Chapter Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Chapter Label"
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                      name="chapterNameLabel"
                      value={values.chapterNameLabel}
                      error={Boolean(
                        touched.chapterNameLabel && errors.chapterNameLabel
                      )}
                      helperText={
                        touched.chapterNameLabel && errors.chapterNameLabel
                      }
                      {...getFieldProps("chapterNameLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Text Code Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Text Code Label"
                      variant="outlined"
                      name="enterTextCodeLabel"
                      value={values.enterTextCodeLabel}
                      error={Boolean(
                        touched.enterTextCodeLabel && errors.enterTextCodeLabel
                      )}
                      helperText={
                        touched.enterTextCodeLabel && errors.enterTextCodeLabel
                      }
                      {...getFieldProps("enterTextCodeLabel")}
                    />
                  </Grid>
                  {/* <Grid item lg={6} xs={12}>
                    <InputLabel>Text Code Placeholder*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Text Code Label"
                      variant="outlined"
                      name="enterCodePlaceholder"
                      value={values.enterCodePlaceholder}
                      error={Boolean(
                        touched.enterCodePlaceholder && errors.enterCodePlaceholder
                      )}
                      helperText={
                        touched.enterCodePlaceholder && errors.enterCodePlaceholder
                      }
                      {...getFieldProps("enterCodePlaceholder")}  
                    />
                  </Grid> */}

                  <Grid item lg={6} xs={12}>
                    <InputLabel>Hints Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Hints Label"
                      variant="outlined"
                      name="hintsLabel"
                      value={values.hintsLabel}
                      error={Boolean(touched.hintsLabel && errors.hintsLabel)}
                      helperText={touched.hintsLabel && errors.hintsLabel}
                      {...getFieldProps("hintsLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Time remaining*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Time remaining Label"
                      variant="outlined"
                      name="timeRemainig"
                      value={values.timeRemainig}
                      error={Boolean(
                        touched.timeRemainig && errors.timeRemainig
                      )}
                      helperText={touched.timeRemainig && errors.timeRemainig}
                      {...getFieldProps("timeRemainig")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Type here placeholder text*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Team Description"
                      variant="outlined"
                      name="typeGamePlaceholderText"
                      value={values.typeGamePlaceholderText}
                      error={Boolean(
                        touched.typeGamePlaceholderText &&
                        errors.typeGamePlaceholderText
                      )}
                      helperText={
                        touched.typeGamePlaceholderText &&
                        errors.typeGamePlaceholderText
                      }
                      {...getFieldProps("typeGamePlaceholderText")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Enter button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Enter Button Label"
                      variant="outlined"
                      name="enterButton"
                      value={values.enterButton}
                      error={Boolean(touched.enterButton && errors.enterButton)}
                      helperText={touched.enterButton && errors.enterButton}
                      {...getFieldProps("enterButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Wrong code*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Wrong Code Label"
                      variant="outlined"
                      name="wrongCode"
                      value={values.wrongCode}
                      error={Boolean(touched.wrongCode && errors.wrongCode)}
                      helperText={touched.wrongCode && errors.wrongCode}
                      {...getFieldProps("wrongCode")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Clues label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter clue Label"
                      variant="outlined"
                      name="clueLabel"
                      value={values.clueLabel}
                      error={Boolean(touched.clueLabel && errors.clueLabel)}
                      helperText={touched.clueLabel && errors.clueLabel}
                      {...getFieldProps("clueLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Points label (for multiple )*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter points Label"
                      variant="outlined"
                      name="points"
                      value={values.points}
                      error={Boolean(touched.points && errors.points)}
                      helperText={touched.points && errors.points}
                      {...getFieldProps("points")}
                    />
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <InputLabel>Hint taken label (In hint popup)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter hint taken Label"
                      variant="outlined"
                      name="hinttaken"
                      value={values.hinttaken}
                      error={Boolean(touched.hinttaken && errors.hinttaken)}
                      helperText={touched.hinttaken && errors.hinttaken}
                      {...getFieldProps("hinttaken")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Point label (for single)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter point Label"
                      variant="outlined"
                      name="point"
                      value={values.point}
                      error={Boolean(touched.point && errors.point)}
                      helperText={touched.point && errors.point}
                      {...getFieldProps("point")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Penalty label (Popup)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter penalty Label"
                      variant="outlined"
                      name="penalty"
                      value={values.penalty}
                      error={Boolean(touched.penalty && errors.penalty)}
                      helperText={touched.penalty && errors.penalty}
                      {...getFieldProps("penalty")}
                    />
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <InputLabel>Reveal answer label (In hint popup)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Reveal answer Label"
                      variant="outlined"
                      name="revealanswer"
                      value={values.revealanswer}
                      error={Boolean(touched.revealanswer && errors.revealanswer)}
                      helperText={touched.revealanswer && errors.revealanswer}
                      {...getFieldProps("revealanswer")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Penalty label (Game top left)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Penalties Label"
                      variant="outlined"
                      name="penaltiesLabel"
                      value={values.penaltiesLabel}
                      error={Boolean(
                        touched.penaltiesLabel && errors.penaltiesLabel
                      )}
                      helperText={
                        touched.penaltiesLabel && errors.penaltiesLabel
                      }
                      {...getFieldProps("penaltiesLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Min (Penalty)*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Min"
                      variant="outlined"
                      name="mins"
                      value={values.mins}
                      error={Boolean(
                        touched.mins && errors.mins
                      )}
                      helperText={
                        touched.mins && errors.mins
                      }
                      {...getFieldProps("mins")}
                    />
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <InputLabel>No Clue Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter No Clue Label"
                      variant="outlined"
                      name="nocluelabel"
                      value={values.nocluelabel}
                      error={Boolean(
                        touched.nocluelabel && errors.nocluelabel
                      )}
                      helperText={
                        touched.nocluelabel && errors.nocluelabel
                      }
                      {...getFieldProps("nocluelabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>No Hint Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter No Hint Label"
                      variant="outlined"
                      name="nohintlabel"
                      value={values.nohintlabel}
                      error={Boolean(
                        touched.nohintlabel && errors.nohintlabel
                      )}
                      helperText={
                        touched.nohintlabel && errors.nohintlabel
                      }
                      {...getFieldProps("nohintlabel")}
                    />
                  </Grid>
                </Grid>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Chapter section:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Chapter Complete Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Chapter Label"
                      variant="outlined"
                      name="chapterCompleteLabel"
                      value={values.chapterCompleteLabel}
                      error={Boolean(
                        touched.chapterCompleteLabel &&
                        errors.chapterCompleteLabel
                      )}
                      helperText={
                        touched.chapterCompleteLabel &&
                        errors.chapterCompleteLabel
                      }
                      {...getFieldProps("chapterCompleteLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Chapter Complete Description*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Chapter Description"
                      variant="outlined"
                      name="chapterCompleteDesc"
                      value={values.chapterCompleteDesc}
                      error={Boolean(
                        touched.chapterCompleteDesc &&
                        errors.chapterCompleteDesc
                      )}
                      helperText={
                        touched.chapterCompleteDesc &&
                        errors.chapterCompleteDesc
                      }
                      {...getFieldProps("chapterCompleteDesc")}
                    />
                  </Grid>
                </Grid>

                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Reveal answer:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Close button*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Close Button Label"
                      variant="outlined"
                      name="closeButton"
                      value={values.closeButton}
                      error={Boolean(touched.closeButton && errors.closeButton)}
                      helperText={touched.closeButton && errors.closeButton}
                      {...getFieldProps("closeButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Continue button on video scenes*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Continue Button Label"
                      variant="outlined"
                      name="continueButtonOnVideo"
                      value={values.continueButtonOnVideo}
                      error={Boolean(
                        touched.continueButtonOnVideo && errors.continueButtonOnVideo
                      )}
                      helperText={
                        touched.continueButtonOnVideo && errors.continueButtonOnVideo
                      }
                      {...getFieldProps("continueButtonOnVideo")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Continue button on popups*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Continue button on popups Label"
                      variant="outlined"
                      name="continueButtonOnPopup"
                      value={values.continueButtonOnPopup}
                      error={Boolean(
                        touched.continueButtonOnPopup &&
                        errors.continueButtonOnPopup
                      )}
                      helperText={
                        touched.continueButtonOnPopup &&
                        errors.continueButtonOnPopup
                      }
                      {...getFieldProps("continueButtonOnPopup")}
                    />
                  </Grid>
                </Grid>
                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Game Completion:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game Complete Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Game Complete Label"
                      variant="outlined"
                      name="gameCompleteLabel"
                      value={values.gameCompleteLabel}
                      error={Boolean(
                        touched.gameCompleteLabel && errors.gameCompleteLabel
                      )}
                      helperText={
                        touched.gameCompleteLabel && errors.gameCompleteLabel
                      }
                      {...getFieldProps("gameCompleteLabel")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game Complete Description*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Game Complete Description"
                      variant="outlined"
                      name="gameCompleteDesc"
                      value={values.gameCompleteDesc}
                      error={Boolean(
                        touched.gameCompleteDesc && errors.gameCompleteDesc
                      )}
                      helperText={
                        touched.gameCompleteDesc && errors.gameCompleteDesc
                      }
                      {...getFieldProps("gameCompleteDesc")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game timeout header*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Game Timeout Header Label"
                      variant="outlined"
                      name="gameTimeoutHeader"
                      value={values.gameTimeoutHeader}
                      error={Boolean(
                        touched.gameTimeoutHeader && errors.gameTimeoutHeader
                      )}
                      helperText={
                        touched.gameTimeoutHeader && errors.gameTimeoutHeader
                      }
                      {...getFieldProps("gameTimeoutHeader")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game timeout text*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter Game Timeout Text Label"
                      variant="outlined"
                      name="gameTimeoutText"
                      value={values.gameTimeoutText}
                      error={Boolean(
                        touched.gameTimeoutText && errors.gameTimeoutText
                      )}
                      helperText={
                        touched.gameTimeoutText && errors.gameTimeoutText
                      }
                      {...getFieldProps("gameTimeoutText")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game completed image*</InputLabel>
                    <input
                      type="file"
                      name="gameCompletedImage"
                      className="form-control"
                      onChange={handleGameCompleteFile}
                    />
                    {Boolean(touched.gameCompletedImage && errors.gameCompletedImage) && (
                      <small className="text-danger form-text">
                        {touched.gameCompletedImage && errors.gameCompletedImage}
                      </small>
                    )}
                    {values.gameCompletedUrl &&
                      typeof values.gameCompletedUrl === "string" ? (
                      <Grid item lg={6} md={12} xs={12}>
                        <Box
                          component="img"
                          alt="file preview"
                          src={values.gameCompletedUrl}
                          sx={{
                            top: 8,
                            borderRadius: 1,
                            objectFit: "cover",
                            padding: "5px",
                            width: 'calc(100% - 2px)',
                            height: '200px'
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Game timeout image*</InputLabel>
                    <input
                      type="file"
                      name="gameTimeoutImage"
                      className="form-control"
                      onChange={handleGameTimeoutFile}
                    />
                    {Boolean(touched.gameTimeoutImage && errors.gameTimeoutImage) && (
                      <small className="text-danger form-text">
                        {touched.gameTimeoutImage && errors.gameTimeoutImage}
                      </small>
                    )}
                    {values.gameTimeoutUrl &&
                      typeof values.gameTimeoutUrl === "string" ? (
                      <Grid item lg={6} md={12} xs={12}>
                        <Box
                          component="img"
                          alt="file preview" x
                          src={values.gameTimeoutUrl}
                          sx={{
                            top: 8,
                            borderRadius: 1,
                            objectFit: "cover",
                            padding: "5px",
                            width: 'calc(100% - 2px)',
                            height: '200px'
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Misc section:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Error header Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error header label"
                      variant="outlined"
                      name="miscellaneousheader"
                      value={values.miscellaneousheader}
                      error={Boolean(
                        touched.miscellaneousheader &&
                        errors.miscellaneousheader
                      )}
                      helperText={
                        touched.miscellaneousheader &&
                        errors.miscellaneousheader
                      }
                      {...getFieldProps("miscellaneousheader")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Error text Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="miscellaneoustext"
                      value={values.miscellaneoustext}
                      error={Boolean(
                        touched.miscellaneoustext &&
                        errors.miscellaneoustext
                      )}
                      helperText={
                        touched.miscellaneoustext &&
                        errors.miscellaneoustext
                      }
                      {...getFieldProps("miscellaneoustext")}
                    />
                  </Grid>
                </Grid>
                <Card.Title className="mt-4 font-weight-bold text-dark mb-2">
                  Leaderboard section:
                </Card.Title>
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>View Leaderboard Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error header label"
                      variant="outlined"
                      name="ViewLeaderboardButton"
                      value={values.ViewLeaderboardButton}
                      error={Boolean(
                        touched.ViewLeaderboardButton &&
                        errors.ViewLeaderboardButton
                      )}
                      helperText={
                        touched.ViewLeaderboardButton &&
                        errors.ViewLeaderboardButton
                      }
                      {...getFieldProps("ViewLeaderboardButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Refresh Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="RefreshButton"
                      value={values.RefreshButton}
                      error={Boolean(
                        touched.RefreshButton &&
                        errors.RefreshButton
                      )}
                      helperText={
                        touched.RefreshButton &&
                        errors.RefreshButton
                      }
                      {...getFieldProps("RefreshButton")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Leaderboard Title Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="LeaderboardTitle"
                      value={values.LeaderboardTitle}
                      error={Boolean(
                        touched.LeaderboardTitle &&
                        errors.LeaderboardTitle
                      )}
                      helperText={
                        touched.LeaderboardTitle &&
                        errors.LeaderboardTitle
                      }
                      {...getFieldProps("LeaderboardTitle")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Total Time Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="Totaltime"
                      value={values.Totaltime}
                      error={Boolean(
                        touched.Totaltime &&
                        errors.Totaltime
                      )}
                      helperText={
                        touched.Totaltime &&
                        errors.Totaltime
                      }
                      {...getFieldProps("Totaltime")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Reveal Answers Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="RevealAnswers"
                      value={values.RevealAnswers}
                      error={Boolean(
                        touched.RevealAnswers &&
                        errors.RevealAnswers
                      )}
                      helperText={
                        touched.RevealAnswers &&
                        errors.RevealAnswers
                      }
                      {...getFieldProps("RevealAnswers")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Finished Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="Finished"
                      value={values.Finished}
                      error={Boolean(
                        touched.Finished &&
                        errors.Finished
                      )}
                      helperText={
                        touched.Finished &&
                        errors.Finished
                      }
                      {...getFieldProps("Finished")}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <InputLabel>Not Finished Label*</InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Enter error text label"
                      variant="outlined"
                      name="Notfinished"
                      value={values.Notfinished}
                      error={Boolean(
                        touched.Notfinished &&
                        errors.Notfinished
                      )}
                      helperText={
                        touched.Notfinished &&
                        errors.Notfinished
                      }
                      {...getFieldProps("Notfinished")}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="end" mt={3}>
                  <Button
                    type="reset"
                    onClick={() => {
                      navigate(`/admin/game/edit/${id}`);
                    }}
                    className="btn btn-outline-secondary mr-3"
                  >
                    BACK
                  </Button>
                  <Button type="submit">UPDATE</Button>
                </Box>
              </Form>
            </FormikProvider>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default LabelEditPage;
