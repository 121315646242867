const menuItems = {
  items: [
    {
      id: 'navigation',
      // title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/admin/dashboard',
          icon: 'feather icon-home'
        },
        {
          id: 'gamemanagement',
          title: 'Game Management',
          type: 'item',
          url: '/admin/gamelist',
          icon: 'feather icon-book'
        },
        // {
        //   id: 'chapter',
        //   title: 'Chapter Management',
        //   type: 'item',
        //   icon: 'feather icon-book',
        //   url: '/puzzle/admin/chapterlist'
        // },
        {
          id: 'pod',
          title: 'Pod Management',
          type: 'item',
          url: '/admin/pod',
          icon: 'feather icon-home'
        },
        {
          id: 'report',
          title: 'Report Management',
          type: 'item',
          url: '/admin/reports',
          icon: 'feather icon-home'
        },
        // {
        //   id: 'cms',
        //   title: 'Content Management',
        //   type: 'item',
        //   url: '/admin/cms',
        //   icon: 'feather icon-home'
        // },
        // {
        //   id: 'team',
        //   title: 'Team Management',
        //   type: 'item',
        //   url: '/puzzle/admin/teamlist',
        //   icon: 'feather icon-home'
        // }

        // {
        //   id: 'label',
        //   title: 'Label Management',
        //   type: 'item',
        //   url: '/admin/label/list',
        //   icon: 'feather icon-home'
        // },

        // {
        //   id: 'leaderboard',
        //   title: 'Leaderboard Management',
        //   type: 'item',
        //   url: '/puzzle/admin/leaderboard',
        //   icon: 'feather icon-home'
        // },
        // {
        //   id: 'reports',
        //   title: 'Reports',
        //   type: 'item',
        //   url: '/puzzle/admin/reports',
        //   icon: 'feather icon-home'
        // },
        // {
        //   id: 'welcome',
        //   title: 'Welcome',
        //   type: 'item',
        //   url: '/puzzle/user/welcome',
        //   icon: 'feather icon-home'
        // }
      ]
    }
  ]
};

export default menuItems;
