import React from 'react';
import EditPuzzle from './EditPuzzle';
const EditChapter = () => {
  return (
    <>
      <EditPuzzle />
    </>
  );
};

export default EditChapter;
