import React, { useState, useEffect } from 'react';
import { teamGet } from '../Services/TeamServices';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
const TeamList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [teamList, setTeamList] = useState([]);
  const [filteredteamList, setFilteredTeamList] = useState([]);
  const [searched, setSearched] = useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  useEffect(() => {
    getTeamData();
  }, []);
  const getTeamData = async () => {
    try {
      const teamData = await teamGet();
      console.log('teamData', teamData);
      setTeamList(teamData.data.data);
      setFilteredTeamList(teamData.data.data);
    } catch (err) {
      console.log(err.response);
    }
  };
  const handleRequestSort = (event, property) => {
    // console.log('handlerequest', property);
    const isAsc = orderBy === 'Game Name' && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleTeamOne = (team) => {
    navigate('/admin/teamview', { state: team });
  };

  const handleTeamEdit = (team) => {
    navigate('/admin/editteam', { state: team });
  };
  const onAddTeam = () => {
    navigate('/admin/addteam');
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    requestSearch();
  }, [searched]);

  const requestSearch = async () => {
    let data = teamList;
    let filteredRows = [];
    // setSearched(searchedVal);
    if (searched.length) {
      filteredRows = data?.filter((team) => {
        return team.teamName.toLowerCase().includes(searched.toLowerCase());
      });
      setFilteredTeamList(filteredRows);
    } else {
      setFilteredTeamList(teamList);
    }
    console.log(filteredRows, 'filteredRows');

    setPage(0);

    // await gameList({ pageSize: rowsPerPage, pageNumber: page, search: searchedVal });
  };
  console.log('dhdshkjdfs', searched);
  return (
    <>
      <button className="btn btn-success mt-2" onClick={(e) => onAddTeam(e)}>
        Add{' '}
      </button>
      <input
        type="search"
        className="game__search"
        value={searched}
        // on={(e) => console.log('search', e)}
        onChange={(e) => setSearched(e.target.value)}
      />
      <table className="table">
        <thead>
          <tr onClick={handleRequestSort}>
            <th scope="col">Team Name</th>
            <th scope="col">Pod</th>

            <th scope="col">Game</th>
            <th scope="col">startTime</th>
            <th scope="col">endTime</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        {filteredteamList
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .sort(getComparator(order, orderBy))
          .map((team) => (
            <tbody>
              <td>{team?.teamName}</td>
              <td>{team?.podId.name}</td>
              <td>{team?.gameId.name}</td>
              <td>{team.startTime ? team.startTime.hours + ':' + team.startTime.minutes : 'NA'}</td>
              <td>{team.endTime ? team.endTime.hours + ':' + team.endTime.minutes : 'NA'}</td>

              <td>
                <button className="btn btn-info mt-2" onClick={() => handleTeamOne(team)}>
                  View
                </button>
                <button className="btn btn-primary mt-2" onClick={() => handleTeamEdit(team)}>
                  Edit
                </button>
              </td>
            </tbody>
          ))}
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={teamList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TeamList;
