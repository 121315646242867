import {combineReducers} from 'redux';

import login from './Login';
import team from './Team';
import game from './Game';
import chapter from './Chapter';
import play from './Play';

export default combineReducers({
    login,   
    team,
    game,
    chapter,
    play
 });