import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { url } from '../../Config/config';
import { Grid, Box } from '@material-ui/core';
import ImageModal from './ImageModel';
import VedioModel from './VedioModel';
import AudioPlayerAdmin from '../../../../components/AudioPlayerAdmin';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const condition = ['png', 'jpg', 'jpeg'];

export default function PuzzleCard({ puzzle }) {

  const [showImageModel, setshowImageModel] = useState({
    show: false,
    data: null
  });

  const [vedioModel, setVedioModel] = useState({
    show: false,
    data: null
  });

  return (

    <Grid container spacing={3}>
      {puzzle && puzzle.mediaType && (puzzle.mediaType).split("/")[0] === "video" ?
        <Grid item lg={4} md={6} xs={12}>
          <div className="admin-video-container">
            <video width="100%"
              key={puzzle._id}
              controlsList="nodownload"
              preload="auto"
              // controls 
              className="card-media"
              onClick={() => { setVedioModel({ ...vedioModel, show: true, data: `${url}${puzzle.url}` }) }}
              style={{
                // top: 8,
                // borderRadius: 1,
                // border:"2px solid #000",              
                objectFit: 'contain',
                // padding: '5px',
                // width: 'calc(100% - 16px)',
                height: '300px',
                cursor: 'pointer'
              }}
            >
              <source src={`${url}${puzzle.url}`}></source>
            </video>
            <span className="play-icon-admin"
              style={{ cursor: 'pointer', color: "#000" }} onClick={() => { setVedioModel({ ...vedioModel, show: true, data: `${url}${puzzle.url}` }) }} >
              <PlayArrowIcon fontSize='large' />
            </span>
          </div>
          <VedioModel show={vedioModel.show} data={vedioModel.data} onClose={() => setVedioModel({
            ...vedioModel, show: false
          })}
          />
        </Grid>
        : puzzle && puzzle?.mediaType && (puzzle.mediaType).split("/")[0] === "image" ?
          <Grid item lg={4} md={6} xs={12}>
            <Box
              component="img"
              alt="file preview"
              src={`${url}${puzzle.url}`}
              onClick={() => { setshowImageModel({ ...showImageModel, show: true, data: `${url}${puzzle.url}` }) }}
              sx={{
                // top: 8,
                // borderRadius: 1,
                // border:"2px solid #000",              
                objectFit: 'contain',
                // padding: '5px',
                width: 'auto',
                height: '300px',
                cursor: 'pointer'
              }}
            />
            <ImageModal show={showImageModel.show} data={showImageModel.data} onClose={() => setshowImageModel({
              ...showImageModel, show: false
            })}
            />
          </Grid>
          :
          puzzle && puzzle?.mediaType && (puzzle.mediaType).split("/")[0] === "audio" ?
            <Grid item lg={4} md={6} xs={12}>
              <div id="hint-need-audio">
                <AudioPlayerAdmin src={`${url}${puzzle.url}`} />
              </div>
            </Grid>
            : <>
              {puzzle && puzzle.text &&
                <Grid item lg={4} md={6} xs={12}>
                  <Typography>{puzzle.text}</Typography>

                </Grid>
              }
            </>
      }
    </Grid>
  );
}
