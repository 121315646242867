import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import MaterialTable from 'material-table';
import { getAdminPodReports, podAllName, podAll } from '../Services/PodServices';
import { toInteger } from 'lodash';
import { getGameName } from '../Services/GameServices';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
// const formatTime = (ms) => {
//   let x = ms / 1000
//   const seconds = x % 60
//   x /= 60
//   const minutes = x % 60
//   x /= 60
//   const hours = x % 24
//   x /= 24
//   const days = x
//   // return ms;
//   return `${toInteger(hours) ? toInteger(hours) + ":" : ""}${toInteger(minutes) ? toInteger(minutes) + ":" : ""}${toInteger(seconds)}`
// }


const formatTime = (ms) => {
  let x = ms / 1000
  let seconds = Math.floor(x % 60)
  seconds = seconds.toString().length < 2 ? '0' + seconds : seconds
  x /= 60
  let minutes = Math.floor(x % 60)
  minutes = minutes.toString().length < 2 ? '0' + minutes : minutes
  x /= 60
  const hours = Math.floor(x % 24)
  const formattedTime = hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
  return formattedTime;
}




const Report = () => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(5)
  const [exportCSV, setExportCSV] = useState([])
  const [filterData, setFilterData] = useState("");
  const [podData, setPodData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [filterGame, setFilterGame] = useState("");


  const tableRef = useRef();
  const column = [
    {
      title: 'Game Name',
      field: 'gameName',
      sorting: false
    },
    { title: 'POD Name', field: 'pod', sorting: false, render: (rowData) => rowData?.podName },
    { title: 'Team Name', field: 'teamName', sorting: false },
    {
      title: 'Team Members', sorting: false, render: (rowData) => (
        `${rowData?.teamMembers?.map(item => item.name).join(", ")}`
      )
    },
    { title: 'Reveal Answers', field: 'revealAnswerUsed', sorting: false },
    { title: 'Mostly Hint Taken', sorting: false, render: (rowData) => rowData?.maxHintTakenData ? rowData?.maxHintTakenData.hintName + "," + rowData?.maxHintTakenData.hintCount : "-" },

    { title: 'Total Penalties', field: 'totalPenalties', sorting: false },
    {
      title: 'Playing Time', sorting: false, render: (rowData) => (
        typeof rowData?.timeLeft === 'number' ? `${formatTime((rowData?.podTime * 60 * 1000) - rowData?.timeLeft)}`
          : "0:0:0"
      )
    },
    {
      title: 'Total Time', field: 'score', sorting: false, render: (rowData) => (
        typeof rowData?.timeLeft === 'number' ? `${formatTime(((rowData?.podTime * 60 * 1000) - rowData?.timeLeft) + rowData?.totalPenalties * 60 * 1000)}` : 0
      )
    },
  ];
  const exportData = async () => {
    let body;

    if (filterGame) {
      body = {
        gameId: filterGame.value
      }
    }
    if (filterData) {
      body = {
        podId: filterData.value
      }
    }

    const res = await getAdminPodReports(body);

    console.log(res.data.data);
    setExportCSV(res.data.data)
  }
  const getData = async (query) => {
    const { page, orderBy, orderDirection } = query;
    let body = {
      search: query?.search,
      pageNumber: query?.page + 1,
      pageSize: query?.pageSize,
      sortBy: orderBy?.field ? orderBy?.field : "date",
      sortOrder: orderDirection === 'asc' ? 1 : -1,
      podId: filterData ? filterData.value : "",
      gameId: filterGame ? filterGame.value : "",

    };
    setPageSize(query?.pageSize)
    const response = await getAdminPodReports(body);
    const data = response.data.data;
    const totalCount = response.data.totalteam;
    setData(data);
    return {
      data,
      page,
      totalCount
    }
  }

  const getPodData = async () => {
    const result = await podAllName();
    setPodData(result?.data.data);
    // localStorage.setItem("gameData", result?.data.data.name)
  };

  const getGameData = async () => {
    const result = await getGameName();
    setGameData(result?.data.data);
    localStorage.setItem("gameData", result?.data.data.name)
  };
  const handleFilter = async (e) => {
    setFilterData(e);
    tableRef.current.onQueryChange();
  };

  const handleFiltered = async (e) => {
    setFilterGame(e);
    tableRef.current.onQueryChange();
  };

  const option = podData.map((pod) => ({
    label: pod.name,
    value: pod._id
  }));
  const options = gameData.map((game) => ({
    label: game.internalTitle,
    value: game._id
  }));
  const exportParticipate = (file) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName, csvData;
    fileName = 'Participants';
    csvData =
      file.map((daa, index) => {
        return (
          {
            "S.No.": index + 1,
            "Game Name": daa.gameName,
            "POD Name": daa?.podName,
            "Team Name": daa.teamName,
            "Team Members": daa?.teamMembers.map(item => item.name).join(", "),
            "Penalties": daa.totalPenalties,
            "Reveal Answers": daa.revealAnswerUsed,
            "most used hint chapter": daa?.chapterName,
            "most used hint": daa.maxHintTakenData ? daa?.maxHintTakenData.hintName : "-",
            "Hint Count": daa.maxHintTakenData ? daa.maxHintTakenData.hintCount : "0",
            "Total Time": formatTime(((daa?.podTime * 60 * 1000) - daa?.timeLeft) + daa?.totalPenalties * 60 * 1000),
            "Playing Time": formatTime((daa?.podTime * 60 * 1000) - daa?.timeLeft),
            "Total Hints": daa?.totalHints?.length,
          }
        )
      })

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    getPodData()
    getGameData()
  }, []);
  useEffect(() => {
    exportData()
  }, [filterData, filterGame])
  console.log(filterGame)
  return (
    <div>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Reports</Card.Title>
        </Card.Header>

        <div className='table-head'>
          <div className='row'>
            <div className='col-md-4'>
              <Button onClick={() => exportParticipate(exportCSV)} className="btn btn-primary">
                Export Report <FileDownloadIcon />
              </Button>
            </div>
            <div className="col-md-4">
              <Select
                className="filter"
                name="podId"
                placeholder="Pod Filter"
                isClearable={true}
                options={option}
                value={filterData}
                onChange={(e) => handleFilter(e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                className="filter"
                name="gameId"
                value={filterGame}
                placeholder="Game Filter"
                isClearable={true}
                options={options}
                onChange={(e) => handleFiltered(e)}
              />
            </div>
          </div>
        </div>
        <div>
          <MaterialTable
            tableRef={tableRef}
            columns={column}
            data={getData}
            options={{
              search: false,
              emptyRowsWhenPaging: false,
              headerStyle: { fontWeight: 'bold' },
              pageSize: pageSize
            }}
          />
        </div>
      </Card >
    </div >
  );
};

export default Report;

