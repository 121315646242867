import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap';
import { Box, Typography } from '@material-ui/core';

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import closeIcon from "../../../assets/images/close-icon.png"

export default function SolutionView({ show, data, onClose, labels }) {
  let solutions = useSelector((state) => state.chapter && state.chapter.data && Object.keys(state.chapter.data).length && state.chapter.data.solution &&
    Array.isArray(state.chapter.data.solution) && state.chapter.data.solution.length > 0 ? state.chapter.data.solution : [])
  // console.log(solutions.toString())
  let solutionPoints = useSelector((state) => state.chapter && state.chapter.data && Object.keys(state.chapter.data).length && (state?.chapter?.data?.solutionPoints) ? (state.chapter.data.solutionPoints).toString() : "")
  let solutionDescription = useSelector((state) => state?.chapter?.data?.solutionDescription)

  return (
    data &&
    <Modal show={show} onHide={() => { onClose() }} centered backdrop="static">
      <Box style={{
        display: "flex",
        backgroundColor: "#1D1C1C",
        color: "#FFFB00",
        justifyContent: "flex-end",
        cursor: 'pointer',
        gap: '5px',
        padding: "10px 10px 0px"
      }} onClick={() => { onClose() }}>
        <img src={closeIcon} style={{ cursor: 'pointer', height: '20px' }} />
        <span style={{ color: "#FFF", letterSpacing: '0.32px', fontSize: '14px' }}>{labels?.closeButton}</span>
      </Box>
      <ModalBody className='need-hint-content text-center' style={{ backgroundColor: "#1D1C1C" }}>
        <h3>{labels?.revealanswer ? labels?.revealanswer : "Reveal Answer"}</h3>
        <h4>{(labels?.penalty ? labels?.penalty : "PENALTY")} : {solutionPoints.toString()} {labels.mins}</h4>
        <h5 className='yallow-color year-text'>{solutions[0]}</h5>
        <p className='solution-description'>{solutionDescription ? solutionDescription : null} </p>
      </ModalBody>
    </Modal>
  )
}
