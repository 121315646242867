import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import Breadcrumb from '../../layouts/AdminLayout/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from '../Admin/Services/Service';
import { TextField } from '@material-ui/core';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (newPassword.length != 0) {
        if (confirmPassword.length != 0) {
          const resetData = await resetPassword({ newPassword, confirmPassword }, token);
          console.log('resetData', resetData);
          if (resetData && resetData.status === 200 && resetData.data.length !== 0) {
            toast.success(resetData.data.message);
            navigate('/auth/login');
          }
        } else {
          setError(true);
          toast.error('Confirm Password is required!!');
        }
      } else {
        setError(true);
        toast.error('New password should be greater than 8 characters');
      }
    } catch (err) {
      console.log('errror', err.response);
      toast.error(err.response.data.message);
    }
  };
  console.log('tokentokentoken', token);

  return (
    <React.Fragment>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                <i className="feather icon-user-plus auth-icon" />
              </div>
              <h3 className="mb-4"> Reset Password</h3>
              <div className="form-group mb-3">
                <TextField
                  variant='outlined'
                  type="password"
                  fullWidth
                  placeholder="Enter new password"
                  value={newPassword}
                  name="newPassword"
                  onChange={(e) => {
                    let val = e.target.value.replace(' ', '');
                    setNewPassword(val);
                  }}
                />
              </div>
              {error && newPassword.length === 0 && <small className="text-danger form-text">New password is required</small>}

              <br />
              <div className="form-group mb-4">
                <TextField
                  variant='outlined'
                  type="password"
                  fullWidth
                  placeholder="Enter confirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    let val = e.target.value.replace(' ', '');
                    setConfirmPassword(val);
                  }}
                />
              </div>
              {error && confirmPassword.length === 0 && <small className="text-danger form-text">Confirm password is required</small>}

              <br />
              <button className="btn btn-primary mb-4" onClick={(e) => handleSubmit(e)}>
                Submit
              </button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
