import axios from 'axios';
import { gameUrl, gameName } from '../Config/config';
import Interceptor from '../../../config/Interceptor';

var body = {};

export const gameAdd = (game) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/addGame`, game, {
    headers: {
      Authorization: `bearer ${token}`
    }
  });
};
export const gameList = (game) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/getAllGames`, game, { headers: { Authorization: `Bearer ${token}` } });
};

//Trash
export const gameTrash = (game) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/getTrashGames`, game, { headers: { Authorization: `Bearer ${token}` } });
};

export const gameRestore = (id) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/retrieveGame`, id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getOneGame = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/getOneGameDetails`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const gameEdit = (editDetails) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/updateGame`, editDetails, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const gameDelete = (id) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameUrl}/deleteGame`, id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getGameName = () => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameName}chapters/getGameNames`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const copyGame = (data) => {
  const token = localStorage.getItem('token');
  return Interceptor().post(`${gameName}games/copyGame`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
