import React, { useEffect, useState } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { getDashboard } from '../Services/Dashboard';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from '@mui/material'
import { styled } from '@material-ui/styles';


const ButtonStyle = styled(Button)(() => ({
  backgroundColor: "#FFFB00 !important",
  color: "#000 !important",
  maxWidth: "260px",
  maxHeight: "50px",
  padding: "10px 15px",
}));

const CardStyle = styled(Card)(() => ({
  borderRadius: 0,
  padding: "20px 15px",
  margin: "5px",
  display: "flex",
  justifyContent: "space-between",
  minHeight: "105px",
  alignItems: 'center'
}))

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    game: 0,
    team: 0,
  });
  useEffect(() => {
    getDashboardData();
  }, []);

  //get the data which you want to display on dashboard
  const getDashboardData = async () => {
    try {
      const dashboard = await getDashboard();

      setData(dashboard?.data.data);
    } catch (err) { }
  };

  const handleGame = () => {
    navigate("/admin/gamelist")
  }


  const handleTeam = () => {
    navigate("/admin/pod")
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} md={6} xl={3} xs={12}>
          <CardStyle className='dash-card'>
            <ButtonStyle variant="contained" onClick={() => { handleGame() }}>Active Games</ButtonStyle>
            <h3 className="f-w-300 float-right d-flex align-items-center m-b-0" style={{ color: '#111' }}>
              {data.totalGame}
            </h3>
          </CardStyle>
        </Col>
        <Col lg={6} md={6} xl={3} xs={12}>
          <CardStyle className='dash-card'>
            <ButtonStyle variant="contained" onClick={() => { handleTeam() }}>Teams</ButtonStyle>

            <h3 className="f-w-300 float-right d-flex align-items-center m-b-0" style={{ color: '#111' }}>
              {data.totalTeam}
            </h3>
          </CardStyle>
        </Col>
        {/* <Col lg={6} md={6} xl={3} xs={12}>
          <CardStyle className='dash-card'>            
              <ButtonStyle variant = "contained">Users Clicked the Link</ButtonStyle>
              <h3 className="f-w-300 float-right d-flex align-items-center m-b-0" style={{ color: '#111' }}>
                -
              </h3>            
          </CardStyle>
        </Col> */}
        <Col lg={6} md={6} xl={3} xs={12}>
          <CardStyle className='dash-card'>
            <ButtonStyle variant="contained">User Played the Game</ButtonStyle>

            <h3 className="f-w-300 float-right d-flex align-items-center m-b-0" style={{ color: '#111' }}>
              {data.totaluserplayedgame}
            </h3>
          </CardStyle>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
