import { TEAM_DATA_SUCCESS, CLEAN_ALL_DATA, PENALTY_FUN, HINT_FUN, CHAPTER_FUN, TEAM_FUN, SOLUTION_FUN } from '../Actions/Types';





const team = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            data: {},
            error: {},
        }
    }


    switch (action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: {},
                error: {},
            }

        case TEAM_DATA_SUCCESS:
            return {
                ...state,
                data: action.data
            }

        case PENALTY_FUN:
            return {
                ...state,
                data: { ...state.data, totalPenalty: action.data }
            }

        case TEAM_FUN:
            return {
                ...state,
                data: { ...state.data, teamDetails: { ...action.data } }
            }

        case HINT_FUN:

            return {
                ...state,
                data: { ...state.data, hintsTaken: [...action.data] }
            }

        case CHAPTER_FUN:
            return {
                ...state,
                data: { ...state.data, chapterDetail: { ...state.data.chapterDetail, ...action.data } }
            }

        case SOLUTION_FUN:
            return {
                ...state,
                data: { ...state.data, solutionTaken: action.data }
            }


        default:
            return state
    }
}



export default team;