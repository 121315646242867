import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ClueCard from './CardDisplay/ClueCard';
import HintCard from './CardDisplay/HintCard';
import PuzzleCard from './CardDisplay/PuzzleCard';
import { viewAllChapters, getChapterOne } from '../Services/ChapterServices';

import { Card, Row, Col, Button, Modal, ModalBody, ModalHeader } from 'react-bootstrap';

import { Table } from 'react-bootstrap';
import { Divider, Grid, Typography } from '@material-ui/core';

const ChapterOneList = () => {  
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [display, setDisplay] = useState({});
  const {id, gameId} = useParams();
  useEffect(() => {
    getChapter();
  }, []);

  //get the chapter data
  const getChapter = async () => {
    const resultChapter = await getChapterOne({_id:id});
    setDisplay({...resultChapter.data.data});
  };
  const handleBack = () => {
    navigate(`/admin/game/edit/${gameId}`);
  };

  console.log(display)
  return (
    <>
      <Modal  size='lg' show={show} centered scrollable={true} onHide={() => setShow(false)} backdrop="static">
        <ModalHeader closeButton={true}>
          <h5 className='fw-bold'>Description</h5>          
        </ModalHeader>
        <ModalBody className='sidebar-scroll'>
          <p className='text-black text-justify'>{display.description}</p> 
        </ModalBody>
      </Modal>
      <Card>
        <Card.Header>
        <Row>
          <Col md={11}>
          {' '}        
            <Card.Title as="h5">Chapter's detail </Card.Title>
          </Col>
          <Col md={1}>
            <Button className="btn btn-outline-secondary bt-sm" type="submit" onClick={(e) => handleBack(e)}>
              Back
            </Button>
            </Col>
          </Row>
        </Card.Header>
          {display.length===0 ? <Row className='text-center'>
            <Typography variant='h6'>No Chapter Available</Typography>
          </Row> :<></>}
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  {Object.keys(display).length>0 &&
                    <Grid container spacing={1}>
                      <Grid item lg={3} xs={12}>
                        <h6 className='fw-bold'>Chapter Name</h6>
                      </Grid>
                      <Grid item lg={9} xs={12}>
                        <p className='text-black'>{display?.name}</p>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <h6 className='fw-bold'>Description</h6>
                      </Grid>
                      <Grid item lg={9} xs={12}>
                        {display.description.length>200 ?
                        <p className='text-black text-justify'>{display.description.slice(0,200)}...
                          <span style={{cursor:'pointer'}} onClick={()=>{setShow(true)}}>
                            more
                          </span> 
                        </p> 
                        :
                        <p className='text-black text-justify'>{display.description}</p>}
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <h6 className='fw-bold'>Solution</h6>
                      </Grid>
                      <Grid item lg={9} xs={12}>
                        <p className='text-black'>{display?.solution}</p>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <h6 className='fw-bold'>Solution Points</h6>
                      </Grid>
                      <Grid item lg={9} xs={12}>
                        <p className='text-black'>{display?.solutionPoints}</p>
                      </Grid>
                      <Grid item lg={12} xs={12}>
                      <h6 className='fw-bold mt-3'>Puzzle</h6>
                        <PuzzleCard puzzle={display?.puzzle} text={display?.puzzleText} />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <h6 className='fw-bold mt-3'>Clues</h6>
                        <ClueCard clue={display?.clues} />
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <h6 className='fw-bold mt-3'>Hints</h6>
                        <HintCard hint={display?.hints}/>
                      </Grid>
                    </Grid>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        {/* { display.length ?
        display.map((display, index) => (
          <>
          <Divider />
          </>
        ))
      :<></>} */}
      </Card>
    </>
  );
};

export default ChapterOneList;
